// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Link from 'components/core/Link';
import FullWidthTypography from 'components/core/FullWidthTypography';
import Project from 'types/Project';
import JoinButton from 'components/project/JoinButton';
import ProjectContact from 'components/project/ProjectContact';

const Root = styled.div<{ flipped: boolean | undefined; numImages: number }>(
  ({ theme, flipped, numImages }) => {
    return `
      min-width: ${theme.maxToolWidth};
      height: min-content;
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: ${
        numImages > 0
          ? flipped
            ? '51rem 26.49rem'
            : '26.49rem 51rem'
          : '45rem'
      };
      margin: 2rem 0;

      @media (max-width: 1200px) {
        grid-template-columns: ${
          numImages > 0
            ? flipped
              ? '44.49rem 26.49rem'
              : '26.49rem 44.49rem'
            : '45rem'
        }
      }
    `;
  }
);

const Header = styled(FullWidthTypography)({
  marginTop: '0',
  marginBottom: '0.5rem',
  display: 'flex',
  gap: '1rem',
});

const Subtitle = styled.div(({ theme }) => {
  return {
    fontSize: '1.125rem',
    fontWeight: 'bold',
    paddingBottom: '0.325rem',
    margin: '0px 0px 0.25rem',
  };
});

const Description = styled.div({
  marginBottom: '0.5rem',
});

const Thumbnail = styled.div<{ flipped: boolean }>(({ theme, flipped }) => {
  return {
    display: 'flex',
    justifyContent: flipped ? 'right' : 'left',
    alignItems: 'start',
  };
});

interface ProjectListingProps {
  project: Project;
  flipped?: boolean;
  className?: string;
}

function ProjectListing(props: ProjectListingProps): React.ReactElement | null {
  const { project, flipped, className } = props;
  const title = project.title;
  const subtitle = project.subtitle;
  const description = project.description;
  const contact = project.contact;
  const image = project.links.logo;
  const linkedUrl = `${project.slug}/info`;
  const linksToExternalSite = linkedUrl.includes('http');

  const thumbnail = (
    <Thumbnail flipped={flipped || false}>
      <img src={image} alt={''} width={336} />
    </Thumbnail>
  );

  return (
    <Root flipped={flipped} numImages={1} className={className}>
      {flipped ? null : thumbnail}
      <div>
        <Header variant="h2">
          <Link to={linkedUrl} openNewTab={linksToExternalSite}>
            {title}
          </Link>
          <JoinButton project={project} includeEdit={false} />
        </Header>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
        <ProjectContact contact={contact} />
      </div>
      {flipped ? thumbnail : null}
    </Root>
  );
}

export default observer(ProjectListing);
