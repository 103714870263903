// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { storyblokEditable } from '@storyblok/react';

// All other imports
import { RichTextStoryblok } from 'types/storyblokTypes';
import renderRichText from './renderRichText';

const Root = styled.div``;

export interface RichTextProps {
  blok: RichTextStoryblok;
  className?: string;
}

function RichText(props: RichTextProps): React.ReactElement | null {
  const { blok, className } = props;
  const body = blok.content
    ? renderRichText(blok.content, undefined, blok.continueOrderedList)
    : null;
  return (
    <Root className={className} {...storyblokEditable(blok)}>
      {body}
    </Root>
  );
}

export default observer(RichText);
