// NPM packages
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { DateTime } from 'luxon';

// All other imports
import { EventListStoryblok } from 'types/storyblokTypes';
import { storyblokEditable } from '@storyblok/react';

const Root = styled.div({});

const StyledTable = styled(Table)(({ theme: { palette, typography } }) => ({
  '& tr:nth-of-type(even)': {
    backgroundColor: palette.grey[100],
  },
  '& thead tr': {
    backgroundColor: palette.grey[300],
  },
  '& thead th': {
    fontWeight: typography.fontWeightBold,
    fontSize: '1rem',
  },
}));
const DateCell = styled(TableCell)({
  whiteSpace: 'nowrap',
});
const TypeCell = styled(TableCell)({
  width: '15rem',
});

export interface EventListProps {
  blok: EventListStoryblok;
  className?: string;
}

function EventList(props: EventListProps): React.ReactElement | null {
  const { blok, className } = props;
  const events = blok.events;

  return (
    <Root className={className} {...storyblokEditable(blok)}>
      <TableContainer component={Paper}>
        <StyledTable>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {events.map((event, index) => {
              const type = event.customType || event.type;
              const description = event.description;
              const [year, month, day] = event.date
                .split(' ')[0]
                .split('-')
                .map((str: string) => parseInt(str));
              const dateTime = DateTime.local(year, month, day);
              const localeString = dateTime.toLocaleString(DateTime.DATE_MED);
              return (
                <TableRow
                  // index is OK since events never change
                  key={index}
                >
                  <TypeCell>{type}</TypeCell>
                  <DateCell>{localeString}</DateCell>
                  <TableCell>{description}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </StyledTable>
      </TableContainer>
    </Root>
  );
}

export default observer(EventList);
