import { makeAutoObservable, runInAction } from 'mobx';
import axios from 'axios';

export default class CliService {
  installCmd: string | null = null;
  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    const hostname = window.location.hostname;
    const hostnameParts = hostname.split('.');
    let environment = null;
    if (hostnameParts.length === 3) {
      // e.g. dev or stage
      environment = hostnameParts[0];
      // Show instructions for installing dev CLI
      if (environment === 'local') {
        environment = 'dev';
      }
    } else {
      environment = 'prod';
    }
    this.setInstallCmd(environment);
  }

  setInstallCmd = async (environment: string): Promise<void> => {
    const cmd = await this.fetchInstallCmd(environment);
    runInAction(() => {
      this.installCmd = cmd;
    });
  };

  fetchInstallCmd = async (environment: string): Promise<string> => {
    if (environment) {
      const readmeUrl = `https://raw.githubusercontent.com/MSD-LIVE/msdlive-cli-distro/${environment}/README.md`;
      const readmeResponse = await axios(readmeUrl);
      return readmeResponse.data;
    }
    return 'pip install msdlive-cli';
  };
}
