// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

// All other imports
import ProjectContact from 'components/project/ProjectContact';
import ProjectResourceGrid from 'components/project/ProjectResourceGrid';
import MarkdownText from 'components/core/MarkdownText';
import Project from 'types/Project';
import EditLogo from 'components/project/EditLogo';
import { observer } from 'mobx-react-lite';

const DescriptionBar = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  marginBottom: '1.5rem',
});
const LogoContainer = styled.div({
  flex: '0 1 auto',
  width: '100%',
  overflow: 'hidden',
  paddingLeft: '2.5rem',
  maxWidth: '25%',
});
const Logo = styled.img({
  width: '100%',
  marginBottom: '1rem',
});

function ProjectInfo(props: { project: Project }): React.ReactElement {
  const { project } = props;
  return (
    <div>
      <DescriptionBar>
        <MarkdownText
          markdown={project.longDescription ?? 'No Description Provided'}
        />
        <LogoContainer>
          <Logo src={project.links.logo} alt="" />
          <EditLogo project={project} />
        </LogoContainer>
      </DescriptionBar>
      <ProjectContact contact={project.contact} />
      <Typography variant="h2">Project Resources</Typography>
      <ProjectResourceGrid resources={project.resources} />
    </div>
  );
}

export default observer(ProjectInfo);
