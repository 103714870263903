// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation } from 'react-router-dom';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { faPenToSquare } from '@fortawesome/free-regular-svg-icons';

// All other imports
import { useServices } from 'services';
import ButtonLink from 'components/core/ButtonLink';
import Link from 'components/core/Link';

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});
const TopBar = styled.div({
  marginBottom: '0.5rem',
});
const BottomBar = styled.div({
  width: 'min-content',
  whiteSpace: 'nowrap',
});

const LogInButton = styled(ButtonLink)({
  backgroundColor: '#e0e1e2',
  color: 'rgba(0,0,0,.8)',
  marginRight: '0.25rem',
  '&:hover': {
    backgroundColor: '#cacbcd',
  },
});

export interface LogInSignUpProps {
  className?: string;
}

function LogInSignUp(props: LogInSignUpProps): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();
  const location = useLocation();
  return (
    <Root className={className}>
      <TopBar>
        <LogInButton
          to={authService.getLogInUrl({ location })}
          startIcon={<FontAwesomeIcon icon={faArrowRightToBracket} />}
        >
          Log In
        </LogInButton>
        <ButtonLink
          to="/sign-up"
          startIcon={<FontAwesomeIcon icon={faPenToSquare} />}
        >
          Sign Up
        </ButtonLink>
      </TopBar>
      <BottomBar>
        <Link to="/forgot-password">Forgot Password?</Link>
      </BottomBar>
    </Root>
  );
}

export default observer(LogInSignUp);
