// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

const Root = styled.div({
  display: 'flex',
  justifyContent: 'right',
  position: 'absolute',
  top: '-28px',
  right: '50px',
});

const StyledData = styled.div({
  color: '#16adfe',
});

const StyledName = styled.div({
  color: 'black',
});

export interface ChartDataLabelProps {
  data: string | number | undefined;
  name: string;
  offsetRight?: number;
  className?: string;
}

function ChartDataLabel(props: ChartDataLabelProps): React.ReactElement | null {
  const { data, name, offsetRight, className } = props;
  return (
    <Root
      className={className}
      style={offsetRight ? { right: `${offsetRight}px` } : undefined}
    >
      <StyledData>{data}</StyledData>&nbsp;
      <StyledName>{name}</StyledName>
    </Root>
  );
}

export default observer(ChartDataLabel);
