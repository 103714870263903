// NPM packages
import React, { useState } from 'react';
import { Dialog, DialogContent, Button, Alert } from '@mui/material';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { observer } from 'mobx-react-lite';

// All other imports
import Project from 'types/Project';
import Form from 'components/core/Form';
import FileUpload from 'components/core/FileUpload';
import { useServices } from 'services';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});
const EditButton = styled(Button)({
  width: '100%',
});

interface UploadFormData {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  logo: any;
}
const schema: yup.ObjectSchema<UploadFormData> = yup.object({
  logo: yup
    .mixed()
    .required()
    .test('logoRequired', 'A logo is required', (value) => {
      return value instanceof FileList && value.length === 1;
    }),
});

export interface EditLogoProps {
  className?: string;
  project: Project;
}

function EditLogo(props: EditLogoProps): React.ReactElement | null {
  const { className, project } = props;
  const { authService } = useServices();
  const [showModal, setShowModal] = useState<boolean>(false);
  const openModal = (): void => setShowModal(true);
  const closeModal = (): void => setShowModal(false);
  const onSubmit = async (data: UploadFormData): Promise<void> => {
    await project.updateLogo(data.logo.item(0));
    closeModal();
  };

  const userIsAdmin =
    authService.user && authService.user.isAdmin(project.slug);

  if (!userIsAdmin) {
    return null;
  }

  return (
    <Root className={className}>
      <EditButton size="large" onClick={openModal}>
        Upload New Logo
      </EditButton>
      <Dialog open={showModal} onClose={closeModal}>
        <DialogContent>
          <Form<UploadFormData>
            schema={schema}
            defaultValues={{ logo: null }}
            onSubmit={onSubmit}
            submitText={'Submit'}
            onCancel={closeModal}
          >
            {(formState) => {
              return (
                <>
                  <FileUpload formState={formState} fieldName="logo" />
                  <Alert severity="warning">
                    Submitting a new logo will permanently overwrite the old
                    logo.
                  </Alert>
                </>
              );
            }}
          </Form>
        </DialogContent>
      </Dialog>
    </Root>
  );
}

export default observer(EditLogo);
