// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router';

// All other imports
import PageContent from 'components/core/PageContent';
import { useServices } from 'services';
import useSelectProjectByUrl from 'hooks/useSelectProjectByUrl';
import useResourceGuard from 'hooks/useResourceGuard';
import { observer } from 'mobx-react-lite';
import Project from 'types/Project';
import ProjectForm from 'components/project/ProjectForm';
import Link from 'components/core/Link';

const Root = styled(PageContent)({});

export interface EditProjectProps {
  className?: string;
}

function EditProject(props: EditProjectProps): React.ReactElement | null {
  const { className } = props;
  const { projectService } = useServices();
  useSelectProjectByUrl({ projectService });
  const spinner = useResourceGuard({
    resource: projectService.selectedProject,
  });
  const navigate = useNavigate();
  let body: React.ReactNode;
  if (spinner) {
    body = spinner;
  } else {
    const project = projectService.selectedProject as Project;
    body = (
      <ProjectForm
        initialValues={{
          title: project.title,
          subtitle: project.subtitle,
          description: project.description,
          longDescription: project.longDescription,
          resources: project.resources,
          contact: project.contact,
        }}
        onSubmit={(data) =>
          project.updateMetadata({
            title: data.title,
            subtitle: data.subtitle,
            description: data.description,
            longDescription: data.longDescription,
            resources: data.resources,
            contact: data.contact,
          })
        }
        getSuccessMessage={() => (
          <Typography>
            Success! Go to{' '}
            <Link to={`/projects/${project.slug}`}>{project.title}</Link> to
            review your changes.
          </Typography>
        )}
        getErrorMessage={(rejectedVal) =>
          rejectedVal?.message ?? 'Failed to update project'
        }
        onCancel={() =>
          navigate(`/projects/${project.slug}`, { replace: true })
        }
      />
    );
  }
  return (
    <Root className={className}>
      <Typography variant="h1">Edit Project</Typography>
      {body}
    </Root>
  );
}

export default observer(EditProject);
