import { Theme } from '@emotion/react';
import styled, { StyledComponent } from '@emotion/styled';
import { Breadcrumbs, Link } from '@mui/material';
import React, {
  DetailedHTMLProps,
  ElementType,
  ImgHTMLAttributes,
} from 'react';

export interface BreadcrumbType {
  text: string;
  url: string;
}

export function useBreadcrumbs(): BreadcrumbType[] {
  let pathname = window.location.pathname;
  let pathPrefix = '/';
  if (pathname.startsWith('/sb')) {
    pathPrefix = '/sb/';
    pathname = pathname.replace('/sb', '');
  }
  const segments = pathname.split('/').filter((segment) => segment !== '');
  const breadcrumbs = segments.map((segment, index) => {
    const segmentsUpToCurrent = segments.slice(0, index + 1);
    let url = segmentsUpToCurrent.join('/');
    url = pathPrefix + url;
    return {
      url,
      text: segment.replaceAll('-', ' '),
    };
  });
  const onlyOneBreadcrumb = breadcrumbs.length === 1;
  return onlyOneBreadcrumb ? [] : breadcrumbs;
}

const StyledBreadcrumbs = styled(Breadcrumbs)({
  width: '100%',
});

const StyledLink = styled(Link)({
  textTransform: 'capitalize',
});

export function displayBreadcrumbs(
  breadcrumbs: BreadcrumbType[],
  textColor: string
): React.ReactElement {
  return (
    <StyledBreadcrumbs color={textColor}>
      {breadcrumbs.map((breadcrumb, idx) => {
        const linksToCurrentPage = breadcrumb.url === window.location.pathname;
        return (
          <StyledLink
            underline={linksToCurrentPage ? 'none' : 'always'}
            color={textColor}
            href={linksToCurrentPage ? undefined : breadcrumb.url}
            key={`Breadcrumb-${idx}`}
          >
            {breadcrumb.text}
          </StyledLink>
        );
      })}
    </StyledBreadcrumbs>
  );
}

// TODO: Cleanup/remove any of the below as possible
export const LIMITED_RESOURCE_CATEGORY_COLUMN_GAP = 2; // rem
export const LIMITED_RESOURCE_CATEGORY_MAX_HEIGHT = 218; // px
export const LIMITED_RESOURCE_CATEGORY_THUMBNAIL_COLUMN_RATIO = 0.38;
export const LIMITED_RESOURCE_CATEGORY_INFO_COLUMN_RATIO =
  1 - LIMITED_RESOURCE_CATEGORY_THUMBNAIL_COLUMN_RATIO;

export const RESOURCE_COMPONENT_COLUMN_GAP = 2; // rem
export const RESOURCE_COMPONENT_THUMBNAIL_COLUMN_RATIO = 0.3;
export const RESOURCE_COMPONENT_INFO_COLUMN_RATIO =
  1 - RESOURCE_COMPONENT_THUMBNAIL_COLUMN_RATIO;

export const RESOURCE_COMPONENT_THUMBNAIL_CONTAINER_WIDTH = parseFloat(
  (1221 * RESOURCE_COMPONENT_THUMBNAIL_COLUMN_RATIO).toFixed(0)
);
export const RESOURCE_COMPONENT_THUMBNAIL_CONTAINER_HEIGHT =
  RESOURCE_COMPONENT_THUMBNAIL_CONTAINER_WIDTH * 0.565757;

export const IMAGE_COLUMN_WIDTH_PX = 366;

/**
 * The function useStoryblok, when called, can result in an error. Since
 * it's a hook, it cannot be placed inside of a try-catch (or any other
 * conditional), and the below was the most seamless workaround. Rather
 * than returning the error, the storyblokInit call (see index.tsx) has a
 * 'responseInterceptor' which returns, when a 4xx status code is
 * received, this object, which simulates as if Storyblok were returning
 * content that is the same as our "Page Not Found" content
 */
export default function notFoundResponse(): object {
  return {
    data: {
      story: {
        name: 'test',
        created_at: '2024-02-21T16:53:22.841Z',
        published_at: null,
        id: 3860658,
        uuid: '1d9c286f-c3d4-4c87-9280-19fca5473583',
        content: {
          _uid: 'e818bba0-4377-4871-8cbe-dfd5be9c14fb',
          body: [],
          header: 'Page Not Found',
          component: 'page',
          description: "Please shoot us an email if this doesn't seem right.",
          _editable:
            '<!--#storyblok#{"name": "page", "space": "1018956", "uid": "e818bba0-4377-4871-8cbe-dfd5be9c14fb", "id": "3860658"}-->',
        },
        slug: 'test',
        full_slug: 'test',
        sort_by_date: null,
        position: 0,
        tag_list: [],
        is_startpage: false,
        parent_id: null,
        meta_data: null,
        group_id: '7bbebf6c-d610-4d9e-8abd-f4221ffe958a',
        first_published_at: null,
        release_id: null,
        lang: 'default',
        path: null,
        alternates: [],
        default_full_slug: null,
        translated_slugs: null,
      },
      cv: 1708534989,
      rels: [],
      links: [],
    },
    headers: {
      'cache-control': 'max-age=0, private, must-revalidate',
      'content-length': '845',
      'content-type': 'application/json; charset=utf-8',
    },
    status: 200,
    statusText: '',
  };
}

type EmotionStyledImage = StyledComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { theme?: Theme | undefined; as?: ElementType<any> | undefined },
  DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
  {}
>;

export const getImageElement = (
  image: string | JSX.Element | undefined,
  imageAlt: string | undefined,
  StyledComponent: EmotionStyledImage
): React.ReactElement | null => {
  if (React.isValidElement(image)) {
    return image;
  } else if (typeof image === 'string') {
    const imagePathSplit = image.split('.');
    const lastPathIndex = imagePathSplit.length - 1;
    const imageType = imagePathSplit[lastPathIndex];

    if (imageType === 'svg') {
      return <StyledComponent src={image} alt={imageAlt || ''} />;
    } else {
      return <img src={image} alt={imageAlt || ''} />;
    }
  }
  return null;
};
