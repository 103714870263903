// NPM packages
import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import LoadingStateManager from 'types/LoadingStateManager';

// All other imports

const Root = styled(Alert)({
  marginTop: '1rem',
  marginBottom: '0.5rem',
});

export interface ErrorOutletProps {
  className?: string;
  loadingStateManager: LoadingStateManager;
  clearable?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage?: (rejectedVal: any) => React.ReactNode;
}

function ErrorOutlet(props: ErrorOutletProps): React.ReactElement | null {
  const {
    className,
    clearable,
    loadingStateManager: { loadingState, clearState },
    getErrorMessage = defaultGetErrorMsg,
  } = props;
  if (!loadingState.rejectedVal) {
    return null;
  }
  return (
    <Root
      className={className}
      severity="error"
      onClose={clearable ? clearState : undefined}
    >
      <AlertTitle>Error</AlertTitle>
      {getErrorMessage(loadingState.rejectedVal)}
    </Root>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function defaultGetErrorMsg(rejectedVal: any): React.ReactNode {
  if (rejectedVal && typeof rejectedVal === 'object') {
    if (rejectedVal.message) {
      return rejectedVal.message;
    }
  }
  return 'An unknown error occurred';
}

export default observer(ErrorOutlet);
