// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import {
  faArrowRightFromBracket,
  faShieldHalved,
  faUser,
} from '@fortawesome/free-solid-svg-icons';

// All other imports
import { useServices } from 'services';
import SplitButton, { SplitButtonOption } from 'components/core/SplitButton';
import UserNavItem from './UserNavItem';
import LogInSignUp from './LogInSignUp';
import { useNavigate } from 'react-router';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

export interface UserManagementProps {
  className?: string;
}

function UserManagement(props: UserManagementProps): React.ReactElement | null {
  const { className } = props;
  const { authService, envService } = useServices();
  const dataRepoUrl = envService.getValue('DATA_REPO_URL') ?? '';
  const cognitoDomain = envService.getValue('AWS_COGNITO_DOMAIN') ?? '';
  const cognitoClientId = envService.getValue('AWS_USER_POOL_CLIENT_ID') ?? '';

  const navigate = useNavigate();

  let body: React.ReactNode;
  if (authService.isLoggedIn) {
    body = (
      <SplitButton
        defaultOption={{
          key: 'default-user-profile',
          label: <UserNavItem icon={faUser} label={authService.user!.email} />,
          onClick: () => navigate('/profile'),
        }}
        secondaryOptions={getSecondaryOptions({
          dataRepoUrl,
          cognitoDomain,
          cognitoClientId,
        })}
        buttonProps={{ color: 'secondary' }}
      />
    );
  } else {
    body = <LogInSignUp />;
  }
  return <Root className={className}>{body}</Root>;
}

export default observer(UserManagement);

function getSecondaryOptions({
  dataRepoUrl,
  cognitoDomain,
  cognitoClientId,
}: {
  dataRepoUrl: string;
  cognitoDomain: string;
  cognitoClientId: string;
}): SplitButtonOption[] {
  return [
    {
      key: 'secondary-user-profile',
      label: <UserNavItem icon={faUser} label="Profile" />,
      to: `/profile`,
    },
    {
      key: '',
      label: <UserNavItem icon={faShieldHalved} label="Security" />,
      to: `${dataRepoUrl}/account/settings/security/`,
    },
    {
      key: 'log-out',
      label: <UserNavItem icon={faArrowRightFromBracket} label="Log out" />,
      to: `${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${dataRepoUrl}/logout/`,
    },
  ];
}
