import React from 'react';

export enum LoadingStatus {
  LOADING = 'loading',
  IDLE = 'idle',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface LoadingState {
  status: LoadingStatus;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rejectedVal: any | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  resolvedVal: any | null;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetSuccessMessageFunc = (resolvedVal: any) => React.ReactNode;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GetErrorMessageFunc = (rejectedVal: any) => React.ReactNode;
export type StartLoadingFunc = (args: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  waitOn: Promise<any>;
  // getSuccessMessage?: GetSuccessMessageFunc;
  // getErrorMessage: GetErrorMessageFunc;
}) => void;
export type ClearStateFunc = () => void;

export default interface LoadingStateManager {
  loadingState: LoadingState;
  startLoading: StartLoadingFunc;
  clearState: ClearStateFunc;
}
