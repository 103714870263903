// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Link from 'components/core/Link';
import { Contact } from 'types/Project';

const Root = styled.p(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  margin: 0,
}));

export interface ProjectContactProps {
  className?: string;
  contact: Contact;
}

function ProjectContact(props: ProjectContactProps): React.ReactElement | null {
  const { className, contact } = props;
  return (
    <Root className={className}>
      MSD-LIVE Contact:{' '}
      <Link to={`mailto:${contact.email}`}>
        {`${contact.firstName} ${contact.lastName}`}
      </Link>
    </Root>
  );
}

export default observer(ProjectContact);
