// NPM packages
import React, { useState } from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import {
  TextUrlStoryblok,
  PersonStoryblok,
  TextResourceStoryblok,
  VideoResourceStoryblok,
} from 'types/storyblokTypes';
import Link from 'components/core/Link';
import AttachmentThumbnail from 'components/resources/AttachmentThumbnail';
import TextTruncate from 'react-text-truncate';
import { StoryblokStory } from 'storyblok-generate-ts';

const Root = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const Header = styled(Typography)({
  width: '100%',
  marginTop: '0',
  marginBottom: '0.5rem',
  fontSize: '24px !important',
});

const Authors = styled.div<{ numAuthors: number }>(({ numAuthors }) => {
  if (numAuthors === 0) return { display: 'none' };
  return {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '0.5rem',
  };
});

const DescriptionButton = styled(Link)({
  marginTop: '0.125rem',
});

const Attachments = styled.div({
  width: '100%',
  display: 'flex',
  marginTop: '0.5rem',
});

const Attachment = styled(AttachmentThumbnail)({
  display: 'flex',
  flexWrap: 'wrap',
  paddingRight: '1rem',
});

export interface ResourceInformationProps {
  blok: TextResourceStoryblok | VideoResourceStoryblok;
  className?: string;
}

function ResourceInformation(
  props: ResourceInformationProps
): React.ReactElement | null {
  const { blok, className } = props;
  const isTextResourceWithLink = blok.component === 'textResource' && blok.link;
  const numAuthors = blok.authors?.length || 0;
  const numAttachments = blok.attachments ? blok.attachments.length : 0;

  let numDescriptionLines = 4;
  if (numAuthors > 0) {
    numDescriptionLines--;
  }
  if (numAttachments > 0) {
    numDescriptionLines--;
  }

  const [descriptionTruncated, setDescriptionTruncated] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);

  return (
    <Root className={className}>
      <div>
        <Header variant="h2">
          {isTextResourceWithLink ? (
            <Link to={blok.link}>{blok.title}</Link>
          ) : (
            blok.title
          )}
        </Header>
        {numAuthors > 0 && (
          <Authors numAuthors={numAuthors}>
            {numAuthors === 1 && <div>Author:&nbsp;</div>}
            {numAuthors >= 2 && <div>Authors:&nbsp;</div>}
            {blok.authors?.map(
              (
                value: StoryblokStory<PersonStoryblok> | string,
                index: number
              ) => {
                const authorName =
                  typeof value === 'string' ? value : value.content.name;
                const authorEmail =
                  typeof value === 'string' ? value : value.content.email;
                const mailToLink = `mailto:${authorEmail}`;
                const lastAuthor = index === blok.authors!.length - 1;
                const appendage = lastAuthor ? '' : ' |';
                return (
                  <div key={`Link-${blok._uid}-${index}`}>
                    {authorEmail ? (
                      <Link to={mailToLink}>{authorName}</Link>
                    ) : (
                      authorName
                    )}
                    {appendage}&nbsp;
                  </div>
                );
              }
            )}
          </Authors>
        )}
        {showFullDescription ? (
          <div>{blok.description}</div>
        ) : (
          <TextTruncate
            line={numDescriptionLines}
            element={'span'}
            text={blok.description}
            onTruncated={() => setDescriptionTruncated(true)}
          />
        )}
        {descriptionTruncated && (
          <DescriptionButton
            onClick={() => setShowFullDescription(!showFullDescription)}
          >
            {showFullDescription ? 'Close details' : 'Read more'}
          </DescriptionButton>
        )}
        {numAttachments > 0 && (
          <Attachments>
            {blok.attachments.map(
              (attachment: TextUrlStoryblok, idx: number) => {
                const filePath = attachment.url;
                const label = getAttachmentLabel(filePath);
                return (
                  <Attachment
                    attachment={{ filePath, label }}
                    openNewTab
                    key={`Attachment-${blok._uid}-${idx}`}
                  />
                );
              }
            )}
          </Attachments>
        )}
      </div>
    </Root>
  );
}

function getAttachmentLabel(filePath: string): string {
  const filePathSplit = filePath.split('.');
  const fileExtension = filePathSplit[filePathSplit.length - 1];
  const labelObject: Record<string, string> = {
    pptx: 'PowerPoint',
    pdf: 'Summary',
    png: 'Image',
    jpg: 'Image',
    svg: 'Image',
  };
  const label = labelObject[fileExtension] || 'File';
  return label;
}

export default observer(ResourceInformation);
