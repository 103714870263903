export enum ProjectResourceType {
  WIKI = 'wiki',
  WEBSITE = 'website',
  SLACK = 'slack',
  JUPYTER = 'jupyter',
  DATASETS = 'datasets',
  GITHUB = 'github',
}

export default interface ProjectResource {
  type: ProjectResourceType;
  url: string;
  private?: boolean;
}
