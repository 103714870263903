// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { AlertStoryblok } from 'types/storyblokTypes';
import { Alert as MuiAlert } from '@mui/material';
import renderRichText from './renderRichText';
import { storyblokEditable } from '@storyblok/react';

// All other imports

const Root = styled(MuiAlert)({
  marginBottom: '1rem',
});

export interface AlertProps {
  blok: AlertStoryblok;
  className?: string;
}

function Alert(props: AlertProps): React.ReactElement | null {
  const { blok, className } = props;
  const description = blok.description;
  const descriptionProperlyDefined =
    description &&
    description.content &&
    description.content.length !== 0 &&
    (description.content.length === 1 || description.content[0].content);
  return (
    <Root
      severity={blok.severity || 'info'}
      className={className}
      {...storyblokEditable(blok)}
    >
      {descriptionProperlyDefined &&
        renderRichText(description, undefined, undefined, 'alert')}
    </Root>
  );
}

export default observer(Alert);
