// NPM packages
import React from 'react';
import styled from '@emotion/styled';

export default styled.fieldset({
  border: 'none',
  padding: 0,
  margin: 0,
});

export type FieldSetProps = React.FieldsetHTMLAttributes<HTMLFieldSetElement>;
