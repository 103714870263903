// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import MarkdownText from 'components/core/MarkdownText';
import { BreadcrumbType, displayBreadcrumbs, getImageElement } from './utils';

const Root = styled.div(({ theme }) => {
  return `
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    color: white;
    background: linear-gradient( to right,
      ${theme.palette.primary.main} 50%,
      ${theme.palette.primary.light}
    );
  `;
});

const DisplayArea = styled.div(({ theme }) => {
  return `
    min-width: ${theme.maxToolWidth};
    display: grid;
    justify-content: center;
    grid-template-columns: 45rem 32.49rem;

    @media (max-width: 1200px) {
      grid-template-columns: 38.49rem 32.49rem
    }
  `;
});

const TextArea = styled.div({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  margin: '1.5rem 0',
});

const Header = styled(Typography)({
  marginBottom: '1rem',
});

const Description = styled.div({
  width: '100%',
});

const ImageArea = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const SVGImage = styled.img({
  filter: 'brightness(0) invert(1)',
  height: '8rem',
  width: '8rem',
  margin: '1rem 0',
});

export interface GradientBannerProps {
  header: string;
  description: string;
  image?: string | JSX.Element;
  imageAlt?: string;
  breadcrumbs?: BreadcrumbType[];
  className?: string;
}

function GradientBanner(props: GradientBannerProps): React.ReactElement | null {
  const { header, description, image, imageAlt, breadcrumbs, className } =
    props;
  const imageElement = getImageElement(image, imageAlt, SVGImage);

  return (
    <Root className={className}>
      <DisplayArea>
        <TextArea>
          {breadcrumbs && displayBreadcrumbs(breadcrumbs, '#ffffff')}
          <Header variant="h1">{header}</Header>
          <Description>
            <MarkdownText markdown={description} />
          </Description>
        </TextArea>
        <ImageArea>{imageElement}</ImageArea>
      </DisplayArea>
    </Root>
  );
}

export default observer(GradientBanner);
