// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';

// All other imports
import LoadingStateManager, { LoadingStatus } from 'types/LoadingStateManager';
import CircularProgress, {
  CircularProgressProps,
} from 'components/core/CircularProgress';

export interface SpinnerOutletProps {
  className?: string;
  loadingStateManager: LoadingStateManager;
  circularProgressProps?: CircularProgressProps;
}

function SpinnerOutlet(props: SpinnerOutletProps): React.ReactElement | null {
  const {
    className,
    loadingStateManager: { loadingState },
    circularProgressProps,
  } = props;
  if (loadingState.status === LoadingStatus.LOADING) {
    return (
      <CircularProgress
        color="inherit"
        className={className}
        {...circularProgressProps}
      />
    );
  }
  return null;
}

export default observer(SpinnerOutlet);
