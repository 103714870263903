import { Location } from 'react-router-dom';

function isAbsoluteUrl(url: string): boolean {
  return /^(?:[a-z]+:)?\/\//.test(url);
}

function isMailto(url: string): boolean {
  return /^mailto:/.test(url);
}

// Useful when stubbing things out.
function mockAsync<ReturnType>({
  milliseconds,
  resolvedValue,
}: {
  milliseconds: number;
  resolvedValue: ReturnType;
}): Promise<ReturnType> {
  return new Promise((resolve) => {
    setTimeout(() => resolve(resolvedValue), milliseconds);
  });
}

function wait({
  milliseconds,
  rejectWith,
}: {
  milliseconds: number;
  rejectWith?: Error;
}): Promise<void> {
  return new Promise((resolve, reject) => {
    setTimeout(
      () => (rejectWith ? reject(rejectWith) : resolve()),
      milliseconds
    );
  });
}

/**
 * Needed because when using react router, window.location.href
 * can not be trusted.
 * @param location The react router location object
 * @returns The full url of the current page.
 */
function getCurrentUrl(location: Location): string {
  return `${window.location.protocol}//${window.location.host}${location.pathname}${location.search}${location.hash}`;
}

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

/**
 * Merge multiple functions into one. All functions should take the
 * same arguments. Functions will be called in the order they are
 * given. If a function is async, it will NOT be waited on before
 * calling the next function.
 * @param funcs Functions to call. Undefined values are allowed.
 * @returns A function which is the result of merging the given functions.
 * It will take the same set of arguments as the given functions. It will
 * return an array with the return values from the merged functions. If
 * undefined was one of the merged values, undefined will be used as
 * placeholder in the array of return values.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function callFuncsInOrder<T extends any[]>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  funcs: (((...args: T) => any) | undefined)[]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): (...args: T) => any[] {
  return (...args) => {
    return funcs.map((func) => {
      if (func) {
        return func(...args);
      }
      return undefined;
    });
  };
}

function getErrorMessage({
  error,
  defaultMessage,
}: {
  error: unknown;
  defaultMessage: string;
}): string {
  if (error instanceof Error) {
    return error.message;
  }
  return defaultMessage;
}

function isLoaded(value: unknown): boolean {
  return value !== undefined;
}

function assertLoaded<LoadedType>(
  value: LoadedType | undefined
): asserts value is LoadedType {
  if (!isLoaded(value)) {
    throw new Error('Value is not loaded');
  }
}
export {
  isAbsoluteUrl,
  isMailto,
  mockAsync,
  wait,
  getCurrentUrl,
  callFuncsInOrder,
  getErrorMessage,
  assertLoaded,
  isLoaded,
};
