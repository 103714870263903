// NPM packages
import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { observer } from 'mobx-react-lite';

// All other imports
import LoadingStateManager, { LoadingStatus } from 'types/LoadingStateManager';

export interface SuccessOutletProps {
  className?: string;
  loadingStateManager: LoadingStateManager;
  /**Will be rendered if not in success state */
  children?: React.ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSuccessMessage: (resolvedVal: any) => React.ReactNode;
}

function SuccessOutlet(props: SuccessOutletProps): React.ReactElement | null {
  const {
    className,
    children,
    loadingStateManager: { loadingState },
    getSuccessMessage,
  } = props;

  const areSuccessful = Boolean(loadingState.status === LoadingStatus.SUCCESS);

  if (areSuccessful) {
    const successMessage = getSuccessMessage(loadingState.resolvedVal);
    return (
      <Alert severity="success" className={className}>
        <AlertTitle>Success</AlertTitle>
        {successMessage}
      </Alert>
    );
  }
  if (children) {
    return children;
  }
  return null;
}

export default observer(SuccessOutlet);
