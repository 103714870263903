// NPM packages
import React from 'react';
import {
  TextField,
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  InputAdornment,
} from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { GridRenderCellParams } from '@mui/x-data-grid';

// All other imports
import Project from 'types/Project';
import { useServices } from 'services';
import MembersTableMode from 'types/MembersTableMode';
import User from 'types/User';
import CircularProgress from 'components/core/CircularProgress';
import UserRole from 'types/UserRole';
import ConfirmationError from 'types/errors/ConfirmationError';

const RolesSelector = styled(Autocomplete)({
  '&&&& .MuiOutlinedInput-root': {
    padding: 0,
    marginBottom: 0,
  },
  flex: '1 1 auto',
}) as typeof Autocomplete;

const SpinnerAdornment = styled(InputAdornment)({
  padding: '0.5rem',
});

export interface GroupsCellProps {
  className?: string;
  params: GridRenderCellParams<User>;
  project: Project;
  isAdminView: boolean;
  mode: MembersTableMode;
}

function GroupsCell(props: GroupsCellProps): React.ReactElement | null {
  const { params, project, isAdminView } = props;
  const { authService } = useServices();
  const user: User = params.row;
  const rolesToDisplay = [];
  if (user.isAdmin(project.slug)) {
    rolesToDisplay.push(UserRole.ADMIN);
  }
  if (user.isCurator(project.slug)) {
    rolesToDisplay.push(UserRole.CURATOR);
  }
  if (!isAdminView) {
    const joinedDisplayString = rolesToDisplay.join(', ');
    return <>{joinedDisplayString}</>;
  }
  const readOnly = params.row.email === authService.user?.email;
  const disabled = user.loading || readOnly;
  const updateGroups = async (
    event: React.SyntheticEvent<Element, Event>,
    value: string[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<UserRole> | undefined
  ): Promise<void> => {
    switch (reason) {
      case 'selectOption':
        user.addRole({ role: details!.option, projectSlug: project.slug });
        break;
      case 'removeOption':
        try {
          user.removeRole({ role: details!.option, projectSlug: project.slug });
        } catch (error: unknown) {
          // Ignore confirmation error: the user changed their mind
          if (!(error instanceof ConfirmationError)) {
            throw error;
          }
        }
        break;
      case 'clear':
        throw Error("Can't clear roles");
      default:
        throw Error(`Unknown reason: ${reason}`);
    }
  };
  return (
    <RolesSelector
      multiple
      disabled={disabled}
      id={`${params.row.email}_roles_autocomplete`}
      options={Object.values(UserRole).filter(
        (value) => value !== UserRole.MEMBER && value !== UserRole.PENDING
      )}
      value={rolesToDisplay}
      onChange={updateGroups}
      disableClearable
      renderInput={({ InputProps, ...otherParams }) => (
        <TextField
          {...otherParams}
          InputProps={{
            ...InputProps,
            startAdornment: user.loading ? (
              <SpinnerAdornment position="start">
                <CircularProgress size="1.5rem" />
              </SpinnerAdornment>
            ) : (
              InputProps.startAdornment
            ),
          }}
          fullWidth
          aria-label="Special Roles"
          helperText={readOnly ? "Can't edit your own roles" : undefined}
        />
      )}
    />
  );
}

export default observer(GroupsCell);
