export default class CookieService {
  getCookieMap = (): Record<string, string> => {
    const result: Record<string, string> = {};
    const allCookies = document.cookie;
    allCookies.split(';').forEach((nameAndValue) => {
      const trimmed = nameAndValue.trim();
      const [name, key] = trimmed.split('=') as [string, string];
      result[name] = key;
    });
    return result;
  };

  getCookie = (name: string): string | null => {
    const cookieMap = this.getCookieMap();
    return cookieMap[name] ?? null;
  };
}
