// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// All other imports
import ResendVerificationForm from 'components/auth/ResendVerificationForm';
import PageContent from 'components/core/PageContent';
import FormCard from 'components/core/FormCard';

const Root = styled(PageContent)({});

export interface ResendVerificationProps {
  className?: string;
}

function ResendVerification(
  props: ResendVerificationProps
): React.ReactElement | null {
  const { className } = props;
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  return (
    <Root className={className}>
      <FormCard
        title="Verify Email"
        titleIcon={<FontAwesomeIcon icon={faUser} />}
      >
        <ResendVerificationForm email={email} />
      </FormCard>
    </Root>
  );
}

export default ResendVerification;
