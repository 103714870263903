// NPM packages
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/lato/400.css';
import '@fontsource/rubik-mono-one/400.css';

// All other imports
import App from 'components/App';
import Services from 'services/Services';
import {
  EnvService,
  AuthService,
  CookieService,
  RdmApiService,
  ServicesProvider,
  ProjectService,
  AwsConfigService,
  GlobalErrorService,
  ConfirmationService,
  CliService,
  MetricsService,
} from 'services';
import * as serviceWorker from './serviceWorker';
import theme from './theme';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import Page from 'components/pages/storyblok/Page';
import ResourceCategoryList from 'components/pages/storyblok/ResourceCategoryList';
import ResourceCategory from 'components/pages/storyblok/ResourceCategory';
import notFoundResponse from 'components/pages/storyblok/utils';
import RichText from 'components/pages/storyblok/RichText';
import GuideResource from 'components/pages/storyblok/resourceComponents/GuideResource';
import TextResource from 'components/pages/storyblok/resourceComponents/TextResource';
import VideoResource from 'components/pages/storyblok/resourceComponents/VideoResource';
import EventList from 'components/pages/storyblok/EventList';
import PrincipleList from 'components/pages/storyblok/PrincipleList';
import PersonList from 'components/pages/storyblok/PersonList';
import Alert from 'components/pages/storyblok/Alert';
import CollapsibleSection from 'components/core/CollapsibleSection';

const envService = new EnvService();
const globalErrorService = new GlobalErrorService();
const confirmationService = new ConfirmationService();
const cookieService = new CookieService();
const awsConfigService = new AwsConfigService({ envService });
awsConfigService.initialize();
const rdmApiService = new RdmApiService({ envService, cookieService });
const authService = new AuthService({
  envService,
  cookieService,
  rdmApiService,
  globalErrorService,
  confirmationService,
});
authService.initialize();
const projectService = new ProjectService({
  envService,
  rdmApiService,
  authService,
  globalErrorService,
});
const cliService = new CliService();
const metricsService = new MetricsService({
  rdmApiService,
  globalErrorService,
});
const services: Services = {
  envService,
  globalErrorService,
  confirmationService,
  authService,
  cookieService,
  rdmApiService,
  projectService,
  cliService,
  metricsService,
};

const sentryDsn = envService.getValue('SENTRY_DSN');
if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

storyblokInit({
  accessToken: envService.getValue('STORYBLOK_ACCESS_TOKEN'),
  use: [apiPlugin],
  components: {
    page: Page,
    resourceCategoryList: ResourceCategoryList,
    resourceCategory: ResourceCategory,
    guideResource: GuideResource,
    textResource: TextResource,
    videoResource: VideoResource,
    richText: RichText,
    eventList: EventList,
    principleList: PrincipleList,
    personList: PersonList,
    alert: Alert,
    collapsibleSection: CollapsibleSection,
  },
  apiOptions: {
    region: 'us',
    responseInterceptor: (event) => {
      const statusCode: string = event.status.toString();
      if (statusCode.startsWith('4')) {
        return notFoundResponse();
      }
      return event;
    },
  },
});

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <ServicesProvider services={services}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ServicesProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
} else {
  console.error("Can't find element with id 'root'");
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
