// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { MenuItem } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';

// All other imports
import NavItem from 'types/NavItem';
import HeaderNav from './HeaderNav';
import Link, { LinkProps } from 'components/core/Link';
import UserManagement from './UserManagement';
import { useServices } from '../../services';
import HoverMenu from 'components/core/HoverMenu';
import { observer } from 'mobx-react-lite';

const Root = styled.div({
  display: 'flex',
  flexDirection: 'column',
});

const PrimaryNav = styled.div(
  ({ theme: { outermostPadding, typography } }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    ...outermostPadding({
      vertical: {
        mobile: '0.75rem',
        tablet: typography.pxToRem(14.5),
        desktop: typography.pxToRem(20),
      },
    }),
  })
);

const Logo = styled.img(({ theme: { breakpoints } }) => ({
  width: '200px',
  [breakpoints.up('md')]: {
    width: '300px',
  },
  [breakpoints.up('lg')]: {
    width: '300px',
  },
  [breakpoints.up('xl')]: {
    width: '300px',
  },
}));

const RightHandSide = styled.div({
  display: 'flex',
  alignItems: 'baseline',
});

const SecondaryNav = styled(
  ({
    onHomepage,
    ...rest
  }: React.HTMLAttributes<HTMLDivElement> & { onHomepage: boolean }) => (
    <div {...rest} />
  )
)<{ onHomepage: boolean }>(
  ({ theme: { outermostPadding, typography, palette }, onHomepage }) => ({
    display: onHomepage ? 'none' : 'flex',
    alignItems: 'center',
    backgroundColor: palette.primary.dark,
    gap: '3.35em',
    fontSize: '1.125rem',
    ...outermostPadding({
      vertical: {
        mobile: '0.75rem',
        tablet: typography.pxToRem(14.5),
        desktop: typography.pxToRem(20),
      },
    }),
    '& a': {
      color: 'white',
      textDecoration: 'none',
      '&:hover': {
        color: 'white',
        textDecoration: 'none',
      },
    },
  })
);

const SecondaryLink = styled(
  ({ onPage, ...rest }: LinkProps & { onPage: boolean }) => <Link {...rest} />
)(({ onPage }) => ({
  borderBottom: onPage ? '4px solid white' : 'none',
}));

export interface HeaderProps {
  className?: string;
  items: NavItem[];
}

function getStyle(
  pathToMatch: string,
  currentPath: string
): React.CSSProperties | undefined {
  const matches = matchPath(pathToMatch, currentPath);
  return matches ? { fontWeight: 'bold' } : undefined;
}

function Header(props: HeaderProps): React.ReactElement | null {
  const { className, items } = props;
  const { envService } = useServices();
  const location = useLocation();
  const dataRepoURl = envService.getValue('DATA_REPO_URL');
  const onHomepage = Boolean(matchPath('/', location.pathname));
  const onComp = Boolean(
    matchPath('/sb/computational-resources/*', location.pathname)
  );
  const onProj = Boolean(matchPath('/projects/*', location.pathname));
  const onHelp = Boolean(
    matchPath('/sb/help/*', location.pathname) ||
      matchPath('/sb/get-started', location.pathname) ||
      matchPath('/sb/cli-help', location.pathname)
  );

  return (
    <Root className={className}>
      <PrimaryNav>
        <Link to="/" aria-label="homepage">
          <Logo src="/MSD.LIVE.horiz.svg" alt="" />
        </Link>
        <RightHandSide>
          <HeaderNav items={items} />
          <UserManagement />
        </RightHandSide>
      </PrimaryNav>
      <SecondaryNav onHomepage={onHomepage}>
        <HoverMenu
          renderAnchor={(props) => (
            <Link {...props} to={dataRepoURl} aria-label="Data Repository">
              Data Repository
            </Link>
          )}
          renderMenuItems={({ close }) => [
            <MenuItem
              onClick={close}
              key="profile"
              component="a"
              href={`${dataRepoURl}/uploads/new`}
            >
              New Upload
            </MenuItem>,
            <MenuItem
              onClick={close}
              key="my-account"
              component="a"
              href={`${dataRepoURl}/me/uploads`}
            >
              My Uploads
            </MenuItem>,
            <MenuItem
              onClick={close}
              key="logout"
              component="a"
              href={`${dataRepoURl}/me/requests`}
            >
              My Requests
            </MenuItem>,
          ]}
          id="data-repository-nav"
        />

        <SecondaryLink
          to="/sb/computational-resources"
          aria-label="Computational Resources"
          onPage={onComp}
        >
          Computational Resources
        </SecondaryLink>
        <SecondaryLink to="/projects" aria-label="Projects" onPage={onProj}>
          Projects
        </SecondaryLink>
        <HoverMenu
          renderAnchor={(props) => (
            <Link {...props} to="sb/help" aria-label="Help" onPage={onHelp}>
              Help
            </Link>
          )}
          renderMenuItems={({ close }) => [
            <MenuItem
              onClick={close}
              key="get-started"
              component={Link}
              to="/sb/get-started"
              aria-label="get-started"
              sx={getStyle('/sb/get-started', location.pathname)}
            >
              Get Started
            </MenuItem>,
            <MenuItem
              onClick={close}
              key="resources"
              component={Link}
              to="/sb/help/resources"
              sx={getStyle('sb/help/resources', location.pathname)}
            >
              Resources
            </MenuItem>,
            <MenuItem
              onClick={close}
              key="sb/cli-help"
              component={Link}
              to="sb/cli-help"
              sx={getStyle('sb/cli-help', location.pathname)}
            >
              CLI Help
            </MenuItem>,
            <MenuItem
              onClick={close}
              key="issues"
              component="a"
              href="https://github.com/MSD-LIVE/issues/issues"
            >
              Issues
            </MenuItem>,
          ]}
          id="help-nav"
        />
      </SecondaryNav>
    </Root>
  );
}
export default observer(Header);
