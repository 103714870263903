import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ProjectService } from 'services';

export default function useSelectProjectByUrl({
  projectService,
}: {
  projectService: ProjectService;
}): void {
  const { id } = useParams();
  useEffect(() => {
    if (id) {
      projectService.selectProject(id);
    }
  }, [id, projectService]);
}
