// NPM packages
import React from 'react';

// All other imports
import MembersTable from 'components/project/MembersTable';
import MembersTableMode from 'types/MembersTableMode';
import Project from 'types/Project';

export function PendingMembers(props: {
  project: Project;
}): React.ReactElement {
  const { project } = props;

  return (
    <div style={{ paddingTop: '6px' }}>
      <MembersTable project={project} mode={MembersTableMode.PENDING_MEMBERS} />
    </div>
  );
}
