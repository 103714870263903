// NPM packages
import React, { useEffect } from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

// All other imports
import { useServices } from 'services';

export interface GlobalErrorBarProps {}

const StyledAlert = styled(Alert)(({ theme: { typography, palette } }) => ({}));

function GlobalErrorBar(props: GlobalErrorBarProps): React.ReactElement | null {
  const { globalErrorService } = useServices();
  const location = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(globalErrorService.closeError, [location.pathname]);
  return (
    <Snackbar
      open={Boolean(globalErrorService.globalError)}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <StyledAlert
        severity="error"
        onClose={
          globalErrorService.globalError?.closeable
            ? globalErrorService.closeError
            : undefined
        }
      >
        <AlertTitle>Something went wrong!</AlertTitle>

        {globalErrorService.globalError?.message}
      </StyledAlert>
    </Snackbar>
  );
}

export default observer(GlobalErrorBar);
