import ProjectLinks from './ProjectLinks';
import ProjectResource from './ProjectResource';
import User from './User';
import { makeObservable, observable, runInAction, when } from 'mobx';
import ProjectJson from './ProjectJson';
import { AuthService, RdmApiService, GlobalErrorService } from 'services';
import UserJson from './UserJson';
import UserRole from './UserRole';
import { isLoaded } from 'utils';
import ConfirmationError from './errors/ConfirmationError';
import { memoize } from 'lodash';

export interface Contact {
  firstName: string;
  lastName: string;
  email: string;
  affiliation: string;
}

export default class Project {
  authService: AuthService;
  rdmApiService: RdmApiService;
  globalErrorService: GlobalErrorService;
  title: string = '';
  subtitle: string = '';
  description: string = '';
  longDescription: string = '';
  resources: ProjectResource[] = [];
  contact: Contact = {
    firstName: '',
    lastName: '',
    email: '',
    affiliation: '',
  };
  slug: string = '';
  id: string = '';
  links: ProjectLinks = { logo: '' };
  members: User[] | null | undefined = undefined;
  pendingMembers: User[] | null | undefined = undefined;

  constructor({
    projectData,
    authService,
    rdmApiService,
    globalErrorService,
  }: {
    projectData: ProjectJson;
    authService: AuthService;
    rdmApiService: RdmApiService;
    globalErrorService: GlobalErrorService;
  }) {
    this.authService = authService;
    this.rdmApiService = rdmApiService;
    this.globalErrorService = globalErrorService;
    this.updateFromJson(projectData);
    makeObservable(this, {
      title: observable,
      subtitle: observable,
      description: observable,
      longDescription: observable,
      resources: observable,
      contact: observable,
      slug: observable,
      id: observable,
      links: observable,
      members: observable,
      pendingMembers: observable,
    });
  }

  private updateFromJson = (json: ProjectJson): void => {
    runInAction(() => {
      const metadata = json.metadata;
      this.title = metadata.title;
      this.subtitle = metadata.subtitle;
      this.description = metadata.description;
      this.longDescription = metadata.long_description;
      this.resources = metadata.resources.map((resource) => ({
        ...resource,
        private: resource.private || false,
      }));
      this.contact = {
        firstName: metadata.contact.first_name,
        lastName: metadata.contact.last_name,
        email: metadata.contact.email,
        affiliation: metadata.contact.affiliation,
      };
      this.slug = json.slug;
      this.id = json.id;
      this.links = {
        logo: `${this.rdmApiService.axios.defaults.baseURL}/projects/${this.id}/logo`,
      };
    });
  };

  requestToJoin = async (): Promise<void> => {
    await this.authService.user?.addRole({
      projectSlug: this.slug,
      role: UserRole.PENDING,
    });
    runInAction(() => {
      if (this.pendingMembers === undefined || this.pendingMembers === null) {
        // This should always be the case since if the user should
        // only be requesting if they are not already a member (and therefore
        // can not see the pending members list)
        this.pendingMembers = [this.authService.user!];
      } else {
        // Something isn't right. The user is already a member?
        throw new Error(
          'Something went wrong: maybe the user is already a member?'
        );
      }
    });
  };

  approveMember = async (member: User): Promise<void> => {
    await when(() => isLoaded(this.pendingMembers));
    await when(() => isLoaded(this.members));

    await member.addRole({
      projectSlug: this.slug,
      role: UserRole.MEMBER,
    });
    runInAction(() => {
      const oldPendingMembers = this.pendingMembers || [];
      this.pendingMembers = oldPendingMembers.filter(
        (pendingMember) => pendingMember !== member
      );
      if (this.members === undefined || this.members === null) {
        this.members = [];
      }
      this.members = [...this.members, member];
    });
  };

  removeMember = async (member: User): Promise<void> => {
    await when(() => isLoaded(this.members));
    await when(() => isLoaded(this.pendingMembers));

    try {
      await member.removeRole({
        projectSlug: this.slug,
        role: UserRole.MEMBER,
      });
      runInAction(() => {
        this.members = this.members!.filter(
          (currentMember) => currentMember !== member
        );
        this.pendingMembers = this.pendingMembers!.filter(
          (currentMember) => currentMember !== member
        );
      });
    } catch (error: unknown) {
      if (error instanceof ConfirmationError) {
        // The user did not confirm
        return;
      }
      throw error;
    }
  };

  updateMetadata = async (data: {
    title: string;
    subtitle: string;
    description: string;
    longDescription: string;
    resources: ProjectResource[];
    contact: Contact | null;
  }): Promise<void> => {
    const rdmContact = {
      first_name: data.contact?.firstName,
      last_name: data.contact?.lastName,
      affiliation: data.contact?.affiliation,
      email: data.contact?.email,
    };
    const response = await this.rdmApiService.axios.post(
      `/projects/${this.slug}`,
      {
        title: data.title,
        subtitle: data.subtitle,
        description: data.description,
        resources: data.resources,
        long_description: data.longDescription,
        contact: rdmContact,
      }
    );
    this.updateFromJson(response.data);
  };

  // Needed because otherwise the logo is cached and doesn't update
  private refreshLinks = (): void => {
    const logoUrl = new URL(this.links.logo);
    logoUrl.search = `?time=${new Date().getTime()}`;
    runInAction(() => {
      this.links = {
        logo: logoUrl.toString(),
      };
    });
  };

  updateLogo = async (logo: File): Promise<void> => {
    await this.rdmApiService.axios.put(`/projects/${this.slug}/logo`, logo, {
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    });
    this.refreshLinks();
  };

  // Memoize so that we only load the members once
  loadMembers = memoize(async (): Promise<void> => {
    if (this.members !== undefined && this.pendingMembers !== undefined) {
      return;
    }

    runInAction(() => {
      this.members = undefined;
      this.pendingMembers = undefined;
    });

    if (this.authService.user) {
      const membersResponse = await this.rdmApiService.axios.get(
        `/projects/members/${this.slug}`
      );
      const members = membersResponse.data.map((userJson: UserJson) =>
        this.authService.cacheUserIfNeeded(userJson)
      );
      let pendingMembers: User[] | null = null;
      if (this.authService.user.isAdmin(this.slug)) {
        const pendingMembersResponse = await this.rdmApiService.axios.get(
          `/projects/pending_members/${this.slug}`
        );
        pendingMembers = pendingMembersResponse.data.map((userJson: UserJson) =>
          this.authService.cacheUserIfNeeded(userJson)
        );
      }
      runInAction(() => {
        this.members = members;
        this.pendingMembers = pendingMembers;
      });
    } else {
      runInAction(() => {
        this.members = [];
        this.pendingMembers = [];
      });
    }
  });
}
