// NPM packages
import React from 'react';
import { Link } from '@mui/material';
import { observer } from 'mobx-react-lite';

// All other imports
import { useServices } from 'services';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export interface DynamicLinkProps {
  muiProps: { href?: string; target?: string; rel?: string };
  children: React.ReactNode;
}

type Conversions = { [k: string]: string };

const convertDynamicLink = (
  link: string | undefined,
  conversions: Conversions
): string | undefined => {
  if (!link) return undefined;
  let newLink = link;
  Object.keys(conversions).forEach((key) => {
    newLink = newLink.replaceAll(key, conversions[key]);
  });
  return newLink;
};

function DynamicLink(props: DynamicLinkProps): React.ReactElement | null {
  const { muiProps, children } = props;
  const { authService } = useServices();
  const location = useLocation();

  const conversions = {
    '<login>': authService.getLogInUrl({ location }),
    '<rdm>': `data.${window.location.hostname}`,
    '<web>': `${window.location.hostname}`,
  };

  muiProps.href = convertDynamicLink(muiProps.href, conversions);

  const isExternalLink = muiProps.target !== '_self';
  if (isExternalLink) {
    muiProps.target = '_blank';
    muiProps.rel = 'noopener noreferrer';
  }

  const linkIcon = isExternalLink ? (
    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
  ) : null;

  return (
    <Link {...muiProps}>
      {children} {linkIcon}
    </Link>
  );
}

export default observer(DynamicLink);
