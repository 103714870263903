// NPM packages
import React, { useEffect } from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { useSearchParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

// All other imports
import PageContent from 'components/core/PageContent';
import useLoadingStateManager from 'hooks/useLoadingStateManager';
import { useServices } from 'services';
import SpinnerOutlet from 'components/core/SpinnerOutlet';
import ErrorOutlet from 'components/core/ErrorOutlet';
import Link from 'components/core/Link';
import SuccessOutlet from 'components/core/SuccessOutlet';

const Root = styled(PageContent)({});
const LogInLink = styled(Link)(({ theme: { typography } }) => ({
  fontWeight: typography.fontWeightBold,
}));
const SpinnerWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export interface VerifyEmailProps {
  className?: string;
}

function VerifyEmail(props: VerifyEmailProps): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();
  const location = useLocation();
  const loadingStateManager = useLoadingStateManager();
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  useEffect(() => {
    loadingStateManager.startLoading({
      waitOn: authService.verifyEmail({ email, code }),
      // getErrorMessage:
      // getSuccessMessage:
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Root className={className}>
      <Typography variant="h1">Verifying Email</Typography>
      <ErrorOutlet
        loadingStateManager={loadingStateManager}
        getErrorMessage={(rejectedVal) =>
          rejectedVal.message ?? 'Failed to verify email'
        }
      />
      <SuccessOutlet
        loadingStateManager={loadingStateManager}
        getSuccessMessage={() => (
          <Typography>
            Once your account is approved, you can{' '}
            <LogInLink
              to={authService.getLogInUrl({
                location,
                nextUrl: `${window.location.protocol}//${window.location.host}`,
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              Log In
            </LogInLink>{' '}
            to get started with MSD-LIVE!
          </Typography>
        )}
      />
      <SpinnerWrapper>
        <SpinnerOutlet
          loadingStateManager={loadingStateManager}
          circularProgressProps={{
            size: 50,
          }}
        />
      </SpinnerWrapper>
    </Root>
  );
}
export default observer(VerifyEmail);
