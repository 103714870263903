// NPM packages
import React, { MouseEvent, useEffect } from 'react';
import {
  Card,
  Backdrop,
  Typography,
  CardMedia,
  CardContent,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

const Root = styled(Backdrop)({
  zIndex: '1301',
});

const ModifiedTypography = styled(Typography)({
  marginBottom: '0px',
  maxWidth: '100%',
});

const Header = styled(ModifiedTypography)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Title = styled(ModifiedTypography)({
  width: '955px',
  fontWeight: 'bold',
});

export interface GifViewerProps {
  title: string;
  link: string;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<number | null>>;
  className?: string;
}

function GifViewer(props: GifViewerProps): React.ReactElement | null {
  const { title, link, open, setOpen } = props;

  // Typing of e could probably be improved so ts-ignore is not used
  const handleBackdropClick = (e: MouseEvent): void => {
    // @ts-ignore - Can ignore e.target.classList potentially being undefined since classList would become [] in such a scenario
    const classList = e.target.classList ? [...e.target.classList] : [];
    if (classList.includes('MuiBackdrop-root')) setOpen(null);
  };

  useEffect(() => {
    const closeOnEscape = (e: KeyboardEvent): void => {
      if (e.key === 'Escape') {
        setOpen(null);
      }
    };
    window.addEventListener('keyup', closeOnEscape);
    return () => window.removeEventListener('keyup', closeOnEscape);
  });

  return (
    <Root open={open} onClick={handleBackdropClick}>
      <Card>
        <CardContent>
          <Header>
            <Title>{title}</Title>
            <IconButton
              onClick={() => {
                setOpen(null);
              }}
            >
              <Close />
            </IconButton>
          </Header>
        </CardContent>
        <CardMedia>
          <img
            src={link}
            height="732.8"
            width="1024"
            alt={`Gif providing help with ${title}`}
          />
        </CardMedia>
      </Card>
    </Root>
  );
}

export default observer(GifViewer);
