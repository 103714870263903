// NPM packages
import React, { useMemo, useState } from 'react';
import {
  Autocomplete,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller, ControllerProps, Path } from 'react-hook-form';
import merge from 'lodash/merge';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

// All other imports
import { FormState } from 'hooks/useForm';
import { callFuncsInOrder } from 'utils';

type ControllerPropsNoRender<T extends object> = Omit<
  ControllerProps<T>,
  'render'
>;

export interface TextFieldAutocompleteProps<FormData extends object> {
  formState: FormState<FormData>;
  fieldName: Path<FormData>;
  textFieldProps?: Omit<MuiTextFieldProps, 'onChange' | 'onBlur'>;
  controllerProps?: ControllerPropsNoRender<FormData>;
  children?: MuiTextFieldProps['children'];
  autocompleteOptions: string[] | undefined;
  searchForOptions: Function;
  optionsLoading: boolean;
}

interface AnnotatedOption {
  label: string;
  value: string;
}

function getAnnotatedOptions(
  options: string[] | undefined
): AnnotatedOption[] | undefined {
  if (options === undefined) {
    return undefined;
  }

  return options.map((option) => {
    return {
      label: option,
      value: option,
    };
  });
}

function TextFieldAutocomplete<FormData extends object>(
  props: TextFieldAutocompleteProps<FormData>
): React.ReactElement | null {
  const {
    formState: { control },
    fieldName,
    textFieldProps,
    controllerProps,
    children,
    autocompleteOptions,
    searchForOptions,
    optionsLoading,
  } = props;
  const defaultControllerProps: ControllerPropsNoRender<FormData> = {
    control,
    name: fieldName,
    rules: { required: 'Required' },
  };
  const finalControllerProps: ControllerPropsNoRender<FormData> = merge(
    defaultControllerProps,
    controllerProps
  );

  const defaultTextFieldProps: TextFieldAutocompleteProps<FormData>['textFieldProps'] =
    {
      id: kebabCase(fieldName),
      label: startCase(fieldName),
      fullWidth: true,
      margin: 'dense',
      size: 'small',
      children,
    };
  const finalTextFieldProps: TextFieldAutocompleteProps<FormData>['textFieldProps'] =
    merge(defaultTextFieldProps, textFieldProps);

  const [inputValue, setInputValue] = useState('');

  const annotatedOptions = useMemo(
    () => getAnnotatedOptions(autocompleteOptions),
    [autocompleteOptions]
  );

  const searchPlaceholderLabel = 'Search...';
  const searchPlaceholderValue = '@@@searchPlaceholder';

  return (
    <Controller
      {...finalControllerProps}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const showError = Boolean(error);
        const {
          onChange: fieldOnChange,
          onBlur: fieldOnBlur,
          value: fieldValue,
          ...otherFieldProps
        } = field;

        const inputInOptions = autocompleteOptions?.includes(inputValue);
        const valueSelected = fieldValue === inputValue;
        let options: AnnotatedOption[] | undefined;
        if (inputValue === '') {
          options = [
            {
              label: searchPlaceholderLabel,
              value: searchPlaceholderValue,
            },
          ];
        } else if (!valueSelected && !inputInOptions) {
          options = [
            {
              label: 'Add "' + inputValue + '"',
              value: inputValue,
            },
            ...(annotatedOptions || []),
          ];
        } else {
          options = annotatedOptions;
        }

        return (
          <Autocomplete
            freeSolo
            loading={optionsLoading}
            value={fieldValue}
            ListboxProps={{ style: { maxHeight: 248 } }}
            options={options || []}
            getOptionDisabled={(option) => {
              return option.value === searchPlaceholderValue ? true : false;
            }}
            onChange={(event, value) => {
              if (value === null) {
                fieldOnChange('');
              } else if (typeof value === 'object' && !Array.isArray(value)) {
                fieldOnChange(value.value);
              } else {
                throw new Error('Unexpected value type');
              }
            }}
            onInputChange={(event, newInputValue) => {
              searchForOptions(newInputValue);
              setInputValue(newInputValue);
            }}
            onBlur={callFuncsInOrder([
              () => {
                // The user can type into the input and then not select
                // an option. If this happens then the input will be
                // out of sync with the selected value.
                if (inputValue !== fieldValue) {
                  // Show the selected value in the input again.
                  setInputValue(fieldValue);
                }
              },
              fieldOnBlur,
            ])}
            inputValue={inputValue}
            renderInput={(params) => {
              return (
                <MuiTextField
                  {...params}
                  error={showError}
                  helperText={error?.message}
                  placeholder="Search or create"
                  {...otherFieldProps}
                  {...finalTextFieldProps}
                />
              );
            }}
          />
        );
      }}
    />
  );
}

export default observer(TextFieldAutocomplete);
