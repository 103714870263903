import { ComplexDataset } from 'services/MetricsService';
import {
  changeMargins,
  generateTickData,
  generateTickIndicies,
  getColors,
  getCurrKeys,
  maxHeight,
  maxWidth,
  usualMargins,
} from './utils';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { filesize } from 'filesize';
import { MSDTooltip } from './MSDTooltip';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import ChartDataLabel from './ChartDataLabel';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip as MuiTooltip, useTheme } from '@mui/material';

const Root = styled.div({
  // Required in order to keep static brush width
  // i.e. doesn't let user change brush width
  // '&&&&&&&&& .recharts-brush-traveller': {
  //   display: 'none',
  // },
  // Trying to modify display of text w/ brush
  // '&&&&&&&&& .recharts-brush-texts': {
  //   width: '200px',
  // },
});

const BrushHelper = styled.div(({ theme: { palette } }) => ({
  color: palette.primary.dark,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  zIndex: 1000,
  gap: '0.5rem',
  top: '255px',
  left: '520px',
}));

export interface BarChartProps {
  data: ComplexDataset;
  total: string | number | undefined;
  metricType: string;
  tooltipVar: string;
  tooltipFormatter?: boolean;
  heightPercent?: number;
  marginsChanges?: Record<string, number>;
  stacked?: boolean;
  filesizeFormat?: boolean;
  chartLabelOffsetRight?: number;
}

function MSDBarChart(props: BarChartProps): React.ReactElement {
  const {
    data,
    total,
    metricType,
    heightPercent,
    marginsChanges,
    stacked,
    filesizeFormat,
    tooltipVar,
    tooltipFormatter,
    chartLabelOffsetRight,
  } = props;
  const theme = useTheme();

  const chartHeight = heightPercent ? maxHeight * heightPercent : maxHeight;
  const chartMargins = marginsChanges
    ? changeMargins(marginsChanges)
    : usualMargins;

  const allKeys: string[] | undefined = data && getCurrKeys(data);
  const colors = getColors();

  const [brushStartIndex, setBrushStartIndex] = useState<number>(0);
  const [brushEndIndex, setBrushEndIndex] = useState<number>(data.length);
  const showBrushHelper =
    brushStartIndex === 0 && brushEndIndex >= data.length - 1;

  useEffect(() => {
    setBrushStartIndex(0);
    setBrushEndIndex(data.length);
  }, [data]);

  const onBrushChange = (newIndex: {
    startIndex?: number;
    endIndex?: number;
  }): void => {
    console.log(newIndex);
    setBrushStartIndex(newIndex.startIndex || 0);
    setBrushEndIndex(newIndex.endIndex || data.length);
  };

  const brushLength = brushEndIndex - brushStartIndex;
  const tickIndicies = generateTickIndicies(brushLength);
  const offsetTickIndicies = tickIndicies.map(
    (index) => index + brushStartIndex
  );
  const tickData = generateTickData(data, offsetTickIndicies);
  const tooltipText =
    'Click and drag the left and right posts to change the date range. Click and drag the bar to move the date range.';

  return (
    <Root>
      {showBrushHelper && (
        <BrushHelper>
          <div>Date Range Selector</div>
          <MuiTooltip title={tooltipText} style={{ fontSize: '16px' }}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              color={theme.palette.primary.dark}
            />
          </MuiTooltip>
        </BrushHelper>
      )}
      <ChartDataLabel
        data={total}
        name={metricType}
        offsetRight={chartLabelOffsetRight || undefined}
      />
      <BarChart
        width={maxWidth}
        height={chartHeight}
        data={data}
        margin={chartMargins}
        {...{ overflow: 'visible' }}
      >
        <XAxis
          dataKey="day"
          stroke="#093e80"
          minTickGap={48}
          ticks={tickData}
          // Fixes issue where ticks would disappear on 2nd render
          interval={0}
        />
        <YAxis
          stroke="#093e80"
          tickFormatter={filesizeFormat ? filesize : undefined}
        />
        <CartesianGrid horizontal vertical={false} strokeDasharray="3 3" />
        <Brush dataKey={'day'} onChange={onBrushChange} />

        {stacked && allKeys ? (
          allKeys.map((key: string, idx: number) => {
            return (
              <Bar
                type="monotone"
                dataKey={key}
                key={`Bar-${idx}`}
                stroke={colors[idx % colors.length]}
                strokeWidth={0}
                fill={colors[idx % colors.length]}
                fillOpacity={1}
                maxBarSize={40}
                stackId="a"
              />
            );
          })
        ) : (
          <Bar
            type="monotone"
            dataKey="Value"
            stroke="#16adfe"
            strokeWidth={0}
            fill="#16adfe"
            fillOpacity={0.67}
            maxBarSize={40}
          />
        )}

        <Tooltip
          position={{ x: 70, y: 10 }}
          content={
            <MSDTooltip
              varName={tooltipVar}
              varFormatter={tooltipFormatter || false}
              esPluralize={false}
            />
          }
          shared={stacked}
          cursor={{ fill: 'transparent' }}
          contentStyle={{
            border: '2px solid #e0e1e2',
          }}
        />
      </BarChart>
    </Root>
  );
}

export default observer(MSDBarChart);
