// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';

const Root = styled.div({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

interface DetailsPanelErrorProps {
  metric: string;
  className?: string;
}

function DetailsPanelError(
  props: DetailsPanelErrorProps
): React.ReactElement | null {
  const { metric } = props;
  return (
    <Root>
      An error has occurred for this panel and metric{' '}
      {metric ? metric.toLowerCase() : '(no metric)'}
    </Root>
  );
}

export default observer(DetailsPanelError);
