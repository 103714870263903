// NPM packages
import React from 'react';
import { FormHelperText, InputLabel } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ControllerProps, Path, GlobalError } from 'react-hook-form';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import get from 'lodash/get';
import styled from '@emotion/styled';

// All other imports
import { FormState } from 'hooks/useForm';

type ControllerPropsNoRender<T extends object> = Omit<
  ControllerProps<T>,
  'render'
>;
export interface FileUploadProps<FormData extends object> {
  formState: FormState<FormData>;
  fieldName: Path<FormData>;
  controllerProps?: ControllerPropsNoRender<FormData>;
}

const Root = styled.div({
  marginBottom: '0.25rem',
});

function FileUpload<FormData extends object>(
  props: FileUploadProps<FormData>
): React.ReactElement | null {
  const {
    formState: {
      register,
      formState: { errors },
    },
    fieldName,
  } = props;
  const errorForField = get(errors, fieldName, {}) as GlobalError;
  const errorMessage = errorForField.message;

  const id = kebabCase(fieldName);
  const label = startCase(fieldName);

  return (
    <Root>
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <input id={id} type="file" {...register(fieldName)} />

      <FormHelperText error>{errorMessage || ' '}</FormHelperText>
    </Root>
  );
}

export default observer(FileUpload);
