// NPM packages
import React from 'react';
import { Card } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { VideoResourceStoryblok } from 'types/storyblokTypes';
import { storyblokEditable } from '@storyblok/react';
import {
  IMAGE_COLUMN_WIDTH_PX,
  RESOURCE_COMPONENT_COLUMN_GAP as COLUMN_GAP,
} from '../utils';
import ResourceInformation from '../ResourceInformation';

// All other imports

const Root = styled.div(({ theme }) => {
  return {
    minWidth: theme.maxToolWidth,
    marginTop: '1.75rem',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: `${IMAGE_COLUMN_WIDTH_PX}px 45rem`,
    gap: `${COLUMN_GAP}rem`,
  };
});

const VideoContentCard = styled(Card)({
  height: `${IMAGE_COLUMN_WIDTH_PX * 0.565574}px`,
  width: `${IMAGE_COLUMN_WIDTH_PX}px`,
});

const Video = styled.iframe({
  height: `${IMAGE_COLUMN_WIDTH_PX * 0.565574}px`,
  width: `${IMAGE_COLUMN_WIDTH_PX}px`,
});

export interface VideoResourceProps {
  blok: VideoResourceStoryblok;
  className?: string;
}

function VideoResource(props: VideoResourceProps): React.ReactElement | null {
  const { blok, className } = props;
  const thumbnailExists = Boolean(blok.videoUrl);

  return (
    <Root className={className} {...storyblokEditable(blok)}>
      <VideoContentCard variant="elevation" raised>
        {thumbnailExists ? (
          <Video
            src={blok.videoUrl}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            allowFullScreen
          />
        ) : (
          <div></div>
        )}
      </VideoContentCard>
      <ResourceInformation blok={blok} />
    </Root>
  );
}

export default observer(VideoResource);
