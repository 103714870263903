// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import {
  faFilePdf,
  faFilePowerpoint,
} from '@fortawesome/free-regular-svg-icons';

// All other imports
import Link from 'components/core/Link';
import Attachment from 'types/Attachment';

const Root = styled(Link)(({ theme: { palette } }) => ({
  display: 'flex',
  alignItems: 'center',
  color: palette.text.primary,
}));
const Label = styled(Typography)({
  fontSize: '.75rem',
  marginTop: '0.35rem',
});
const StyledIcon = styled(FontAwesomeIcon)(({ theme: { palette } }) => ({
  color: palette.text.primary,
  paddingRight: '0.75rem',
}));

export interface AttachmentThumbnailProps {
  attachment: Attachment;
  openNewTab?: boolean;
  className?: string;
}

export default function AttachmentThumbnail(
  props: AttachmentThumbnailProps
): React.ReactElement | null {
  const {
    attachment: { filePath, label },
    openNewTab,
    className,
  } = props;
  const parts = filePath.split('/');
  const name = parts[parts.length - 1];
  const dotDelimitedParts = name.split('.');
  const extension =
    dotDelimitedParts[dotDelimitedParts.length - 1].toLowerCase();
  let icon = faFile;
  switch (extension) {
    case 'pdf':
      icon = faFilePdf;
      break;
    case 'pptx':
      icon = faFilePowerpoint;
      break;
  }

  return (
    <Root className={className} to={filePath} openNewTab={openNewTab} download>
      <StyledIcon icon={icon} size="xl" />
      <Label>{label}</Label>
    </Root>
  );
}
