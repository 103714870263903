// NPM packages
import React from 'react';

// All other imports
import MembersTable from 'components/project/MembersTable';
import MembersTableMode from 'types/MembersTableMode';
import AddMember from 'components/project/AddMember';
import Project from 'types/Project';
import { TitleBar } from '../styles';
import styled from '@emotion/styled';

const StyledTitleBar = styled(TitleBar)({
  marginBottom: '0.5rem',
});

export function CurrentMembers(props: {
  project: Project;
  isAdmin: boolean | undefined;
}): React.ReactElement {
  const { project, isAdmin } = props;

  return (
    <div style={{ paddingTop: '6px' }}>
      <StyledTitleBar>
        {isAdmin && <AddMember project={project} />}
      </StyledTitleBar>
      <MembersTable project={project} mode={MembersTableMode.CURRENT_MEMBERS} />
    </div>
  );
}
