/* eslint-disable @typescript-eslint/no-explicit-any */
// NPM packages
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Typography } from '@mui/material';

// All other imports
import Link from 'components/core/Link';
import styled from '@emotion/styled';

export interface MarkdownTextProps {
  className?: string;
  markdown: string;
}

const components = {
  h1: ({ node, ...props }: any) => <Typography {...props} variant="h1" />,
  h2: ({ node, ...props }: any) => <Typography {...props} variant="h2" />,
  h3: ({ node, ...props }: any) => <Typography {...props} variant="h3" />,
  h4: ({ node, ...props }: any) => <Typography {...props} variant="h4" />,
  h5: ({ node, ...props }: any) => <Typography {...props} variant="h5" />,
  h6: ({ node, ...props }: any) => <Typography {...props} variant="h6" />,
  a: ({ node, ...props }: any) => {
    const { href, ...otherProps } = props;
    return <Link {...otherProps} to={props.href} />;
  },
  p: ({ node, ...props }: any) => <Typography {...props} />,
  li: ({ node, ...props }: any) => <Typography {...props} component="li" />,
};

export default function MarkdownText(
  props: MarkdownTextProps
): React.ReactElement | null {
  const { className, markdown } = props;
  return (
    <div>
      <ReactMarkdown
        className={className}
        children={markdown}
        components={components}
      />
    </div>
  );
}
