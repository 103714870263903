// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { storyblokEditable } from '@storyblok/react';

// All other imports
import {
  ResourceCategoryListStoryblok,
  ResourceCategoryStoryblok,
} from 'types/storyblokTypes';
import LimitedResourceCategory from './LimitedResourceCategory';
import { Divider } from '@mui/material';
import { StoryblokStory } from 'storyblok-generate-ts';

const Root = styled.div({
  marginBottom: '1.75rem',
});

export interface ResourceCategoryListProps {
  blok: ResourceCategoryListStoryblok;
  className?: string;
}

function ResourceCategoryList(
  props: ResourceCategoryListProps
): React.ReactElement | null {
  const { blok, className } = props;
  const resourceCategories = blok.resourceCategories;
  return (
    <Root className={className} {...storyblokEditable(blok)}>
      {resourceCategories.map(
        (value: StoryblokStory<ResourceCategoryStoryblok> | string, idx) =>
          typeof value === 'object' && (
            <div>
              <LimitedResourceCategory
                blok={value.content}
                key={`resourceCategory-${idx}`}
                flipped={idx % 2 === 1}
              />
              <Divider />
            </div>
          )
      )}
    </Root>
  );
}

export default observer(ResourceCategoryList);
