// NPM packages
import React from 'react';
import styled from '@emotion/styled';

// All other imports
import PageContent from 'components/core/PageContent';
import NotFoundContent from 'components/core/NotFoundContent';

const Root = styled(PageContent)({});

export interface NotFoundProps {
  className?: string;
}

export default function NotFound(
  props: NotFoundProps
): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <NotFoundContent />
    </Root>
  );
}
