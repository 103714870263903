// NPM packages
import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Alert, AlertTitle, useTheme } from '@mui/material';
import styled from '@emotion/styled';

// All other imports
import PageContent from 'components/core/PageContent';
import { useServices } from 'services';
import { observer } from 'mobx-react-lite';
import useSelectProjectByUrl from 'hooks/useSelectProjectByUrl';
import useResourceGuard from 'hooks/useResourceGuard';
import Project from 'types/Project';

import ProjectInfo from './components/ProjectInfo';
import { CurrentMembers } from './components/CurrentMembers';
import { PendingMembers } from './components/PendingMembers';

import H1 from 'components/core/H1';
import JoinButton from 'components/project/JoinButton';
import { TitleBar } from './styles';

import { useLocation, useNavigate } from 'react-router-dom';

const Root = styled(PageContent)({});

const PendingAlert = styled(Alert)({
  marginBottom: '1rem',
});

const PendingMembersAlert = styled.div`
  height: 20px;
  padding: 0 4px;
  border-radius: 4px;
  background-color: orange;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
`;

const StyledH1 = styled(H1)(({ theme: { palette } }) => ({
  color: palette.primary.main,
  marginBottom: 0,
}));

const StyledTabs = styled(Tabs)({
  height: '56px',
  marginBottom: '12px',
  '.MuiTab-root': {
    height: '72px',
  },
});

export interface ProjectDetailsProps {
  className?: string;
}

function ProjectDetails(props: ProjectDetailsProps): React.ReactElement | null {
  const { className } = props;
  const { projectService, authService } = useServices();
  useSelectProjectByUrl({ projectService });
  const guardContent = useResourceGuard({
    resource: projectService.selectedProject,
  });
  const theme = useTheme();

  const [currTab, setCurrTab] = useState<number>(0);

  const location = useLocation().pathname.split('/');
  const tab = location[location.length - 1];

  useEffect(() => {
    switch (tab.toLowerCase()) {
      case 'info':
        setCurrTab(0);
        break;
      case 'current':
        setCurrTab(1);
        break;
      case 'pending':
        setCurrTab(2);
        break;
    }
  }, [tab]);

  const navigate = useNavigate();
  const redirect = (tab: 'info' | 'current' | 'pending'): void => {
    let newpath = '';
    location.forEach((str, idx) => {
      if (idx !== location.length - 1) {
        newpath += idx === 0 ? str : '/' + str;
      }
    });
    newpath += '/' + tab;
    navigate(newpath, { replace: true });
  };

  let body: React.ReactNode;

  if (guardContent) {
    body = guardContent;
  } else {
    // We know the project is not null or a NotFoundError since
    // the guard returned null
    const project = projectService.selectedProject as Project;
    const isAdmin = authService.user?.isAdmin(project.slug);
    const isPending = authService.user?.isPending(project.slug);
    const pendingMembersExist =
      project.pendingMembers && project.pendingMembers.length > 0;

    body = (
      <>
        {isPending && (
          <PendingAlert severity="info">
            <AlertTitle>Membership Awaiting Approval</AlertTitle>
            Your request to join {project.title} is pending approval.
          </PendingAlert>
        )}
        <div>
          <TitleBar>
            <div>
              <StyledH1>{project.title}</StyledH1>
            </div>

            <JoinButton project={project} size="medium" includeEdit />
          </TitleBar>
          <StyledTabs value={currTab}>
            <Tab
              value={0}
              label="Project Info"
              onClick={() => redirect('info')}
            />
            <Tab
              value={1}
              label="Current Members"
              onClick={() => redirect('current')}
            />
            <Tab
              value={2}
              label="Pending Members"
              onClick={() => redirect('pending')}
              icon={
                pendingMembersExist && isAdmin ? (
                  <PendingMembersAlert>
                    <div style={{ marginTop: '1px' }}>
                      {project.pendingMembers && project.pendingMembers.length}
                    </div>
                  </PendingMembersAlert>
                ) : undefined
              }
              iconPosition={'end'}
            />
          </StyledTabs>
          {currTab === 0 && <ProjectInfo project={project} />}
          {currTab === 1 && (
            <CurrentMembers project={project} isAdmin={isAdmin} />
          )}
          {currTab === 2 && <PendingMembers project={project} />}
        </div>
      </>
    );
  }

  return (
    <Root className={className} maxWidth={theme.maxToolWidth}>
      {body}
    </Root>
  );
}

export default observer(ProjectDetails);
