// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

// All other imports
import H1 from 'components/core/H1';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

export interface NotFoundContentProps {
  className?: string;
}

export default function NotFoundContent(
  props: NotFoundContentProps
): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <H1>Page Not Found</H1>
      <Typography>
        Please shoot us an email if this doesn't seem right.
      </Typography>
    </Root>
  );
}
