// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import CallToAction, { CallToActionProps } from 'components/home/CallToAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faUsers } from '@fortawesome/free-solid-svg-icons';

// All other imports
import { useServices, EnvService } from 'services';
import H1 from 'components/core/H1';
import { observer } from 'mobx-react-lite';

const Root = styled.div({});
const ShoutOut = styled.div(
  ({ theme: { outermostPadding, typography, breakpoints } }) => ({
    // Background color is needed to make text readable if image
    // is slow to load or does not load.
    backgroundColor: '#0d2249',
    backgroundImage: 'url(background.jpeg)',
    backgroundSize: 'cover',
    [breakpoints.down('xl')]: {
      // Layers:
      // 1. Background color to make text readable if image is slow to load
      //    or does not load.
      // 2. Background image
      // 3. Gradient which is used to darken the image on small screens. This
      //    is needed because the image includes white orbs on the right hand
      //    side. On small screens, those orbs shift underneath the text as
      //    browser "zooms in" on the background image. Since the text is white,
      //    that causes contrast issues which need to be corrected.
      background:
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(background.jpeg), #0d2249',
    },
    ...outermostPadding({
      vertical: {
        mobile: typography.pxToRem(30),
        tablet: typography.pxToRem(33),
        desktop: typography.pxToRem(35),
      },
    }),
  })
);
const StyledH1 = styled(H1)(
  ({ theme: { palette, typography, breakpoints } }) => ({
    color: palette.common.white,
    width: 'min-content',
    marginBottom: '2rem',
    fontSize: '3rem',
    [breakpoints.up('xl')]: {
      marginBottom: '2rem',
      fontSize: '3.2rem',
    },
  })
);
const Description = styled(Typography)(
  ({ theme: { breakpoints, typography, palette } }) => ({
    color: palette.common.white,
    fontFamily: typography.h1.fontFamily,
    fontSize: typography.pxToRem(24),
    fontStyle: 'italic',
    [breakpoints.up('xl')]: {
      fontSize: '1.7rem',
      maxWidth: '50%',
    },
  })
);
const callToActionClassName = 'about-call-to-action';
const Content = styled.div(({ theme: { outermostPadding, typography } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  [`.${callToActionClassName}`]: {
    padding: '1rem 3rem',
  },
  ...outermostPadding({
    vertical: {
      desktop: '3rem',
      tablet: '2rem',
      mobile: '1rem',
    },
  }),
}));

const Column = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

// TODO: Need Devin's help - Add a media query so that mobile or tablet size adds the flex-wrap: wrap setting.
const Row = styled.div({
  display: 'flex',
  justifyContent: 'center',
});

export interface AboutProps {
  className?: string;
}

function About(props: AboutProps): React.ReactElement | null {
  const { className } = props;
  const { envService } = useServices();
  const callsToAction = getCallsToAction({ envService });
  return (
    <Root className={className}>
      <ShoutOut>
        <StyledH1 variant="h1">
          Living Intuitive Value&#8209;adding Environment
        </StyledH1>
        <Description>
          A collaborative data and computational platform for the MultiSector
          Dynamics community
        </Description>
      </ShoutOut>
      <Content>
        <Column>
          <Row>
            <CallToAction
              {...callsToAction[0]}
              className={callToActionClassName}
            />
            <CallToAction
              {...callsToAction[1]}
              className={callToActionClassName}
            />
          </Row>
          <Row>
            <CallToAction
              {...callsToAction[2]}
              className={callToActionClassName}
            />
            <CallToAction
              {...callsToAction[3]}
              className={callToActionClassName}
            />
          </Row>
        </Column>
      </Content>
    </Root>
  );
}

function getCallsToAction({
  envService,
}: {
  envService: EnvService;
}): CallToActionProps[] {
  return [
    {
      title: 'Data & Code Repository',
      description:
        'Discover and share curated MSD datasets, codes, and workflows.',
      renderIcon: ({ className }) => (
        <FontAwesomeIcon icon={faDatabase} className={className} size="9x" />
      ),

      href: envService.getValue('DATA_REPO_URL') || '',
    },
    {
      title: 'Computational Resources',
      description:
        'Use Jupyter Notebooks to analyze or visualize data stored in MSD-LIVE.',
      renderIcon: ({ className }) => (
        <img src="/compute_icon.svg" alt="" className={className} />
      ),
      href: '/sb/computational-resources',
    },
    {
      title: 'Project Services',
      description:
        'Create and manage project teams and their collaborative tools and resources.',
      renderIcon: ({ className }) => (
        <FontAwesomeIcon icon={faUsers} className={className} size="9x" />
      ),
      href: '/projects',
    },
    {
      title: 'Need Help?',
      description: 'Learn how to start using MSD-LIVE to manage your data.',
      renderIcon: ({ className }) => (
        <img src="/get_started_icon.svg" alt="" className={className} />
      ),
      href: '/sb/get-started',
    },
  ];
}
export default observer(About);
