export default class EnvService {
  getValue = (varName: string): string | undefined => {
    if (varName === 'NODE_ENV') {
      return process.env.NODE_ENV;
    }
    const fullName = `REACT_APP_${varName}`;
    return process.env[fullName];
  };

  isSet = (varName: string): boolean => {
    return this.getValue(varName) !== undefined;
  };
}
