// NPM packages
import React from 'react';
import { Card } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { AssetStoryblok, MultiassetStoryblok } from 'types/storyblokTypes';
import { LIMITED_RESOURCE_CATEGORY_MAX_HEIGHT as MAX_HEIGHT } from './utils';

const ImageContainer = styled.div<{ flipped: boolean }>(({ flipped }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    width: '96.3%',
    marginLeft: flipped ? '3.7%' : 0,
  };
});

enum Placements {
  TOPLEFT = 'topleft',
  TOPRIGHT = 'topright',
  BOTTOMLEFT = 'bottomleft',
  BOTTOMRIGHT = 'bottomright',
  CENTER = 'center',
}

const ImageCard = styled(Card)<{
  placement: Placements;
}>(({ placement }) => {
  const placeOnTop = placement.includes('top');
  const placeOnLeft = placement.includes('left');
  const placeOnRight = placement.includes('right');
  const placeOnBottom = placement.includes('bottom');
  const placeInCenter = placement.includes('center');
  return {
    position: placeInCenter ? 'relative' : 'absolute',
    top: placeOnTop ? 0 : undefined,
    left: placeOnLeft ? 0 : undefined,
    right: placeOnRight ? 0 : undefined,
    bottom: placeOnBottom ? 0 : undefined,
    height: placeInCenter ? 'min-content' : undefined,
  };
});

const Image = styled.img<{
  ratio: number;
}>(({ ratio }) => {
  return {
    display: 'block',
    height: `${MAX_HEIGHT * ratio}px`,
    width: `${MAX_HEIGHT * 2 * ratio}px`,
    objectFit: 'cover',
  };
});

interface LimitedResourceCategoryThumbnailProps {
  images: MultiassetStoryblok;
  flipped: boolean;
  className?: string;
}

function LimitedResourceCategoryThumbnail(
  props: LimitedResourceCategoryThumbnailProps
): React.ReactElement | null {
  const { images, flipped } = props;
  const numImages = images.length;

  let ratio = undefined;
  let placements = undefined;

  if (numImages === 0) {
    return null;
  } else if (numImages === 1) {
    ratio = 1;
    placements = [Placements.CENTER];
  } else if ([2, 3].includes(numImages)) {
    ratio = 0.67;
    placements = [Placements.TOPLEFT, Placements.BOTTOMRIGHT];
  } else {
    ratio = 0.5;
    placements = [
      Placements.TOPLEFT,
      Placements.TOPRIGHT,
      Placements.BOTTOMRIGHT,
      Placements.BOTTOMLEFT,
    ];
  }

  return (
    <ElevatedImages
      images={images}
      ratio={ratio}
      placements={placements}
      flipped={flipped}
    />
  );
}

interface ElevatedImagesProps {
  images: AssetStoryblok[];
  ratio: number;
  placements: Placements[];
  flipped: boolean;
}

function ElevatedImages(props: ElevatedImagesProps): React.ReactElement {
  const { images, ratio, placements, flipped } = props;
  const numImages = images.length;

  const fourImagesStyling = {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  };

  return (
    <ImageContainer flipped={flipped}>
      <MidContainer fourImages={numImages === 4}>
        {images.map((image, idx) => {
          return (
            <ImageCard
              variant="elevation"
              placement={placements[idx]}
              key={`img-${ratio}-${idx}`}
              raised={numImages !== 4}
              sx={numImages === 4 ? fourImagesStyling : undefined}
            >
              <Image alt="" src={image.filename} ratio={ratio} />
            </ImageCard>
          );
        })}
      </MidContainer>
    </ImageContainer>
  );
}

interface MidContainerProps {
  fourImages?: boolean;
  children?: React.ReactNode;
}

const FourImageCard = styled(Card)({
  height: '100%',
  width: '100%',
  borderBottomLeftRadius: '3px',
});

function MidContainer(props: MidContainerProps): React.ReactElement {
  const { fourImages, children } = props;
  if (fourImages) {
    return (
      <FourImageCard variant="elevation" raised>
        {children}
      </FourImageCard>
    );
  } else {
    return <div>{children}</div>;
  }
}

export default observer(LimitedResourceCategoryThumbnail);
