// NPM packages
import React from 'react';
import { Tooltip, Button, ButtonProps } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import ButtonLink from 'components/core/ButtonLink';

// All other imports
import { useServices } from 'services';
import Project from 'types/Project';
import CircularProgress from 'components/core/CircularProgress';

const Root = styled(Tooltip)({
  // EXAMPLE -> display: 'flex'
});

export interface JoinButtonProps {
  className?: string;
  project: Project;
  size?: ButtonProps['size'];
  includeEdit: boolean;
}

function JoinButton(props: JoinButtonProps): React.ReactElement | null {
  const { className, project, size = 'small', includeEdit } = props;
  const { authService } = useServices();
  const isAdmin = authService.user?.isAdmin(project.slug);

  if (includeEdit && isAdmin) {
    return (
      <ButtonLink to={`/projects/edit/${project.slug}`} size={size}>
        Edit Metadata
      </ButtonLink>
    );
  }
  const isProjectMember = authService.user?.isMember(project.slug);
  const isPendingMember = authService.user?.isPending(project.slug);

  let joinDisabledMessage = '';
  if (!authService.isLoggedIn) {
    joinDisabledMessage = 'Log in to join';
  } else if (isAdmin) {
    joinDisabledMessage = 'Admins are automatically members';
  } else if (isPendingMember) {
    joinDisabledMessage = 'Request pending';
  } else if (isProjectMember) {
    joinDisabledMessage = 'Already a member';
  }

  const loading = authService.user?.loading;
  return (
    <Root title={joinDisabledMessage} className={className}>
      <span>
        <Button
          onClick={async () => {
            await project.requestToJoin();
          }}
          disabled={Boolean(loading || joinDisabledMessage)}
          size={size}
        >
          {loading && <CircularProgress insideButton buttonSize={size} />}
          Join
        </Button>
      </span>
    </Root>
  );
}

export default observer(JoinButton);
