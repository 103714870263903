// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

// All other imports
import TextField from 'components/core/TextField';
import { useServices } from 'services';
import Form from 'components/core/Form';

export interface ResendVerificationProps {
  className?: string;
  email: string | null;
}

type FormData = {
  email: string;
};

const schema: yup.ObjectSchema<FormData> = yup.object({
  email: yup.string().required().email().label('Email'),
});

function ResendVerification(
  props: ResendVerificationProps
): React.ReactElement | null {
  const { className, email } = props;
  const { authService } = useServices();

  return (
    <Form<FormData>
      className={className}
      defaultValues={{ email: email ?? '' }}
      schema={schema}
      onSubmit={(data) => authService.resendVerificationEmail(data)}
      getErrorMessage={({ rejectedVal }) =>
        rejectedVal.message ?? 'Failed to resend link'
      }
      getSuccessMessage={() => 'Verification email sent!'}
      submitText="Send Verification"
    >
      {(formState) => (
        <TextField
          formState={formState}
          fieldName="email"
          textFieldProps={{ type: 'email' }}
        />
      )}
    </Form>
  );
}

export default observer(ResendVerification);
