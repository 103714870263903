import { useState } from 'react';
import LoadingStateManager, {
  LoadingStatus,
  LoadingState,
  StartLoadingFunc,
  ClearStateFunc,
} from 'types/LoadingStateManager';

export default function useLoadingStateManager(): LoadingStateManager {
  const [state, setState] = useState<LoadingState>({
    status: LoadingStatus.IDLE,
    rejectedVal: null,
    resolvedVal: null,
  });

  const startLoading: StartLoadingFunc = async ({ waitOn }) => {
    setState({
      status: LoadingStatus.LOADING,
      resolvedVal: null,
      rejectedVal: null,
    });

    try {
      const resolvedVal = await waitOn;

      setState({
        status: LoadingStatus.SUCCESS,
        resolvedVal,
        rejectedVal: null,
      });
    } catch (rejectedVal) {
      setState({
        status: LoadingStatus.ERROR,
        resolvedVal: null,
        rejectedVal,
      });
    }
  };

  const clearState: ClearStateFunc = () => {
    setState({
      status: LoadingStatus.IDLE,
      resolvedVal: null,
      rejectedVal: null,
    });
  };

  return {
    loadingState: state,
    startLoading,
    clearState,
  };
}
