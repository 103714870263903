// NPM packages
import React from 'react';
import styled from '@emotion/styled';

// All other imports

const Root = styled.div({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  margin: '1rem 0',
});

export interface HorizontallyCenteredProps {
  className?: string;
  children: React.ReactNode;
}

export default function HorizontallyCentered(
  props: HorizontallyCenteredProps
): React.ReactElement | null {
  const { className, children } = props;
  return <Root className={className}>{children}</Root>;
}
