// NPM packages
import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, List, ListItem, ListItemText } from '@mui/material';
import styled from '@emotion/styled';
import { NavLink, NavLinkProps, useLocation } from 'react-router-dom';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

// All other imports
import NavItem from 'types/NavItem';
import SwipeableDrawer from 'components/core/SwipeableDrawer';

const Root = styled('div')({
  // EXAMPLE -> display: 'flex'
});
const DrawerLink = styled(NavLink)({
  color: 'inherit',
  textDecoration: 'none',
});
const CloseButton = styled(IconButton)({
  position: 'fixed',
  top: 0,
  right: '-1000px',
  '&:focus': {
    position: 'absolute',
    top: 0,
    right: 0,
  },
});

export interface MobileMenuProps {
  className?: string;
  items: NavItem[];
}

export default function MobileMenu(
  props: MobileMenuProps
): React.ReactElement | null {
  const { className, items } = props;
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const toggleMenu = useCallback(() => {
    setMenuIsOpen((isOpen) => !isOpen);
  }, []);
  const location = useLocation().pathname;
  return (
    <Root className={className}>
      <IconButton onClick={toggleMenu} size="large" aria-label="open menu">
        <FontAwesomeIcon icon={faBars} />
      </IconButton>
      <SwipeableDrawer
        open={menuIsOpen}
        onClose={toggleMenu}
        onOpen={toggleMenu}
      >
        <CloseButton aria-label="close navigation menu" onClick={toggleMenu}>
          <FontAwesomeIcon icon={faTimes} />
        </CloseButton>
        <List>
          {items.map((item) => {
            const selected = location === item.href;
            return (
              <li key={item.href}>
                <ListItem
                  component={React.forwardRef<
                    HTMLAnchorElement | null,
                    NavLinkProps
                  >((props: NavLinkProps, ref) => (
                    <DrawerLink {...props} ref={ref} />
                  ))}
                  to={item.href}
                  onClick={toggleMenu}
                >
                  <ListItemText
                    primary={
                      <div
                        style={selected ? { fontWeight: 'bold' } : undefined}
                      >
                        {item.text}
                      </div>
                    }
                  />
                </ListItem>
              </li>
            );
          })}
        </List>
      </SwipeableDrawer>
    </Root>
  );
}
