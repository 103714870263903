// NPM packages
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey, faUser } from '@fortawesome/free-solid-svg-icons';

// All other imports
import PageContent from 'components/core/PageContent';
import FormCard from 'components/core/FormCard';
import UpdateProfileForm from 'components/auth/UpdateProfileForm';
import { useNavigate } from 'react-router';
import { useServices } from 'services';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';

const Root = styled(PageContent)({});

const LowerFormCard = styled(FormCard)({
  marginTop: '1.5rem',
});

export interface ProfileProps {
  className?: string;
}

function Profile(props: ProfileProps): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();
  const navigate = useNavigate();
  const email = authService.user?.email || '';
  const isLoggedIn = authService.isLoggedIn;

  const [resetPasswordText, setResetPasswordText] = useState(
    'Send Password Reset'
  );
  const [disableResetPassword, setDisableResetPassword] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate('/sign-up', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <Root className={className}>
      <FormCard
        title="Update Information"
        titleIcon={<FontAwesomeIcon icon={faUser} />}
      >
        <UpdateProfileForm />
      </FormCard>
      <LowerFormCard
        title="Update Password"
        titleIcon={<FontAwesomeIcon icon={faKey} />}
      >
        <Button
          onClick={() => {
            authService.forgotPassword({ email });
            setResetPasswordText('Password Reset Sent!');
            setDisableResetPassword(true);
          }}
          disabled={disableResetPassword}
        >
          {resetPasswordText}
        </Button>
      </LowerFormCard>
    </Root>
  );
}

export default observer(Profile);
