// NPM packages
import React from 'react';

// All other imports
import { FormState } from 'hooks/useForm';
import ProjectFormData from 'types/ProjectFormData';
import TextField from 'components/core/TextField';
import FieldSet from 'components/core/FieldSet';
import { Typography } from '@mui/material';

export interface ContactFieldProps {
  className?: string;
  formState: FormState<ProjectFormData>;
}

export default function ContactField(
  props: ContactFieldProps
): React.ReactElement | null {
  const { formState } = props;
  return (
    <FieldSet>
      <Typography variant="h3" component="legend">
        Contact
      </Typography>
      <TextField formState={formState} fieldName="contact.firstName" />
      <TextField formState={formState} fieldName="contact.lastName" />
      <TextField formState={formState} fieldName="contact.email" />
      <TextField formState={formState} fieldName="contact.affiliation" />
    </FieldSet>
  );
}
