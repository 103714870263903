// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icons } from './utils';
import { Tooltip } from '@mui/material';

const Root = styled.div({
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'center',
});

const Icon = styled(FontAwesomeIcon)(({ theme: { palette } }) => ({
  color: palette.primary.dark,
  marginLeft: '8px',
}));

const StyledDescription = styled.div({
  fontSize: '12px',
  padding: '2px',
});

export interface DownloadsPanelLabelProps {
  text: string;
  description: string;
  className?: string;
}

function DownloadsPanelLabel(
  props: DownloadsPanelLabelProps
): React.ReactElement | null {
  const { text, description, className } = props;
  return (
    <Root className={className}>
      {text}
      <Tooltip
        title={<StyledDescription>{description}</StyledDescription>}
        arrow
      >
        <Icon icon={icons.faCircleQuestion} />
      </Tooltip>
    </Root>
  );
}

export default observer(DownloadsPanelLabel);
