import { useEffect } from 'react';
import { useServices } from 'services';
import TextFieldAutocomplete, {
  TextFieldAutocompleteProps,
} from './TextFieldAutocomplete';
import { observer } from 'mobx-react-lite';

interface AffiliationAutocompleteProps<FormData extends object> {
  formState: TextFieldAutocompleteProps<FormData>['formState'];
  fieldName: TextFieldAutocompleteProps<FormData>['fieldName'];
  textFieldProps?: TextFieldAutocompleteProps<FormData>['textFieldProps'];
  controllerProps?: TextFieldAutocompleteProps<FormData>['controllerProps'];
  children?: TextFieldAutocompleteProps<FormData>['children'];
}

function AffiliationAutocomplete<FormData extends object>(
  props: AffiliationAutocompleteProps<FormData>
): React.ReactElement {
  const { projectService } = useServices();
  const optionsLoading = projectService.affiliations === undefined;

  useEffect(() => {
    // Effectively only searches once, on mount
    projectService.searchAffiliations('');
  }, [projectService]);

  return (
    <TextFieldAutocomplete
      {...props}
      autocompleteOptions={projectService.affiliations}
      searchForOptions={projectService.searchAffiliations}
      optionsLoading={optionsLoading}
    />
  );
}

export default observer(AffiliationAutocomplete);
