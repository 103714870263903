// NPM packages
import React, { CSSProperties, useState } from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import SimpleMdeReact from 'react-simplemde-editor';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

const Root = styled.div({
  position: 'relative',
  '&&&&&&&&& .editor-toolbar': {
    button: {
      position: 'relative',
      zIndex: 2,
    },
  },
});

const Title = styled.div({
  position: 'absolute',
  top: '-7px',
  zIndex: 1,
  display: 'flex',
  flexWrap: 'wrap',
  fontFamily: "'Roboto', sans-serif",
  color: 'gray',
  gap: '2rem',
  marginLeft: '10px',
  fontSize: '0.85rem',
  backgroundColor: 'white',
  padding: '0px 6px',
});

const Icon = styled(FontAwesomeIcon)({
  marginRight: '0.5rem',
});

// Emulates MuiFormHelperText-root
const commonNoteStyling = {
  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
  fontWeight: 400,
  fontSize: '0.75rem',
  letterSpacing: '0.03333em',
  margin: '9px 14px 0 14px',
};

const LinkNote = styled.div({
  ...commonNoteStyling,
  color: 'orange',
  fontWeight: 'bold',
});

const Helper = styled.div({
  ...commonNoteStyling,
  color: 'rgba(0,0,0,0.6)',
});

const defaultToolbar: EasyMDE.Options['toolbar'] = [
  'bold',
  'italic',
  '|',
  'heading',
  'heading-bigger',
  'heading-smaller',
  '|',
  'ordered-list',
  'unordered-list',
  '|',
  'image',
  '|',
  'guide',
];

const defaultOptions = {
  spellChecker: false,
  status: false,
  toolbar: defaultToolbar,
  maxHeight: '384px',
};

export interface MarkdownFieldProps {
  fieldName: string;
  value: string;
  onChange: (value: string, changeObject?: CodeMirror.EditorChange) => void;
  helperText?: string;
  options?: EasyMDE.Options;
  style?: CSSProperties;
  label?: string;
  className?: string;
}

const linkRegex = new RegExp(/[^!]\[.{0,}\]\(.{0,}\)/i);
const linkingText = `Consider moving any links under "Resources" below`;

function MarkdownField(props: MarkdownFieldProps): React.ReactElement | null {
  const {
    fieldName,
    value,
    onChange,
    helperText,
    options = defaultOptions,
    style,
    label,
    className,
  } = props;

  const [showLinkingText, setShowLinkingText] = useState(linkRegex.test(value));

  const markdownOnChange = (value: string): void => {
    setShowLinkingText(linkRegex.test(value));
    onChange(value);
  };

  return (
    <Root style={style} className={className}>
      <Title>{fieldName}</Title>
      <SimpleMdeReact
        value={value}
        onChange={markdownOnChange}
        options={options}
        aria-label={label}
      />
      {showLinkingText && (
        <LinkNote>
          <Icon icon={faCircleInfo} />
          {linkingText}
        </LinkNote>
      )}
      {helperText && <Helper>{helperText}</Helper>}
    </Root>
  );
}

export default observer(MarkdownField);
