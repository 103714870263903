import styled from '@emotion/styled';
import { filesize } from 'filesize';
import { useEffect, useState } from 'react';
import { TooltipProps } from 'recharts';
import {
  NameType,
  Payload,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import { useServices } from 'services';
import Project from 'types/Project';

export const MSDTooltipMain = styled.div<{}>`
  background-color: white;
  border: 2px solid #e0e1e2;
  color: black;
  padding: 0 5px;
  font-size: 14px;
`;

export const MSDTooltipEntry = styled.div<{ $colorHex: string }>`
  color: ${(props) => props.$colorHex};
`;

export interface MSDTooltipProps extends TooltipProps<ValueType, NameType> {
  varName: string;
  varFormatter: boolean;
  esPluralize: boolean;
  noOpeningName?: boolean;
}

export const MSDTooltip = (props: MSDTooltipProps): React.ReactElement => {
  const { payload, varName, varFormatter, esPluralize, noOpeningName } = props;
  const pluralizedVarName = varName + (esPluralize ? 'es' : 's');

  const { projectService } = useServices();
  const [projectNames, setProjectNames] = useState<Record<string, string>>({});

  useEffect(() => {
    projectService.search();
  }, [projectService]);

  const projects = projectService.projects;
  const projectKeys = Object.keys(projects);
  const projectsExist = projectKeys.length > 0;

  const projectNameKeys = Object.keys(projectNames);
  const projectNamesExist = projectNameKeys.length > 0;

  useEffect(() => {
    if (projectsExist && !projectNamesExist) {
      const newProjectNames: Record<string, string> = {};
      projectKeys.forEach((key: string) => {
        const project = projects[key];
        if (project instanceof Project) {
          newProjectNames[key] = project.title;
        } else {
          // Do not do any translation
          newProjectNames[key] = key;
        }
      });
      setProjectNames(newProjectNames);
    }
  }, [projectsExist, projectNamesExist, projectKeys, projects]);

  const getDisplayText = (datapoint: Payload<ValueType, NameType>): string => {
    const datapointName =
      datapoint.name?.toString().replace('msdlive-project-', '') || '';
    const nameInProjectMap = projectNameKeys.includes(datapointName);
    const displayName = nameInProjectMap
      ? projectNames[datapointName]
      : datapointName;
    const displayValue = datapoint.value;

    const formattedVal = varFormatter
      ? filesize((displayValue as number) || 0, { standard: 'iec' }).replace(
          /[a-z]/g,
          ''
        ) // Do not want "GiB", replace with "GB"
      : displayValue +
        ' ' +
        ([-1, 1].includes(displayValue as number)
          ? varName
          : pluralizedVarName);

    if (noOpeningName) {
      return formattedVal;
    } else {
      return displayName + ': ' + formattedVal;
    }
  };

  payload?.sort((a, b) => {
    return (b.value as number) - (a.value as number);
  });

  const date = payload?.[0]?.payload.day;

  return (
    <MSDTooltipMain>
      <div>{date}</div>
      {payload?.map((datapoint, idx) => {
        return (
          <MSDTooltipEntry $colorHex={'#000000'} key={`tooltipEntry${idx}`}>
            {getDisplayText(datapoint)}
          </MSDTooltipEntry>
        );
      })}
    </MSDTooltipMain>
  );
};
