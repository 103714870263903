// NPM packages
import React from 'react';
import { Alert, AlertTitle, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

// All other imports
import Project from 'types/Project';
import { AuthService, useServices } from 'services';
import GroupsCell from 'components/project/GroupsCell';
import MembersTableMode from 'types/MembersTableMode';
import ActionsCell from './ActionsCell';
import CircularProgress from 'components/core/CircularProgress';

export interface MembersTableProps {
  className?: string;
  project: Project;
  mode: MembersTableMode;
}

const getColumns = ({
  project,
  authService,
  mode,
}: {
  project: Project;
  authService: AuthService;
  mode: MembersTableMode;
}): GridColDef[] => {
  const isAdminView = Boolean(authService.user?.isAdmin(project.slug));
  const isMemberView = Boolean(authService.user?.isMember(project.slug));
  const columns: GridColDef[] = [
    { field: 'lastName', headerName: 'Last Name', flex: 0.75 },
    { field: 'firstName', headerName: 'First Name', flex: 0.5 },
    {
      field: 'affiliation',
      headerName: 'Affiliation',
      flex: 0.75,
      valueGetter: (params) => params.row.affiliation ?? 'N/A',
    },
  ];
  if (isMemberView) {
    if (mode === MembersTableMode.CURRENT_MEMBERS) {
      columns.push({
        field: 'groups',
        headerName: 'Special Roles',
        flex: 1.1,
        renderCell: (params) => (
          <GroupsCell
            params={params}
            project={project}
            isAdminView={isAdminView}
            mode={mode}
          />
        ),
      });
    }

    columns.push({
      field: 'email',
      headerName: 'Email',
      flex: 1,
    });
  }
  if (isAdminView) {
    columns.push({
      field: 'actions',
      headerName: 'Actions',
      flex: 0.3,
      renderCell: (params) => (
        <ActionsCell params={params} mode={mode} project={project} />
      ),
    });
  }
  return columns;
};

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

function MembersTable(props: MembersTableProps): React.ReactElement | null {
  const { className, project, mode } = props;
  const { authService } = useServices();
  const columns = getColumns({ project, authService, mode });
  const members = {
    [MembersTableMode.CURRENT_MEMBERS]: project.members,
    [MembersTableMode.PENDING_MEMBERS]: project.pendingMembers,
  }[mode];

  let body: React.ReactNode = null;
  if (!authService.user) {
    body = (
      <Alert severity="info">
        <AlertTitle>You must be logged in to view members</AlertTitle>
      </Alert>
    );
  } else if (
    mode === MembersTableMode.PENDING_MEMBERS &&
    !authService.user.isAdmin(project.slug)
  ) {
    body = (
      <Alert severity="info">
        <AlertTitle>You must be an admin to view pending members</AlertTitle>
      </Alert>
    );
  } else if (members === undefined) {
    body = <CircularProgress />;
  } else if (members === null || members.length === 0) {
    body = (
      <Typography>
        No {mode === MembersTableMode.PENDING_MEMBERS && 'Pending '}Members
      </Typography>
    );
  } else {
    body = (
      <DataGrid
        disableRowSelectionOnClick
        rows={members}
        columns={columns}
        getRowId={(member) => member.email}
        getRowHeight={(params) => {
          let height = 56;
          if (params.id === authService.user?.email) {
            height = 72;
          }
          return height;
        }}
        autoHeight
        aria-label={
          mode === MembersTableMode.PENDING_MEMBERS
            ? 'Pending Members'
            : 'Members'
        }
      />
    );
  }
  return <Root className={className}>{body}</Root>;
}

export default observer(MembersTable);
