import React, { useState, useEffect, useRef } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';
import { useLocation } from 'react-router-dom';
import {
  AlertStoryblok,
  CollapsibleSectionStoryblok,
} from 'types/storyblokTypes';
import renderRichText from 'components/pages/storyblok/renderRichText';
import Alert from 'components/pages/storyblok/Alert';
import { storyblokEditable } from '@storyblok/react';

const Title = styled(Typography)(({ theme }) => ({
  fontSize: '2.25rem !important',
  '&&&': {
    marginTop: '0px !important',
    marginBottom: '0px !important',
  },
})) as typeof Typography;

interface CollapsibleSectionProps {
  title: string;
  children: React.ReactNode;
  blok?: CollapsibleSectionStoryblok;
  id: string;
}

const CollapsibleSection: React.FC<CollapsibleSectionProps> = ({
  title,
  children,
  blok,
  id,
}): React.ReactElement | null => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const location = useLocation();

  const handleChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean
  ): void => {
    setExpanded(isExpanded);
  };

  useEffect(() => {
    const ids = [id, blok?.id];
    if (ids.includes(location.hash.substring(1))) {
      setExpanded(true);
      // have to give the page a chance to redraw with the section expanded in order for the
      // browser to know where the top of the ref is
      setTimeout(
        () =>
          wrapperRef.current?.scrollIntoView({
            block: 'start',
            behavior: 'smooth',
          }),
        300
      );
    }
  }, [location, id, blok?.id]);

  const modifiedBlokTitle = blok?.title?.replaceAll(' ', '-').toLowerCase();
  const content: (React.ReactElement | null)[] =
    blok?.body?.map((section, index) => {
      const component = section.component;
      const key = `collapsible-info-${modifiedBlokTitle}-${index}`;
      switch (component) {
        case 'alert':
          return (
            <AccordionDetails key={key}>
              <Alert blok={section as AlertStoryblok} />
            </AccordionDetails>
          );
        case 'richText':
          return (
            <AccordionDetails key={key}>
              {section.content &&
                renderRichText(
                  section.content,
                  undefined,
                  undefined,
                  'richText'
                )}
            </AccordionDetails>
          );
        default:
          return null;
      }
    }) || [];

  const dynamicStoryblokEditable = blok ? storyblokEditable(blok) : {};

  return (
    <div className="root" ref={wrapperRef} {...dynamicStoryblokEditable}>
      <Accordion expanded={expanded} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<FontAwesomeIcon icon={faCaretDown} />}
          aria-controls={`${id}-content`}
          id={`${id}-header`}
        >
          <Title variant="h3" component="h2">
            {blok?.title || title}
          </Title>
        </AccordionSummary>
        {content.length > 0 ? (
          content
        ) : (
          <AccordionDetails>{children}</AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

export default CollapsibleSection;
