// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

// All other imports
import PageContent from 'components/core/PageContent';
import H1 from 'components/core/H1';

const Root = styled(PageContent)({});

export interface ComingSoonProps {
  className?: string;
}

export default function ComingSoon(
  props: ComingSoonProps
): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <H1>Coming Soon!</H1>
      <Typography>This page is under construction.</Typography>
    </Root>
  );
}
