// NPM packages
import React, { useEffect } from 'react';
import { Dialog, DialogContent, Button } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { useServices } from 'services';
import { useLocation } from 'react-router-dom';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});
const ButtonBar = styled.div({
  marginTop: '1rem',
  '&& button': {
    marginRight: '1rem',
  },
});

export interface ConfirmationModalProps {
  className?: string;
}

function ConfirmationModal(
  props: ConfirmationModalProps
): React.ReactElement | null {
  const { className } = props;
  const { confirmationService } = useServices();
  const location = useLocation();
  useEffect(() => {
    confirmationService.close();
  }, [location.pathname, confirmationService]);
  return (
    <Root className={className}>
      <Dialog open={confirmationService.isOpen} onClose={() => {}}>
        {confirmationService.title}
        <DialogContent>
          {confirmationService.prompt}
          <ButtonBar>
            <Button variant="outlined" onClick={confirmationService.sayNo}>
              {confirmationService.noText}
            </Button>
            <Button variant="contained" onClick={confirmationService.sayYes}>
              {confirmationService.yesText}
            </Button>
          </ButtonBar>
        </DialogContent>
      </Dialog>
    </Root>
  );
}

export default observer(ConfirmationModal);
