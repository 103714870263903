// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { Typography } from '@mui/material';

import { firstSentence } from 'components/core/FundingStatement';

// All other imports

const Root = styled.div(({ theme: { palette } }) => ({
  backgroundColor: palette.primary.dark,
  color: palette.common.white,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '1rem 5.4375rem .75rem 5.4375rem',
  position: 'relative',
}));

const Filler = styled.div({
  width: '33%',
});

const StatementText = styled.div(({ theme: { typography, palette } }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  fontWeight: typography.fontWeightMedium,
  fontSize: '1rem',
  textAlign: 'center',
  '& a': {
    color: palette.grey['400'],
    '&:hover': {
      color: 'white',
    },
  },
}));

const Footnote = styled(Typography)(
  ({ theme: { palette, typography, breakpoints } }) => ({
    fontSize: '1rem',
  })
);

const LogoHolder = styled.div({
  width: '33%',
  display: 'flex',
  justifyContent: 'flex-end',
});

export interface FooterProps {
  className?: string;
}

export default function Footer(props: FooterProps): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <Filler />
      <StatementText>
        <Footnote>{firstSentence}</Footnote>
      </StatementText>
      <LogoHolder></LogoHolder>
    </Root>
  );
}
