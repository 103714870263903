// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

// All other imports
import Link from 'components/core/Link';

const Root = styled.div({
  display: 'flex',
  alignItems: 'center',
});
const iconClassName = 'call-to-action-icon';
const IconWrapper = styled.div(({ theme: { breakpoints, palette } }) => ({
  [`.${iconClassName}`]: {
    marginRight: '25px',
    width: '65px',
    color: palette.primary.light,
  },
  [breakpoints.up('xl')]: {
    [`.${iconClassName}`]: {
      marginRight: '30px',
      width: '91px',
    },
  },
}));
const TextWrapper = styled.div(({ theme: { typography } }) => ({
  maxWidth: typography.pxToRem(550),
}));

export interface CallToActionProps {
  className?: string;
  title: string;
  description: string;
  renderIcon: (renderProps: { className: string }) => React.ReactNode;
  href: string;
}

export default function CallToAction(
  props: CallToActionProps
): React.ReactElement | null {
  const { className, title, href, description, renderIcon } = props;
  return (
    <Root className={className}>
      <Link to={href}>
        <IconWrapper>{renderIcon({ className: iconClassName })}</IconWrapper>
      </Link>
      <TextWrapper>
        <Typography variant="h2">
          <Link to={href}>{title}</Link>
        </Typography>
        <Typography>{description}</Typography>
      </TextWrapper>
    </Root>
  );
}
