import { useContext } from 'react';

import ServicesContext from './ServicesContext';
import Services from './Services';

const useServices = (): Services => {
  const services = useContext(ServicesContext);
  if (services === null) {
    throw new Error('Attempted to use services without provider.');
  }
  return services;
};
export default useServices;
