import axios, { AxiosInstance } from 'axios';
import CookieService from './CookieService';
import EnvService from './EnvService';

export default class RdmApiService {
  envService: EnvService;
  cookieService: CookieService;
  axios: AxiosInstance;

  constructor({
    envService,
    cookieService,
  }: {
    envService: EnvService;
    cookieService: CookieService;
  }) {
    this.envService = envService;
    this.cookieService = cookieService;
    this.axios = axios.create({
      baseURL: `${envService.getValue('DATA_REPO_URL')}/api`,
      withCredentials: true,
      xsrfHeaderName: 'x-csrftoken',
      xsrfCookieName: 'csrftoken',
    });
    this.axios.interceptors.response.use(
      function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        return response;
      },
      function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        if (error.response?.data?.message) {
          error.message = error.response.data.message;
        }
        return Promise.reject(error);
      }
    );
  }
}
