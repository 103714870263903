import { runInAction, makeObservable, observable, action, when } from 'mobx';
import React from 'react';

import ConfirmationError from 'types/errors/ConfirmationError';

enum ConfirmationResponse {
  YES = 'yes',
  NO = 'no',
}

export default class ConfirmationService {
  isOpen: boolean = false;
  prompt: React.ReactNode | null = null;
  title: React.ReactNode | null = null;
  yesText: string | null = null;
  noText: string | null = null;
  currentResponse: ConfirmationResponse | null = null;

  constructor() {
    makeObservable(this, {
      isOpen: observable,
      prompt: observable.ref,
      title: observable.ref,
      yesText: observable,
      noText: observable,
      currentResponse: observable,
      sayNo: action,
      sayYes: action,
      close: action,
    });
  }

  sayNo = (): void => {
    this.currentResponse = ConfirmationResponse.NO;
  };

  sayYes = (): void => {
    this.currentResponse = ConfirmationResponse.YES;
  };

  close = (): void => {
    this.isOpen = false;
  };

  promptUser = async (args: {
    prompt: React.ReactNode;
    title?: React.ReactNode | null;
    yesText?: string;
    noText?: string;
    /**
     * Set this to false if you redirect on answer. Otherwise
     * the modal will close and there will be an awkward second
     * where the modal is closed but the redirect hasn't happened.
     */
    closeOnAnswer?: boolean;
  }): Promise<void> => {
    const {
      prompt = 'Are you sure?',
      yesText = 'Yes',
      noText = 'No',
      title = null,
      closeOnAnswer = true,
    } = args;
    runInAction(() => {
      this.isOpen = true;
      this.prompt = prompt;
      this.title = title;
      this.yesText = yesText;
      this.noText = noText;
      this.currentResponse = null;
    });
    await when(() => this.currentResponse !== null);
    if (closeOnAnswer) {
      runInAction(() => {
        this.isOpen = false;
      });
    }
    switch (this.currentResponse) {
      case ConfirmationResponse.YES:
        return;
      case ConfirmationResponse.NO:
        throw new ConfirmationError();
      default:
        throw new Error('Invalid response');
    }
  };
}
