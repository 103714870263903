// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

// All other imports
import TextField from 'components/core/TextField';
import { useServices } from 'services';
import Link from 'components/core/Link';
import Form from 'components/core/Form';

export interface ForgotPasswordFormProps {
  className?: string;
}

interface FormData {
  email: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object({
  email: yup.string().required().label('Email'),
});

const defaultValues: FormData = {
  email: '',
};

function ForgotPasswordForm(
  props: ForgotPasswordFormProps
): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();

  return (
    <Form<FormData>
      className={className}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={(data) => authService.forgotPassword(data)}
      getErrorMessage={(rejectedVal) => {
        if (rejectedVal.code === 'UserNotFoundException') {
          return (
            <Typography>
              We can't find an account with that email.{' '}
              <Link to="/sign-up">Sign up</Link> to access MSD-LIVE.
            </Typography>
          );
        } else if (rejectedVal.code === 'LimitExceededException') {
          return (
            <Typography>
              You have attempted to reset your password too many times. Please
              wait 5 minutes and try again.
            </Typography>
          );
        } else if (rejectedVal.code === 'InvalidParameterException') {
          const sanitizedMsg = rejectedVal.message.replace(
            ' or phone_number',
            ''
          );
          return (
            <Typography>
              {sanitizedMsg}. You may need to{' '}
              <Link to={`/resend-verification`}>verify your email</Link>.
            </Typography>
          );
        }
        return 'Could not send password reset email';
      }}
      getSuccessMessage={() => 'Sent password reset email'}
      submitText="Send Password Reset"
    >
      {(formState) => (
        <>
          <TextField formState={formState} fieldName="email" />
        </>
      )}
    </Form>
  );
}

export default observer(ForgotPasswordForm);
