// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Link from 'components/core/Link';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

export interface SignUpErrorProps {
  className?: string;
  error: Error;
}

function SignUpError(props: SignUpErrorProps): React.ReactElement | null {
  const { className, error } = props;
  let body: React.ReactNode;
  if (error.name === 'UsernameExistsException') {
    body = (
      <>
        <Typography>
          There is already an account with that email address.
        </Typography>
        <Typography>
          Having trouble logging in? Try{' '}
          <Link to={`/resend-verification`}>
            resending the verification email
          </Link>
          .
        </Typography>
      </>
    );
  } else {
    body = <Typography>{error.message ?? 'Failed to sign up'}</Typography>;
  }
  return <Root className={className}>{body}</Root>;
}

export default observer(SignUpError);
