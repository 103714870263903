// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// All other imports
import PageContent from 'components/core/PageContent';
import FormCard from 'components/core/FormCard';
import ForgotPasswordForm from 'components/auth/ForgotPasswordForm';

const Root = styled(PageContent)({});

export interface ForgotPasswordProps {
  className?: string;
}

export default function ForgotPassword(
  props: ForgotPasswordProps
): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <FormCard
        title="Forgot Password"
        titleIcon={<FontAwesomeIcon icon={faUser} />}
      >
        <ForgotPasswordForm />
      </FormCard>
    </Root>
  );
}
