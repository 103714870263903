// NPM packages
import React from 'react';
import styled from '@emotion/styled';

// All other imports
import NavItem from 'types/NavItem';
import { NavLink, useLocation } from 'react-router-dom';

const Root = styled.ul({
  padding: 0,
  listStyle: 'none',
  display: 'flex',
  margin: '0 1rem',
});
const ListItem = styled.li({
  margin: '0 0.5rem',
});
const Link = styled(NavLink)(({ theme: { palette, typography } }) => ({
  fontSize: typography.pxToRem(18),
  fontWeight: typography.fontWeightMedium,
  textDecoration: 'none',
  color: 'inherit',
  padding: '0.2rem 0.5rem',
  '&.active': {
    borderBottom: '1px solid black',
  },
}));

export interface DesktopMenuProps {
  className?: string;
  items: NavItem[];
}

export default function DesktopMenu(
  props: DesktopMenuProps
): React.ReactElement | null {
  const { className, items } = props;
  return (
    <Root className={className}>
      {items.map((item) => {
        return (
          <ListItem key={item.href}>
            <Link to={item.href}>{item.text}</Link>
          </ListItem>
        );
      })}
    </Root>
  );
}
