import React from 'react';
import NotFoundError from 'types/errors/NotFoundError';
import NotFoundContent from 'components/core/NotFoundContent';
import HorizontallyCentered from 'components/core/HorizontallyCentered';
import CircularProgress from 'components/core/CircularProgress';

export default function useResourceGuard<Resource>({
  resource,
}: {
  // Possibly loading or not found resource
  resource: NotFoundError | null | Resource;
}): React.ReactElement | null {
  if (resource === null) {
    return (
      <HorizontallyCentered>
        <CircularProgress />
      </HorizontallyCentered>
    );
  }
  if (resource instanceof NotFoundError) {
    return <NotFoundContent />;
  }
  return null;
}
