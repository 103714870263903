// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { useServices } from 'services';
import CircularProgress from 'components/core/CircularProgress';

const SpinnerWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100vh',
});

export interface AuthGuardProps {
  children: React.ReactNode;
}

/**
 * Only renders children once authService has initialized
 */
function AuthGuard(props: AuthGuardProps): React.ReactElement | null {
  const { children } = props;
  const { authService } = useServices();

  if (authService.initialized) {
    return <>{children}</>;
  }
  return (
    <SpinnerWrapper>
      <CircularProgress aria-hidden />
      <Typography role="alert">Loading MSD-LIVE</Typography>
    </SpinnerWrapper>
  );
}

export default observer(AuthGuard);
