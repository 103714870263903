// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Link from 'components/core/Link';
import LimitedResourceCategoryThumbnail from './LimitedResourceCategoryThumbnail';
import { LIMITED_RESOURCE_CATEGORY_MAX_HEIGHT as MAX_HEIGHT } from './utils';
import { ResourceCategoryStoryblok } from 'types/storyblokTypes';
import TextTruncate from 'react-text-truncate';
import FullWidthTypography from 'components/core/FullWidthTypography';

const Root = styled.div<{ flipped: boolean | undefined; numImages: number }>(
  ({ theme, flipped, numImages }) => {
    return `
      min-width: ${theme.maxToolWidth};
      height: ${MAX_HEIGHT}px;
      display: grid;
      justify-content: center;
      align-items: center;
      grid-template-columns: ${
        numImages > 0
          ? flipped
            ? '45rem 32.49rem'
            : '32.49rem 45rem'
          : '45rem'
      };
      margin: 2rem 0;

      @media (max-width: 1200px) {
        grid-template-columns: ${
          numImages > 0
            ? flipped
              ? '38.49rem 32.49rem'
              : '32.49rem 38.49rem'
            : '45rem'
        }
      }
    `;
  }
);

const Header = styled(FullWidthTypography)({
  marginTop: '0',
  marginBottom: '0.5rem',
  display: 'flex',
  gap: '1rem',
});

const Description = styled.div({
  marginBottom: '0.5rem',
});

interface LimitedResourceCategoryProps {
  blok: ResourceCategoryStoryblok;
  flipped?: boolean;
  className?: string;
}

function LimitedResourceCategory(
  props: LimitedResourceCategoryProps
): React.ReactElement | null {
  const { blok, flipped, className } = props;
  const linksToExternalSite = blok.linkedUrl?.includes('http');

  const thumbnail = (
    <LimitedResourceCategoryThumbnail
      images={blok.images || []}
      flipped={flipped || false}
    />
  );

  return (
    <Root
      flipped={flipped}
      numImages={thumbnail.props.images.length}
      className={className}
    >
      {flipped ? null : thumbnail}
      <div>
        <Header variant="h2">{blok.title}</Header>
        <Description>
          <TextTruncate
            line={5}
            element={'span'}
            truncateText="..."
            text={blok.description}
          />
        </Description>
        {blok.linkedUrl && (
          <Link to={blok.linkedUrl} openNewTab={linksToExternalSite}>
            Learn More...
          </Link>
        )}
      </div>
      {flipped ? thumbnail : null}
    </Root>
  );
}

export default observer(LimitedResourceCategory);
