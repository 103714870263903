// NPM packages
import React, { useEffect, useRef } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';

// All other imports

const StyledH1 = styled(Typography)({
  outline: 'none',
});

export interface H1Props extends Omit<TypographyProps, 'tabIndex'> {}

export default function H1(props: H1Props): React.ReactElement | null {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    window.scrollTo(0, 0);
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);
  return (
    <StyledH1
      ref={ref}
      variant="h1"
      component="h1"
      tabIndex={-1}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(props as any)}
    />
  );
}
