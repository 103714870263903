// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

// All other imports

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});
const Icon = styled(FontAwesomeIcon)({
  marginRight: '.375rem',
});

export interface UserNavItemProps {
  className?: string;
  label: string;
  icon: FontAwesomeIconProps['icon'];
}

export default function UserNavItem(
  props: UserNavItemProps
): React.ReactElement | null {
  const { className, icon, label } = props;
  return (
    <Root className={className}>
      <Icon icon={icon} /> {label}
    </Root>
  );
}
