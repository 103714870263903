import React, { useEffect } from 'react';
import { Divider } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

import useServices from 'services/useServices';
import GradientBanner from '../storyblok/GradientBanner';
import ProjectListing from './ProjectListing';
import Project from 'types/Project';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from 'components/core/CircularProgress';

const Root = styled.div({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const ProjectList = styled.div(({ theme }) => {
  return {
    width: theme.maxToolWidth,
    marginBottom: '1.75rem',
  };
});

const NoProjects = styled.div(({ theme }) => {
  return {
    display: 'flex',
    width: theme.maxToolWidth,
    marginLeft: '10rem',
    marginTop: '1.75rem',
  };
});

export interface ProjectsProps {
  className?: string;
}

function Projects(props: ProjectsProps): React.ReactElement | null {
  const { className } = props;
  const { projectService } = useServices();

  useEffect(() => {
    projectService.search();
  }, [projectService]);

  let body: React.ReactNode = null;
  if (projectService.searchResults) {
    const noProjects = projectService.searchResults?.length === 0;
    if (noProjects) {
      body = (
        <NoProjects>
          No projects found. Please shoot us an email if this doesn't seem
          right.
        </NoProjects>
      );
    } else {
      body = (
        <ProjectList>
          {projectService.searchResults.map((project: Project, idx) => {
            const atOddIndex = idx % 2 === 1;
            return (
              <div>
                <ProjectListing
                  project={project}
                  flipped={atOddIndex}
                  key={`project-${project.slug}`}
                />
                <Divider />
              </div>
            );
          })}
        </ProjectList>
      );
    }
  } else {
    body = <CircularProgress text={'Loading...'} />;
  }

  return (
    <Root className={className}>
      <GradientBanner
        header={'Projects'}
        description={
          'MSD-LIVE hosts a number of projects for the MSD community.'
        }
        image={<FontAwesomeIcon icon={faUsers} size="5x" />}
        imageAlt="Minimalist icon of three people, two behind the first"
      />
      {body}
    </Root>
  );
}

export default observer(Projects);
