// NPM packages
import React from 'react';
import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  RadioGroupProps as MuiRadioGroupProps,
} from '@mui/material';
import { Controller, Path } from 'react-hook-form';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';
import styled from '@emotion/styled';

// All other imports
import { FormState } from 'hooks/useForm';

export interface RadioGroupProps<FormData extends object> {
  className?: string;
  formState: FormState<FormData>;
  fieldName: Path<FormData>;
  muiRadioGroupProps?: MuiRadioGroupProps;
  label?: string;
  children: React.ReactNode;
}

const Root = styled(FormControl)({
  '&&& .MuiFormControlLabel-label': {
    marginBottom: 0,
  },
});

export default function RadioGroup<FormData extends object>(
  props: RadioGroupProps<FormData>
): React.ReactElement | null {
  const {
    className,
    label: labelProp,
    fieldName,
    children,
    muiRadioGroupProps,
    formState,
  } = props;
  const id = kebabCase(fieldName);
  const labelId = `${id}-label`;
  const label = labelProp || startCase(fieldName);
  return (
    <Controller
      control={formState.control}
      name={fieldName}
      render={({ field, fieldState }) => (
        <Root className={className}>
          <FormLabel id={labelId}>{label}</FormLabel>
          <MuiRadioGroup
            {...muiRadioGroupProps}
            {...field}
            id={id}
            aria-labelledby={labelId}
          >
            {children}
          </MuiRadioGroup>
        </Root>
      )}
    />
  );
}
