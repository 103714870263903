// NPM packages
import React from 'react';
import { Paper, TypographyProps } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import H1 from 'components/core/H1';

const Root = styled(Paper)(({ theme: { palette } }) => ({
  border: `1px solid ${palette.grey['200']}`,
}));
const HeaderBar = styled.div(({ theme: { palette } }) => ({
  background: palette.grey['100'],
  padding: '0.75rem',
}));
const Title = styled(H1)(({ theme: { typography } }) => ({
  '&&&': {
    fontSize: '1.5rem',
    fontWeight: typography.fontWeightBold,
    marginBottom: 0,
  },
}));
const Content = styled.div({
  padding: '1rem',
});

export interface FormCardProps {
  className?: string;
  children?: React.ReactNode;
  title: string;
  titleIcon: React.ReactNode;
  titleTypographyProps?: Omit<TypographyProps, 'tabIndex'>;
}

function FormCard(props: FormCardProps): React.ReactElement | null {
  const { className, children, title, titleIcon, titleTypographyProps } = props;

  return (
    <Root className={className}>
      <HeaderBar>
        <Title {...titleTypographyProps}>
          {titleIcon} {title}
        </Title>
      </HeaderBar>
      <Content>{children}</Content>
    </Root>
  );
}

export default observer(FormCard);
