// NPM packages
import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Autocomplete,
  Typography,
  Chip,
} from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Project from 'types/Project';
import User from 'types/User';
import CircularProgress from 'components/core/CircularProgress';
import { useServices } from 'services';
import { isLoaded } from 'utils';

const Root = styled.div({});
const AffiliationChip = styled(Chip)({
  marginLeft: '0.5rem',
});
const ButtonBar = styled.div({
  '& button': {
    marginTop: '1rem',
    marginRight: '0.5rem',
  },
});
const StyledAutocomplete = styled(Autocomplete)({
  marginTop: '0.5rem',
}) as typeof Autocomplete;

const SpinnerWrapper = styled.div({
  padding: '0 0.5rem',
  display: 'inline-flex',
});

export interface AddMemberProps {
  className?: string;
  project: Project;
}

function AddMember(props: AddMemberProps): React.ReactElement | null {
  const { className, project } = props;
  const [showForm, setShowForm] = useState<boolean>(false);
  const [newMember, setNewMember] = useState<User | null | undefined>(null);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { authService } = useServices();
  const options = useMemo(() => {
    const searchResults = authService.searchState.results || [];
    if (newMember && !searchResults.includes(newMember)) {
      return [newMember, ...searchResults];
    }
    return searchResults;
  }, [newMember, authService.searchState.results]);
  useEffect(() => {
    authService.searchUsers('');
  }, [authService]);

  const membersLoading = !isLoaded(authService.searchState.results);
  const closeForm = (): void => setShowForm(false);
  const handleAddMember = async (): Promise<void> => {
    try {
      setSubmitting(true);
      if (!newMember) {
        throw new Error('No member selected');
      }
      await project.approveMember(newMember);
    } finally {
      setSubmitting(false);
      setNewMember(null);
      closeForm();
    }
  };
  return (
    <Root className={className}>
      <Button onClick={() => setShowForm(true)}>Add Member</Button>
      <Dialog
        open={showForm}
        onClose={closeForm}
        fullWidth
        PaperProps={{ style: { overflowY: 'visible' } }}
      >
        <DialogTitle variant="h2">Add Member to {project.title}</DialogTitle>
        <DialogContent>
          <StyledAutocomplete
            disablePortal
            fullWidth
            // We filter on the backend, so we don't need to filter here
            filterOptions={(x) => x}
            id="select-new-user"
            options={options}
            loading={membersLoading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="User's Name or Email"
                size="small"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {membersLoading && (
                        <SpinnerWrapper>
                          <CircularProgress size="1.5rem" />
                        </SpinnerWrapper>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            getOptionLabel={(user: User) => user.email}
            value={newMember}
            onChange={(event, value) => setNewMember(value)}
            onInputChange={(event, newInputValue) => {
              authService.searchUsers(newInputValue);
            }}
            renderOption={(props, user: User) => (
              <li {...props}>
                <div>
                  <Typography variant="h3">
                    {user.firstName} {user.lastName}
                    {user.affiliation && (
                      <AffiliationChip label={user.affiliation} />
                    )}
                  </Typography>
                  <Typography variant="subtitle2" component="span">
                    {user.email}
                  </Typography>
                </div>
              </li>
            )}
          />
          <ButtonBar>
            <Button
              onClick={closeForm}
              variant="outlined"
              disabled={submitting}
            >
              Cancel
            </Button>
            <Button
              onClick={handleAddMember}
              disabled={submitting || !newMember}
            >
              {submitting && (
                <SpinnerWrapper>
                  <CircularProgress insideButton />
                </SpinnerWrapper>
              )}
              Add Member
            </Button>
          </ButtonBar>
        </DialogContent>
      </Dialog>
    </Root>
  );
}

export default observer(AddMember);
