// NPM packages
import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { faCircleCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

// All other imports
import { useServices } from 'services';
import MembersTableMode from 'types/MembersTableMode';
import Project from 'types/Project';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from 'components/core/CircularProgress';

const Root = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
});

const ApproveButton = styled(IconButton)(({ theme: { palette } }) => ({
  color: palette.success.main,
}));
const RemoveButton = styled(IconButton)(({ theme: { palette } }) => ({
  color: palette.error.main,
}));

export interface ActionsCellProps {
  className?: string;
  mode: MembersTableMode;
  project: Project;
  params: GridRenderCellParams;
}

function ActionsCell(props: ActionsCellProps): React.ReactElement | null {
  const { className, mode, params, project } = props;
  const { authService } = useServices();
  const user = params.row;
  const approve = async (): Promise<void> => {
    await project.approveMember(user);
  };
  const remove = async (): Promise<void> => {
    await project.removeMember(user);
  };
  const disableRemove = user.email === authService.user?.email;
  const actions = (
    <>
      {mode === MembersTableMode.PENDING_MEMBERS && (
        <Tooltip title="Approve new member">
          <ApproveButton onClick={approve}>
            <FontAwesomeIcon icon={faCircleCheck} />
          </ApproveButton>
        </Tooltip>
      )}
      <Tooltip
        title={disableRemove ? "Can't remove yourself" : 'Remove member'}
      >
        <div>
          <RemoveButton onClick={remove} disabled={disableRemove}>
            <FontAwesomeIcon icon={faXmark} />
          </RemoveButton>
        </div>
      </Tooltip>
    </>
  );
  return (
    <Root className={className}>
      {user.loading ? <CircularProgress /> : actions}
    </Root>
  );
}

export default observer(ActionsCell);
