// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import Link from './Link';
export interface FundingStatementProps {
  className?: string;
}

export const firstSentence = (
  <>
    MSD-LIVE is being developed with support from the{' '}
    <Link to="https://gcc02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fclimatemodeling.science.energy.gov%2Fprogram%2Fmultisector-dynamics&data=05%7C01%7Cdevin.mcallester%40pnnl.gov%7Cfa5c9a30311a4e7f5ba808da497e7aa2%7Cd6faa5f90ae240338c0130048a38deeb%7C0%7C0%7C637903104822852288%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=c6b46UnV72P8iVtdpChoU%2BRRYnjVo4O9YnXe4vZO0Hs%3D&reserved=0">
      MultiSector Dynamics
    </Link>{' '}
    program area within the{' '}
    <Link to="https://gcc02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fclimatemodeling.science.energy.gov%2F&data=05%7C01%7Cdevin.mcallester%40pnnl.gov%7Cfa5c9a30311a4e7f5ba808da497e7aa2%7Cd6faa5f90ae240338c0130048a38deeb%7C0%7C0%7C637903104822852288%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=TmnDewCAW8poUkCxYXEzwv1BB7lD9uCAtTvg6KtibEk%3D&reserved=0">
      Earth and Environmental Systems Modeling
    </Link>{' '}
    program of the{' '}
    <Link to="https://gcc02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fwww.energy.gov%2Fscience%2Foffice-science&data=05%7C01%7Cdevin.mcallester%40pnnl.gov%7Cfa5c9a30311a4e7f5ba808da497e7aa2%7Cd6faa5f90ae240338c0130048a38deeb%7C0%7C0%7C637903104822852288%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=7kG09%2BSWln9qH5Ee9MH9Aw76y40jthF%2BgPW%2FXyx0860%3D&reserved=0">
      U.S. Department of Energy{'\u2019'}s Office of Science
    </Link>
    .
  </>
);

function FundingStatement(
  props: FundingStatementProps
): React.ReactElement | null {
  const { className } = props;
  return (
    <Typography className={className}>
      {firstSentence} Scoping and brainstorming for MSD-LIVE was also supported
      by the{' '}
      <Link to="https://gcc02.safelinks.protection.outlook.com/?url=https%3A%2F%2Fscience.osti.gov%2Fber%2FResearch%2Feessd%2FData-Management&data=05%7C01%7Cdevin.mcallester%40pnnl.gov%7Cfa5c9a30311a4e7f5ba808da497e7aa2%7Cd6faa5f90ae240338c0130048a38deeb%7C0%7C0%7C637903104822852288%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C3000%7C%7C%7C&sdata=7%2FxMgYP%2FAcQOicmWuHbEcDjuPXqKqX95ytz63zQZFvk%3D&reserved=0">
        Data Management
      </Link>{' '}
      program.
    </Typography>
  );
}

export default observer(FundingStatement);
