// NPM packages
import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';

// All other imports
import { isAbsoluteUrl, isMailto } from 'utils';

export interface ButtonLinkProps
  extends Omit<ReactRouterLinkProps, keyof ButtonProps>,
    Omit<
      ButtonProps,
      keyof Omit<React.HTMLProps<HTMLAnchorElement>, 'color' | 'size'>
    > {
  className?: string;
  children?: React.ReactNode;
}

export default React.forwardRef((props: ButtonLinkProps, ref) => {
  const { to, ...forwardedProps } = props;
  if (typeof to === 'string' && (isAbsoluteUrl(to) || isMailto(to))) {
    return (
      <Button
        ref={ref}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(forwardedProps as any)}
        component="a"
        href={to}
      />
    );
  }
  return (
    <Button
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      {...(forwardedProps as any)}
      component={ReactRouterLink}
      to={to}
    />
  );
});
