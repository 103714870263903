// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { PersonStoryblok, PersonListStoryblok } from 'types/storyblokTypes';
import PersonCard from './PersonCard';
import { storyblokEditable } from '@storyblok/react';

const Root = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
  gridColumnGap: '1rem',
  gridRowGap: '1rem',
  marginTop: '1rem',
});

export interface PersonListProps {
  blok: PersonListStoryblok;
  className?: string;
}

function PersonList(props: PersonListProps): React.ReactElement | null {
  const { blok, className } = props;
  const people = blok.people;

  return (
    <Root className={className} {...storyblokEditable(blok)}>
      {people?.map((person: PersonStoryblok) => {
        const nameKey = person.name.replaceAll(' ', '');
        return <PersonCard teammate={person} key={`teammate-${nameKey}`} />;
      })}
    </Root>
  );
}

export default observer(PersonList);
