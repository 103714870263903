import * as React from 'react';
import { default as MuiSwipeableDrawer } from '@mui/material/SwipeableDrawer';

export interface SwipeableDrawerProps {
  children: React.ReactNode;
  open: boolean;
  anchor?: 'bottom' | 'left' | 'right' | 'top';
  onOpen: () => void;
  onClose: () => void;
}

export default function SwipeableDrawer({
  children,
  anchor = 'right',
  open,
  onOpen,
  onClose,
}: SwipeableDrawerProps): React.ReactElement | null {
  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);
  return (
    <MuiSwipeableDrawer
      anchor={anchor}
      open={open}
      onClose={onOpen}
      onOpen={onClose}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      {children}
    </MuiSwipeableDrawer>
  );
}
