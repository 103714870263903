// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { Variant } from '@mui/material/styles/createTypography';

const Root = styled(Typography)(({ theme }) => {
  return {
    width: theme.maxTextWidth,
  };
});

export interface FullWidthTypographyProps {
  variant: string;
  children: React.ReactNode;
  className?: string;
}

function FullWidthTypography(
  props: FullWidthTypographyProps
): React.ReactElement | null {
  const { variant, children, className } = props;
  return (
    <Root variant={(variant as Variant) || undefined} className={className}>
      {children}
    </Root>
  );
}

export default observer(FullWidthTypography);
