// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

// All other imports
import { PageStoryblok } from 'types/storyblokTypes';
import MarkdownText from 'components/core/MarkdownText';
import GradientBanner from './GradientBanner';
import { displayBreadcrumbs, useBreadcrumbs } from './utils';
import FullWidthTypography from 'components/core/FullWidthTypography';
import { useTheme } from '@emotion/react';
import PageContent from 'components/core/PageContent';

const Root = styled.div(() => {
  return `
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  `;
});

const Description = styled.div({
  width: '100%',
  display: 'flex',
  justifyContent: 'left',
});

export interface PageProps {
  blok: PageStoryblok;
  className?: string;
}

function Page(props: PageProps): React.ReactElement | null {
  const { blok, className } = props;
  const useGradientBanner = blok.useGradientBanner;
  const breadcrumbs = useBreadcrumbs();
  const onPageNotFound = blok.header === 'Page Not Found';
  const theme = useTheme();
  return useGradientBanner ? (
    <Root className={className} {...storyblokEditable(blok)}>
      <GradientBanner
        header={blok.header}
        description={blok.description || ''}
        image={blok.svgIcon?.filename}
        imageAlt={blok.svgIconAlt}
        breadcrumbs={breadcrumbs}
      />
      {blok.body?.map((bodyBlok, idx) => {
        return <StoryblokComponent blok={bodyBlok} key={`bodyBlok-${idx}`} />;
      })}
    </Root>
  ) : (
    <PageContent className={className} maxWidth={theme.maxTextWidth}>
      {!onPageNotFound &&
        breadcrumbs &&
        displayBreadcrumbs(breadcrumbs, '#000000')}
      <FullWidthTypography variant="h1">{blok.header}</FullWidthTypography>
      <Description>
        <MarkdownText markdown={blok.description || ''} />
      </Description>
      {blok.body?.map((bodyBlok, idx) => {
        return <StoryblokComponent blok={bodyBlok} key={`bodyBlok-${idx}`} />;
      })}
    </PageContent>
  );
}

export default observer(Page);
