// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import Organization from 'types/Organization';

// All other imports
import Link from 'components/core/Link';

const Root = styled.div({
  display: 'inline',
});

export interface OrgDisplayTextProps {
  className?: string;
  org: Organization;
}

export default function OrgDisplayText(
  props: OrgDisplayTextProps
): React.ReactElement | null {
  const { className, org } = props;
  return (
    <Root className={className}>
      {org.website ? <Link to={org.website}>{org.name}</Link> : org.name}
    </Root>
  );
}
