// NPM packages
import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Avatar as MuiAvatar,
} from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { PersonStoryblok } from 'types/storyblokTypes';
import OrgDisplayText from 'components/about/OrgDisplayText';
import { storyblokEditable } from '@storyblok/react';

const Root = styled(Card)({});

const Content = styled(CardContent)({
  display: 'flex',
  justifyContent: 'flex-start',
});

const Avatar = styled(MuiAvatar)({
  marginRight: '0.5rem',
});

const Name = styled(Typography)(({ theme: { typography } }) => ({
  marginBottom: 0,
  fontWeight: typography.fontWeightBold,
}));

export interface PersonCardProps {
  teammate: PersonStoryblok;
  className?: string;
}

function PersonCard(props: PersonCardProps): React.ReactElement | null {
  const { teammate, className } = props;
  const { name, role } = teammate;
  const organization = teammate.organization?.split(',') || [];
  const organizationName = organization[0];
  const organizationLink =
    organization.length >= 2 ? organization[1] : undefined;
  const avatar = teammate.avatar?.filename;
  return (
    <Root className={className} {...storyblokEditable(teammate)}>
      <Content>
        {avatar && (
          <Avatar alt={name} src={avatar} sx={{ width: 56, height: 56 }} />
        )}
        <div>
          <Name variant="h3">{name}</Name>
          {(role || organization) && (
            <Typography variant="subtitle1">
              {role} {organization && role && ' - '}
              {organization && (
                <OrgDisplayText
                  org={{ name: organizationName, website: organizationLink }}
                />
              )}
            </Typography>
          )}
        </div>
      </Content>
    </Root>
  );
}

export default observer(PersonCard);
