// NPM packages
import React from 'react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import {
  Link as ReactRouterLink,
  LinkProps as ReactRouterLinkProps,
} from 'react-router-dom';
import styled from '@emotion/styled';

// All other imports
import { isAbsoluteUrl, isMailto, PartialBy } from 'utils';

type RouterProps = Omit<ReactRouterLinkProps, keyof MuiLinkProps>;
export interface LinkProps extends MuiLinkProps, PartialBy<RouterProps, 'to'> {
  openNewTab?: boolean;
  onPage?: boolean;
  targetProp?: string;
  relProp?: string;
  className?: string;
}

const LinkAsButton = styled(MuiLink)({
  verticalAlign: 'baseline',
  display: 'inline',
});

export default React.forwardRef((props: LinkProps, ref) => {
  const {
    to,
    download,
    openNewTab,
    onPage,
    targetProp,
    relProp,
    ...forwardedProps
  } = props;
  let target = targetProp;
  let rel = relProp;
  if (openNewTab) {
    if (targetProp) {
      throw new Error(
        'Cannot pass both openNewTab and target props to Link component'
      );
    }
    if (relProp) {
      throw new Error(
        'Cannot pass both openNewTab and rel props to Link component'
      );
    }
    target = openNewTab ? '_blank' : undefined;
    rel = openNewTab ? 'noopener noreferrer' : undefined;
  }
  if (!to) {
    // A link with no "to" needs to be a button for A11y
    // The "any" is needed because not all React Router props are valid
    // on buttons (buyer beware)... hopefully we can improve this later

    return (
      <LinkAsButton
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(forwardedProps as any)}
        ref={ref}
        component="button"
        variant="body1"
      />
    );
  } else if (
    typeof to === 'string' &&
    (download || isAbsoluteUrl(to) || isMailto(to))
  ) {
    return (
      <MuiLink
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(forwardedProps as any)}
        ref={ref}
        href={to}
        download={download}
        target={target}
        rel={rel}
      />
    );
  } else {
    return (
      <MuiLink
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(forwardedProps as any)}
        ref={ref}
        component={ReactRouterLink}
        to={to}
        sx={onPage ? { borderBottom: '4px solid white' } : undefined}
        target={target}
        rel={rel}
      />
    );
  }
});
