import EnvService from './EnvService';
import RdmApiService from './RdmApiService';
import Project from 'types/Project';
import { makeObservable, observable, runInAction } from 'mobx';
import AuthService from './AuthService';
import NotFoundError from 'types/errors/NotFoundError';
import GlobalErrorService from './GlobalErrorService';
import ProjectJson from 'types/ProjectJson';
import { AxiosError, AxiosResponse } from 'axios';

export default class ProjectService {
  envService: EnvService;
  rdmApiService: RdmApiService;
  authService: AuthService;
  searchPageNum: number = 1;
  searchPageSize: number = 30;
  globalErrorService: GlobalErrorService;
  searchResults: Project[] | null = null;
  /** Map from slug => project */
  projects: Record<string, Project | NotFoundError> = {};
  selectedProject: Project | NotFoundError | null = null;
  affiliations: string[] | undefined;

  constructor({
    envService,
    rdmApiService,
    authService,
    globalErrorService,
  }: {
    envService: EnvService;
    rdmApiService: RdmApiService;
    authService: AuthService;
    globalErrorService: GlobalErrorService;
  }) {
    this.envService = envService;
    this.rdmApiService = rdmApiService;
    this.authService = authService;
    this.globalErrorService = globalErrorService;
    this.affiliations = undefined;

    makeObservable(this, {
      searchPageNum: observable,
      searchPageSize: observable,
      searchResults: observable,
      selectedProject: observable,
      projects: observable,
      affiliations: observable,
    });
  }

  private getProject = (slug: string): Project | NotFoundError | null => {
    return this.projects[slug] || null;
  };

  private cacheProjectIfNeeded = (json: ProjectJson): Project => {
    let project = this.getProject(json.slug);
    if (project === null || project instanceof NotFoundError) {
      project = new Project({
        projectData: json,
        authService: this.authService,
        rdmApiService: this.rdmApiService,
        globalErrorService: this.globalErrorService,
      });
      this.projects[json.slug] = project;
    }
    return project;
  };

  selectProject = async (slug: string): Promise<void> => {
    let project: Project | null | NotFoundError = null;
    try {
      const projectResp = await this.rdmApiService.axios.get(
        `/communities/${slug}`
      );
      const projectJson = projectResp.data;
      project = this.cacheProjectIfNeeded(projectJson);
      project.loadMembers();
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 404) {
        project = new NotFoundError();
      } else {
        throw error;
      }
    }

    runInAction(() => {
      this.selectedProject = project;
    });
  };

  setSearchPageSize = (newSize: number): void => {
    if (newSize < 1) {
      throw new Error(`Invalid Page Size: ${newSize}`);
    }
    this.searchPageSize = newSize;
  };

  setSearchPageNum = (newPageNum: number): void => {
    if (newPageNum < 1) {
      throw new Error(`Invalid Page Number: ${newPageNum}`);
    }
    this.searchPageNum = newPageNum;
  };

  search = async (): Promise<void> => {
    try {
      const resp = await this.rdmApiService.axios.get('/communities', {
        params: {
          sort: 'alpha',
          page: this.searchPageNum,
          size: this.searchPageSize,
        },
      });
      const parsedResponse = resp.data;
      const rawMetadata = parsedResponse.hits.hits;
      const project = rawMetadata.map(this.cacheProjectIfNeeded);
      runInAction(() => {
        this.searchResults = project;
      });
    } catch (error: unknown) {
      this.globalErrorService.showError({
        message: 'Could not search for projects',
      });
    }
  };

  searchAffiliations = async (
    suggest: string,
    page: number = 1,
    sort: string = 'bestmatch'
  ): Promise<void> => {
    runInAction(() => {
      this.affiliations = undefined;
    });
    try {
      const endpoint = `/affiliations?suggest=${suggest}&page=${page}&sort=${sort}`;
      const response = await this.rdmApiService.axios.get(endpoint);
      const result = response.data.hits.hits;
      const affiliationNames: Set<string> = new Set();
      result.forEach((data: AxiosResponse['data']) => {
        data.acronym
          ? affiliationNames.add(`${data.name} (${data.acronym})`)
          : affiliationNames.add(`${data.name}`);
      });
      runInAction(() => {
        this.affiliations = Array.from(affiliationNames.values());
      });
    } catch (e: unknown) {
      if (e instanceof AxiosError) {
        this.globalErrorService.showError({
          message: e.message,
        });
      } else {
        throw e;
      }
    }
  };
}
