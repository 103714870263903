/**
 * WARNING: Never use this directly. Always use ServiceProvider and useServices!
 */
import React from 'react';

import Services from './Services';

// A context needs a default value. Constructing the services here is
// impractical (it should be handled by the client code). To solve this
// problem, we will:
//   a) Set the value to null
//   b) Only use services with ServiceProvider (so we never get the null value)
type NullableServices = Services | null;

export default React.createContext<NullableServices>(null);
