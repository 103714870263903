// NPM packages
import React, { useRef } from 'react';
import { Menu, useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// All other imports

const StyledMenu = styled(Menu)(({ theme: { palette } }) => ({
  '&&&&&&&&& .Mui-focusVisible': {
    backgroundColor: 'inherit',
  },
  '&&&&&&&&& .MuiMenuItem-root:hover': {
    backgroundColor: palette.action.hover,
  },
  '&&&&&&&&& .MuiPaper-root': {
    borderRadius: '0px',
    border: '1px solid #dcdcdc',
    boxShadow: '1px 4px 5px #dcdcdc',
    marginTop: '-5px',
  },
  '&&&&&&&&& .MuiMenuItem-root': {
    paddingLeft: '2.25rem',
    paddingRight: '2.25rem',
    display: 'flex',
    justifyContent: 'center',
  },
  '&&&&&&&&& .MuiList-padding': {
    paddingBottom: '2px',
  },
}));

const OpenIcon = styled(FontAwesomeIcon)(({ theme: { palette } }) => ({
  color: palette.common.white,
  paddingLeft: '0.25rem',
}));

export interface HoverMenuProps {
  id: string;
  renderAnchor: (anchorProps: object) => React.ReactNode;
  renderMenuItems: (args: { close: () => void }) => React.ReactNode;
}

/**
 * WARNING: THIS IS NOT ACCESSIBLE!
 * We need to revisit this post V1 release to make sure
 * people who can't use mice are still able to navigate
 * our site.
 */
export default function HoverMenu(
  props: HoverMenuProps
): React.ReactElement | null {
  const { renderAnchor, renderMenuItems, id } = props;
  const menuRef = useRef<HTMLUListElement>(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const theme = useTheme();
  const open = Boolean(anchorEl);
  const handleOpenEvent = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const close = (): void => {
    setAnchorEl(null);
  };
  const handleMouseLeave = (event: React.MouseEvent<HTMLElement>): void => {
    const newTarget = event.relatedTarget;
    if (
      newTarget instanceof Node &&
      !(menuRef.current && menuRef.current.contains(newTarget))
    ) {
      close();
    }
  };
  const anchorId = `${id}-anchor`;
  const menuId = `${id}-menu`;
  return (
    <div>
      {renderAnchor({
        className: 'hover-menu-anchor',
        onMouseOver: handleOpenEvent,
        // onClick: handleOpenEvent,
        onMouseLeave: handleMouseLeave,
        id: anchorId,
        'aria-controls': open ? menuId : undefined,
        'aria-haspopup': 'true',
        'aria-expanded': open ? 'true' : undefined,
        style: { zIndex: theme.zIndex.modal + 1, position: 'relative' },
      })}
      <OpenIcon icon={faCaretDown} size="sm" />
      <StyledMenu
        id={menuId}
        anchorEl={anchorEl}
        open={open}
        onClose={close}
        transitionDuration={0}
        MenuListProps={{
          'aria-labelledby': anchorId,
          onMouseLeave: handleMouseLeave,
          ref: menuRef,
        }}
      >
        {renderMenuItems({
          close: close,
        })}
      </StyledMenu>
    </div>
  );
}
