// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

// All other imports
import TextField from 'components/core/TextField';
import { useServices } from 'services';
import Form from 'components/core/Form';

export interface ResetPasswordFormProps {
  className?: string;
  email: string;
  code: string;
}

interface FormData {
  newPassword: string;
  confirmNewPassword: string;
}

const schema: yup.ObjectSchema<FormData> = yup.object({
  newPassword: yup.string().required().label('Password'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required()
    .label('Confirm Password'),
});

const defaultValues: FormData = {
  newPassword: '',
  confirmNewPassword: '',
};

function ResetPasswordForm(
  props: ResetPasswordFormProps
): React.ReactElement | null {
  const { className, email, code } = props;
  const { authService } = useServices();

  return (
    <Form<FormData>
      className={className}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={(data) =>
        authService.resetPassword({
          email,
          code,
          newPassword: data.newPassword,
        })
      }
      getErrorMessage={(rejectedVal) => 'Could not reset password'}
      getSuccessMessage={(resolvedVal) => 'Password successfully reset'}
      submitText="Reset"
    >
      {(formState) => (
        <>
          <TextField
            formState={formState}
            fieldName="newPassword"
            textFieldProps={{ type: 'password' }}
          />
          <TextField
            formState={formState}
            fieldName="confirmNewPassword"
            textFieldProps={{ type: 'password' }}
          />
        </>
      )}
    </Form>
  );
}

export default observer(ResetPasswordForm);
