// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports

const Root = styled.div(({ theme: { outermostPadding, pagePaddingOpts } }) => ({
  ...outermostPadding(pagePaddingOpts),
  display: 'flex',
  justifyContent: 'center',
}));

const Content = styled.div<{
  maxWidthOverride?: React.CSSProperties['maxWidth'];
}>(({ theme: { maxTextWidth }, maxWidthOverride }) => ({
  maxWidth: maxWidthOverride ?? maxTextWidth,
  width: '100%',
}));

export interface PageContentProps {
  className?: string;
  children: React.ReactNode;
  maxWidth?: React.CSSProperties['maxWidth'];
}

function PageContent(props: PageContentProps): React.ReactElement | null {
  const { className, children, maxWidth } = props;
  return (
    <Root className={className}>
      <Content maxWidthOverride={maxWidth}>{children}</Content>
    </Root>
  );
}

export default observer(PageContent);
