import { useEffect, useState } from 'react';
import { SimpleDataset } from 'services/MetricsService';
import {
  changeMargins,
  generateTickData,
  generateTickIndicies,
  maxHeight,
  maxWidth,
  usualMargins,
} from './utils';
import {
  Brush,
  CartesianGrid,
  Line,
  LineChart,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { filesize } from 'filesize';
import { MSDTooltip } from './MSDTooltip';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';
import ChartDataLabel from './ChartDataLabel';
import { CircularProgress } from '@mui/material';
import { Tooltip as MuiTooltip, useTheme } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

export interface LineChartProps {
  data: SimpleDataset;
  total: string | number | undefined;
  metricType: string;
  tooltipVar: string;
  tooltipFormatter?: boolean;
  widthPercent?: number;
  marginsChanges?: Record<string, number>;
  filesizeFormat?: boolean;
  showBrush: boolean;
}

const Root = styled.div({});

const StyledSpinner = styled(CircularProgress)({
  color: '#16adfe',
});

const BrushHelper = styled.div(({ theme: { palette } }) => ({
  color: palette.primary.dark,
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  zIndex: 1000,
  gap: '0.5rem',
  top: '255px',
  left: '520px',
}));

function MSDLineChart(props: LineChartProps): React.ReactElement {
  const {
    data,
    total,
    metricType,
    widthPercent,
    marginsChanges,
    filesizeFormat,
    tooltipVar,
    tooltipFormatter,
    showBrush,
  } = props;
  const theme = useTheme();

  const chartMargins = marginsChanges
    ? changeMargins(marginsChanges)
    : usualMargins;
  const [brushStartIndex, setBrushStartIndex] = useState<number>(0);
  const [brushEndIndex, setBrushEndIndex] = useState<number>(data.length);
  const showBrushHelper =
    brushStartIndex === 0 && brushEndIndex >= data.length - 1 && showBrush;

  useEffect(() => {
    setBrushStartIndex(0);
    setBrushEndIndex(data.length);
  }, [data]);

  if (!data) {
    return <StyledSpinner size="4rem" />;
  }

  const tickIndicies = generateTickIndicies(data.length);
  const tickData = generateTickData(data, tickIndicies);
  const tooltipText =
    'Click and drag the left and right posts to change the date range. Click and drag the bar to move the date range.';

  const onBrushChange = (newIndex: {
    startIndex?: number;
    endIndex?: number;
  }): void => {
    setBrushStartIndex(newIndex.startIndex || 0);
    setBrushEndIndex(newIndex.endIndex || data.length);
  };

  return (
    <Root>
      {showBrushHelper && (
        <BrushHelper>
          <div>Date Range Selector</div>
          <MuiTooltip title={tooltipText} style={{ fontSize: '16px' }}>
            <FontAwesomeIcon
              icon={faCircleInfo}
              color={theme.palette.primary.dark}
            />
          </MuiTooltip>
        </BrushHelper>
      )}
      <ChartDataLabel data={total} name={metricType} />
      <LineChart
        width={widthPercent ? maxWidth * widthPercent : maxWidth}
        height={maxHeight}
        data={data}
        margin={chartMargins}
        {...{ overflow: 'visible' }}
      >
        <XAxis
          dataKey="day"
          stroke="#093e80"
          minTickGap={48}
          ticks={tickData}
        />
        <YAxis
          dataKey="Value"
          stroke="#093e80"
          tickFormatter={filesizeFormat ? filesize : undefined}
        />
        <CartesianGrid horizontal vertical={false} strokeDasharray="3 3" />
        <Line
          type="monotone"
          dataKey="Value"
          stroke="#16adfe"
          strokeWidth={2}
          dot={false}
        />
        <Tooltip
          position={{ x: 70, y: 10 }}
          content={
            <MSDTooltip
              varName={tooltipVar}
              varFormatter={tooltipFormatter || false}
              esPluralize={['Launch'].includes(tooltipVar)}
              noOpeningName
            />
          }
        />
        {showBrush && <Brush onChange={onBrushChange} />}
      </LineChart>
    </Root>
  );
}

export default observer(MSDLineChart);
