// NPM packages
import React from 'react';
import {
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Controller, ControllerProps, Path } from 'react-hook-form';
import merge from 'lodash/merge';
import kebabCase from 'lodash/kebabCase';
import startCase from 'lodash/startCase';

// All other imports
import { FormState } from 'hooks/useForm';
import { callFuncsInOrder } from 'utils';

type ControllerPropsNoRender<T extends object> = Omit<
  ControllerProps<T>,
  'render'
>;

export interface TextFieldProps<FormData extends object> {
  formState: FormState<FormData>;
  fieldName: Path<FormData>;
  textFieldProps?: MuiTextFieldProps;
  controllerProps?: ControllerPropsNoRender<FormData>;
  children?: MuiTextFieldProps['children'];
}

function TextField<FormData extends object>(
  props: TextFieldProps<FormData>
): React.ReactElement | null {
  const {
    formState: { control },
    fieldName,
    textFieldProps,
    controllerProps,
    children,
  } = props;
  const defaultControllerProps: ControllerPropsNoRender<FormData> = {
    control,
    name: fieldName,
    rules: { required: 'Required' },
  };
  const finalControllerProps: ControllerPropsNoRender<FormData> = merge(
    defaultControllerProps,
    controllerProps
  );

  const defaultTextFieldProps: MuiTextFieldProps = {
    id: kebabCase(fieldName),
    label: startCase(fieldName),
    fullWidth: true,
    margin: 'dense',
    size: 'small',
    children,
  };
  const finalTextFieldProps: MuiTextFieldProps = merge(
    defaultTextFieldProps,
    textFieldProps
  );

  return (
    <Controller
      {...finalControllerProps}
      render={({ field, fieldState }) => {
        const { error } = fieldState;
        const showError = Boolean(error);
        const {
          onChange: fieldOnChange,
          onBlur: fieldOnBlur,
          ...otherFieldProps
        } = field;
        const {
          onChange: userOnChange,
          onBlur: userOnBlur,
          ...otherUserProps
        } = finalTextFieldProps;

        return (
          <MuiTextField
            error={showError}
            helperText={error?.message}
            {...otherFieldProps}
            {...otherUserProps}
            onChange={callFuncsInOrder([userOnChange, fieldOnChange])}
            onBlur={callFuncsInOrder([userOnBlur, fieldOnBlur])}
          />
        );
      }}
    />
  );
}

export default observer(TextField);
