// NPM packages
import React, { useState } from 'react';
import { Divider, Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import {
  GuideResourceStoryblok,
  TextWithLinkStoryblok,
} from 'types/storyblokTypes';
import { getImageElement } from '../utils';
import { storyblokEditable } from '@storyblok/react';
import { RESOURCE_COMPONENT_COLUMN_GAP as COLUMN_GAP } from '../utils';
import { IMAGE_COLUMN_WIDTH_PX } from '../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import GifViewer from '../GifViewer';

const StoryblokEditable = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

const Root = styled.div(({ theme }) => {
  return {
    minWidth: theme.maxToolWidth,
    marginTop: '1.5rem',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: `${IMAGE_COLUMN_WIDTH_PX}px 45rem`,
    gap: `${COLUMN_GAP}rem`,
  };
});

const Header = styled(Typography)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '24px !important',
  margin: '0px',
});

const UnorderedList = styled.ul({
  margin: '0px',
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'center',
});

const ListItem = styled.li({
  width: '100%',
});

const fakeLinkStyle = {
  cursor: 'pointer',
  textDecoration: 'underline',
};

const GuideLink = styled.a<{ fakeLink?: boolean; visited?: boolean }>(
  ({ fakeLink, visited }) => {
    const linkStyling = fakeLink ? fakeLinkStyle : {};
    const colorStyling = fakeLink
      ? visited
        ? { color: '#551A8B' } // Visited link color
        : { color: '#0000EE' } // Non-visited link color
      : {};
    return {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      ...linkStyling,
      ...colorStyling,
    };
  }
);

const StyledDivider = styled(Divider)({
  marginTop: '1.5rem',
  width: '90%',
});

const SVGImage = styled.img({
  height: '1.5rem',
  width: '1.5rem',
  marginRight: '0.75rem',
});

export interface GuideResourceProps {
  blok: GuideResourceStoryblok;
  className?: string;
}

function GuideResource(props: GuideResourceProps): React.ReactElement | null {
  const { blok } = props;

  const { title, textLinkPairs, icon, iconAlt } = blok;
  const imageElement = getImageElement(icon, iconAlt, SVGImage);
  const anchorId = title.toLowerCase().replaceAll(' ', '-');
  const [currentViewerIndex, setCurrentViewerIndex] = useState<number | null>(
    null
  );
  const [clickedViewerIndicies, setClickedViewerIndicies] = useState<
    Set<number>
  >(new Set<number>());

  return (
    <StoryblokEditable {...storyblokEditable(blok)}>
      <Root id={anchorId}>
        <Header variant="h2">
          {imageElement}
          {title}
        </Header>
        <UnorderedList>
          {textLinkPairs.map((pair: TextWithLinkStoryblok, idx: number) => {
            const externalLink = !pair.link.startsWith('/');
            const gifLink = pair.link.endsWith('.gif');
            return (
              <ListItem key={`listItem-${title}-${idx}`}>
                {gifLink && (
                  <GifViewer
                    title={pair.text}
                    link={pair.link}
                    open={currentViewerIndex === idx}
                    setOpen={setCurrentViewerIndex}
                  />
                )}
                <GuideLink
                  target={externalLink ? '_blank' : undefined}
                  rel={externalLink ? 'noopener noreferrer' : undefined}
                  href={gifLink ? undefined : pair.link}
                  onClick={() => {
                    setClickedViewerIndicies(
                      new Set([...Array.from(clickedViewerIndicies), idx])
                    );
                    setCurrentViewerIndex(idx);
                  }}
                  fakeLink={gifLink}
                  visited={clickedViewerIndicies.has(idx)}
                >
                  <div
                    style={{ marginRight: externalLink ? '0.5rem' : '0rem' }}
                  >
                    {pair.text}
                  </div>
                  {externalLink && !gifLink && (
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  )}
                </GuideLink>
              </ListItem>
            );
          })}
        </UnorderedList>
      </Root>
      <StyledDivider />
    </StoryblokEditable>
  );
}

export default observer(GuideResource);
