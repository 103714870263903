// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import { Alert, AlertTitle } from '@mui/material';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// All other imports
import PageContent from 'components/core/PageContent';
import FormCard from 'components/core/FormCard';
import ResetPasswordForm from 'components/auth/ResetPasswordForm';

const Root = styled(PageContent)({});

export interface ResetPasswordProps {
  className?: string;
}

export default function ResetPassword(
  props: ResetPasswordProps
): React.ReactElement | null {
  const { className } = props;
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const code = searchParams.get('code');
  let body: React.ReactNode;
  if (!email || !code) {
    body = (
      <Alert>
        <AlertTitle>Error</AlertTitle>
        Malformed URL
      </Alert>
    );
  } else {
    body = (
      <FormCard
        title="Reset Password"
        titleIcon={<FontAwesomeIcon icon={faUser} />}
      >
        <ResetPasswordForm email={email} code={code} />
      </FormCard>
    );
  }
  return <Root className={className}>{body}</Root>;
}
