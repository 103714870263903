import { ThemeOptions, responsiveFontSizes } from '@mui/material/styles';

import createMuiTheme from 'styles/createMuiTheme';

const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      light: '#16adfe',
      main: '#1b75bb',
      dark: '#093e80',
    },
    secondary: {
      main: '#428bca',
      light: '#0C5DC1', //color for join button hovering
    },
  },
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'contained',
      },
    },
    MuiButtonGroup: {},
    MuiLink: {
      defaultProps: {
        underline: 'none',
      },
    },
    MuiTextField: {},
    MuiDialog: {},
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 600,
      lg: 900,
      xl: 1200,
    },
  },
  maxTextWidth: '45rem',
  maxToolWidth: '87.5rem',
  // Will fill in below
  outermostPadding: () => {},
  pagePaddingOpts: {
    vertical: {
      mobile: 0,
      tablet: 0,
      desktop: 0,
    },
  },
};

const theme = createMuiTheme(themeOptions);
theme.typography.body1.marginBottom = '0.5rem';
theme.typography.body1.maxWidth = theme.maxTextWidth;
theme.typography.h1.fontSize = '3rem';
theme.typography.h1.fontFamily = theme.typography.h2.fontFamily =
  "'Rubic', sans-serif";
theme.typography.h2.fontWeight = theme.typography.fontWeightMedium;
theme.typography.h1.fontWeight = theme.typography.fontWeightBold;
theme.typography.h3.fontFamily =
  theme.typography.h6.fontFamily =
  theme.typography.body1.fontFamily =
    "'Roboto', sans-serif";
theme.typography.h1.marginBottom = '2rem';
theme.typography.h2.fontSize = '2.25rem';
theme.typography.h2.marginTop = '1.5rem';
theme.typography.h2.marginBottom = '1rem';
theme.typography.h3.fontSize = '1.5rem';
theme.typography.h3.fontWeight = theme.typography.fontWeightBold;
theme.typography.h3.marginBottom = '0.5rem';
theme.typography.h4.fontSize = '1.4rem';
theme.typography.h4.marginTop = '1.5rem';
theme.typography.h4.marginBottom = '0.5rem';
theme.typography.h4.fontStyle = 'italic';
theme.typography.h6.fontWeight = theme.typography.fontWeightBold;
theme.typography.body1.fontSize = '1.2rem';
theme.typography.h6.fontSize = '1rem';
theme.typography.body1.fontWeight = 400;

theme.outermostPadding = ({ vertical }) => ({
  padding: `${vertical.mobile} 1rem`,
  [theme.breakpoints.up('lg')]: {
    padding: `${vertical.tablet} ${theme.typography.pxToRem(43.5)}`,
  },
  [theme.breakpoints.up('xl')]: {
    padding: `${vertical.desktop} ${theme.typography.pxToRem(87)}`,
  },
});
theme.pagePaddingOpts = {
  vertical: {
    mobile: theme.typography.pxToRem(30),
    tablet: theme.typography.pxToRem(45),
    desktop: theme.typography.pxToRem(60),
  },
};

// Component Style Overrides
// NOTE: For consistency, add to theme.components
// before customizing here.

theme.components!.MuiButton!.styleOverrides = {
  root: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'none',
    '& .MuiButton-startIcon>*:nth-of-type(1)': {
      fontSize: '1rem',
    },
  },
  containedPrimary: {
    backgroundColor: '#093e80',
  },
  containedSizeSmall: {
    borderRadius: '0.3rem',
    fontFamily: theme.typography.h6.fontFamily,
    fontSize: theme.typography.h6.fontSize,
    padding: '0.1rem 1.8rem 0.1rem 1.8rem',
  },
  containedSecondary: {
    color: 'rgba(0, 0, 0, 0.8)',
    backgroundColor: '#e0e1e2',
    '&:hover': {
      backgroundColor: '#cacbcd',
    },
  },
};
theme.components!.MuiButtonGroup!.styleOverrides = {
  root: {
    '&&& .MuiButton-root': {
      border: 'none',
    },
  },
};
theme.components!.MuiTextField!.styleOverrides = {
  root: {
    '& .MuiFormHelperText-root': {
      marginTop: 0,
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      marginBottom: 0,
    },
  },
};
theme.components!.MuiDialog!.styleOverrides = {
  root: {
    '&&&& .MuiPaper-root': {
      // TODO: figure out what's going on here
      // This shouldn't be necessary, but without
      // it the dialog has max-width: 0px
      maxWidth: '50rem',
    },
    zIndex: theme.zIndex.snackbar,
  },
};

export default responsiveFontSizes(theme);
