import {
  useForm as reactHookUseForm,
  UseFormReturn as ReactHookFormReturn,
  DefaultValues,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import useLoadingStateManager from 'hooks/useLoadingStateManager';
import LoadingStateManager, { LoadingStatus } from 'types/LoadingStateManager';

export interface UseFormArgs<FormData extends object> {
  schema: yup.ObjectSchema<FormData>;
  defaultValues: DefaultValues<FormData>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: FormData) => Promise<any>;
}

export type FormState<FormData extends object> = Omit<
  ReactHookFormReturn<FormData>,
  'handleSubmit'
>;
export interface UseFormReturn<FormData extends object> {
  loadingStateManager: LoadingStateManager;
  reactHookFormVals: FormState<FormData>;
  handleSubmit: ReturnType<ReactHookFormReturn['handleSubmit']>;
  disableSubmit: boolean;
}

export default function useForm<FormData extends object>({
  defaultValues,
  schema,
  onSubmit,
}: UseFormArgs<FormData>): UseFormReturn<FormData> {
  const loadingStateManager = useLoadingStateManager();
  const { handleSubmit: baseHandleSubmit, ...reactHookFormVals } =
    reactHookUseForm<FormData>({
      // Type cast because react-hook-form has messed up types
      defaultValues,
      // mode: 'onBlur',
      resolver: yupResolver(schema),
    });

  const handleSubmit = baseHandleSubmit((data) => {
    // Type cast because react-hook-form has messed up types
    const castedData = data as FormData;
    loadingStateManager.startLoading({
      waitOn: onSubmit(castedData),
    });
  });

  const { loadingState } = loadingStateManager;
  return {
    loadingStateManager,
    reactHookFormVals,
    handleSubmit,
    disableSubmit: loadingState.status === LoadingStatus.LOADING,
  };
}
