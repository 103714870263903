// NPM packages
import React from 'react';
import { IconButton } from '@mui/material';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { observer } from 'mobx-react-lite';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const Root = styled.code({
  background: '#f5f5f5',
  padding: '8px 12px',
  borderRadius: '2px',
  whiteSpace: 'pre-line',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  fontSize: '.9rem',
});

export interface CopyCodeProps {
  className?: string;
  code: string;
}

function CopyCode(props: CopyCodeProps): React.ReactElement | null {
  const { className, code } = props;
  return (
    <Root className={className}>
      <span style={{ whiteSpace: 'pre-line' }}>{code}</span>
      <CopyToClipboard text={code}>
        <IconButton aria-label="copy">
          <FontAwesomeIcon icon={faCopy} />
        </IconButton>
      </CopyToClipboard>
    </Root>
  );
}

export default observer(CopyCode);
