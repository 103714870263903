// NPM packages
import React from 'react';
import { Button, ButtonProps } from '@mui/material';

// All other imports
import useForm, { UseFormArgs, FormState } from 'hooks/useForm';
import SpinnerOutlet from 'components/core/SpinnerOutlet';
import ErrorOutlet from 'components/core/ErrorOutlet';
import SuccessOutlet from 'components/core/SuccessOutlet';
import styled from '@emotion/styled';

export interface FormProps<FormData extends object>
  extends UseFormArgs<FormData> {
  className?: string;
  submitText: React.ReactNode;
  submitButtonProps?: ButtonProps;
  replaceFormWithSuccess?: boolean;
  children?: (args: FormState<FormData>) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSuccessMessage?: (submitResolvedValue: any) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage?: (submitRejectedValue: any) => React.ReactNode;
  onCancel?: () => void;
}

const ButtonBar = styled.div({
  marginTop: '2rem',
  '&& > *': {
    marginRight: '1rem',
  },
});

export default function Form<FormData extends object>(
  props: FormProps<FormData>
): React.ReactElement | null {
  const {
    className,
    submitText,
    submitButtonProps,
    replaceFormWithSuccess = true,
    children,
    getSuccessMessage,
    getErrorMessage,
    onCancel,
    ...useFormArgs
  } = props;
  const {
    loadingStateManager,
    reactHookFormVals,
    disableSubmit,
    handleSubmit,
  } = useForm<FormData>(useFormArgs);

  const buttonBar = (
    <ButtonBar>
      <Button type="submit" {...submitButtonProps} disabled={disableSubmit}>
        {submitText}
        <SpinnerOutlet loadingStateManager={loadingStateManager} />
      </Button>
      {onCancel && (
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      )}
    </ButtonBar>
  );

  const childComponents = children && children(reactHookFormVals);

  const errorOutlet = (
    <ErrorOutlet
      loadingStateManager={loadingStateManager}
      getErrorMessage={getErrorMessage}
    />
  );

  const successMessage = getSuccessMessage ?? (() => 'Success');

  return replaceFormWithSuccess ? (
    <SuccessOutlet
      loadingStateManager={loadingStateManager}
      getSuccessMessage={successMessage}
      className={className}
    >
      <form onSubmit={handleSubmit}>
        {childComponents}
        {errorOutlet}
        {buttonBar}
      </form>
    </SuccessOutlet>
  ) : (
    <form onSubmit={handleSubmit}>
      {childComponents}
      <SuccessOutlet
        loadingStateManager={loadingStateManager}
        getSuccessMessage={successMessage}
        className={className}
      />
      {errorOutlet}
      {buttonBar}
    </form>
  );
}
