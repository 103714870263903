// NPM packages
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { observer } from 'mobx-react-lite';
import { faUser } from '@fortawesome/free-solid-svg-icons';

// All other imports
import SignUpForm from 'components/auth/SignUpForm';
import PageContent from 'components/core/PageContent';
import FormCard from 'components/core/FormCard';
import { useServices } from 'services';
import { useNavigate } from 'react-router';

const Root = styled(PageContent)({});

export interface SignUpProps {
  className?: string;
}

function SignUp(props: SignUpProps): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();
  const navigate = useNavigate();
  const isLoggedIn = authService.isLoggedIn;

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/profile', { replace: true });
    }
  }, [isLoggedIn, navigate]);

  return (
    <Root className={className}>
      <FormCard title="Sign Up" titleIcon={<FontAwesomeIcon icon={faUser} />}>
        <SignUpForm />
      </FormCard>
    </Root>
  );
}

export default observer(SignUp);
