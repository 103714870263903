// NPM packages
import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import { PrincipleListStoryblok } from 'types/storyblokTypes';
import { useTheme } from '@emotion/react';
import { storyblokEditable } from '@storyblok/react';

const Root = styled.ul({
  marginTop: '2.5rem',
  padding: 0,
  listStyle: 'none',
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(20rem, 1fr))',
  columnGap: '3rem',
  rowGap: '2rem',
});

const Item = styled.li({
  display: 'flex',
});

const TextWrapper = styled.div({});

const PrincipleTitle = styled(Typography)({
  marginBottom: '0.5rem',
});

const PrincipleDescription = styled(Typography)({
  maxWidth: '15rem',
});

export interface PrincipleListProps {
  blok: PrincipleListStoryblok;
  className?: string;
}

function PrincipleList(props: PrincipleListProps): React.ReactElement | null {
  const { blok, className } = props;
  const principles = blok.principles;

  return (
    <Root
      className={className}
      aria-label="core principles"
      {...storyblokEditable(blok)}
    >
      {principles?.map((principle) => {
        return (
          <Item key={principle.title}>
            <PrincipleIcon filename={principle.svgIcon.filename} />
            <TextWrapper>
              <PrincipleTitle variant="h3">{principle.title}</PrincipleTitle>
              <PrincipleDescription>
                {principle.description}
              </PrincipleDescription>
            </TextWrapper>
          </Item>
        );
      })}
    </Root>
  );
}

interface PrincipleIconProps {
  filename: string;
}

const StyledIcon = styled.div({
  width: '92px',
  height: '92px',
  margin: '0 1rem',
});

function PrincipleIcon(props: PrincipleIconProps): React.ReactElement {
  const { filename } = props;
  const theme = useTheme();
  const [content, setContent] = useState('');

  useEffect(() => {
    const fetchSVG = async (): Promise<void> => {
      try {
        // Avoid CORS policy by calling storyblok's S3 bucket
        const fetchFilename = 'https://s3.amazonaws.com/' + filename.slice(8);
        const response = await fetch(fetchFilename);

        if (!response.ok)
          throw new Error(
            `Failed to fetch PrincipleIcon. Filename provided was "${filename}" and an attempt to fetch was made at "${fetchFilename}".`
          );

        let svgText = await response.text();

        // Ensure svg uses our theme color (convert all paths to primary light)
        const fillRegex = /fill=".{0,}"/g;
        svgText = svgText.replaceAll(fillRegex, '');
        svgText = svgText.replaceAll(
          '<path',
          `<path fill="${theme.palette.primary.light}"`
        );

        setContent(svgText);
      } catch (error) {
        console.error('Error fetching PrincipleIcon', error);
      }
    };

    fetchSVG();
  }, [filename, theme.palette.primary.light]);

  return <StyledIcon dangerouslySetInnerHTML={{ __html: content }} />;
}

export default observer(PrincipleList);
