// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import Link from 'components/core/Link';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

export interface SignUpSuccessProps {
  className?: string;
}

function SignUpSuccess(props: SignUpSuccessProps): React.ReactElement | null {
  const { className } = props;
  return (
    <Root className={className}>
      <Typography>
        In order to login, your account must be approved and you must verify
        your email. If you don't receive the verification email,{' '}
        <Link to={`/resend-verification`}>click here to resend it</Link>. You
        will receive an email notification when your account is approved.
      </Typography>
    </Root>
  );
}

export default observer(SignUpSuccess);
