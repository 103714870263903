// NPM packages
import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';

// All other imports
import NavItem from 'types/NavItem';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';

const Root = styled.nav({
  // EXAMPLE -> display: 'flex'
});

export interface HeaderNavProps {
  className?: string;
  items: NavItem[];
}

export default function HeaderNav(
  props: HeaderNavProps
): React.ReactElement | null {
  const { className, items } = props;

  return (
    <Root className={className}>
      <Box sx={{ display: { xl: 'none' } }}>
        <MobileMenu items={items} />
      </Box>
      <Box sx={{ display: { xs: 'none', xl: 'block' } }}>
        <DesktopMenu items={items} />
      </Box>
    </Root>
  );
}
