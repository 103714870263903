// NPM packages
import React, { useEffect } from 'react';
import { Alert, AlertTitle, Typography, Grid } from '@mui/material';
import styled from '@emotion/styled';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';

// All other imports
import PageContent from 'components/core/PageContent';
import { useServices } from 'services';
import HorizontallyCentered from 'components/core/HorizontallyCentered';
import CircularProgress from 'components/core/CircularProgress';
import H1 from 'components/core/H1';
import useLoadingStateManager from 'hooks/useLoadingStateManager';
import LoadingStateManager, { LoadingStatus } from 'types/LoadingStateManager';
import SpinnerOutlet from 'components/core/SpinnerOutlet';
import ErrorOutlet from 'components/core/ErrorOutlet';
import SuccessOutlet from 'components/core/SuccessOutlet';

const Root = styled(PageContent)({});

export interface ReviewNewUserProps {
  className?: string;
}

function ReviewNewUser(props: ReviewNewUserProps): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();
  const [searchParams] = useSearchParams();
  const loadingStateManager = useLoadingStateManager();
  const email = searchParams.get('email');
  const approvalLoading =
    loadingStateManager.loadingState.status === LoadingStatus.LOADING;

  useEffect(() => {
    if (email) {
      authService.fetchUser(email);
    }
  }, [email, authService]);

  const user = email && authService.users[email];
  const currentUser = authService.user;

  let body = null;
  if (!currentUser) {
    body = (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        You must be logged in to use this feature.
      </Alert>
    );
  } else if (!currentUser.isNewUserApprover) {
    body = (
      <Alert severity="error">
        <AlertTitle>Access Denied</AlertTitle>
        You are not have access to this feature.
      </Alert>
    );
  } else if (!user) {
    body = (
      <HorizontallyCentered>
        <CircularProgress text={'Loading...'} />
      </HorizontallyCentered>
    );
  } else {
    body = (
      <>
        <H1>Review New User</H1>
        <Grid container spacing={2} sx={{ marginBottom: '1.5rem' }}>
          <Grid item xs={12}>
            <Typography>
              The below user was recently created. Please verify they have a
              legitimate interest in using MSD-LIVE.
            </Typography>
          </Grid>
          {user.approved !== null && (
            <Grid item xs={12}>
              <Alert severity={user.approved ? 'info' : 'warning'}>
                <AlertTitle>
                  This user has been {user.approved ? 'APPROVED' : 'DENIED'}
                </AlertTitle>
              </Alert>
            </Grid>
          )}

          <Grid item xs={6}>
            <Typography variant="h3" component="h2">
              Name
            </Typography>
            <Typography>
              {user.firstName} {user.lastName}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="h3" component="h2">
              Email
            </Typography>
            <Typography>{user.email}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" component="h2">
              Affiliation
            </Typography>
            <Typography>{user.affiliation}</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h3" component="h2">
              Justification
            </Typography>
            <Typography>{user.justification}</Typography>
          </Grid>
          <Grid item xs={12}>
            <SpinnerOutlet loadingStateManager={loadingStateManager} />
            <ErrorOutlet loadingStateManager={loadingStateManager} />
          </Grid>
          <Grid item xs={12}>
            <SuccessOutlet
              loadingStateManager={loadingStateManager}
              getSuccessMessage={(resolvedValue) => resolvedValue}
            >
              <>
                <Button
                  sx={{ marginRight: '1rem' }}
                  disabled={approvalLoading}
                  onClick={() =>
                    loadingStateManager.startLoading({
                      waitOn: authService.approve_new_user(user),
                    })
                  }
                >
                  Approve
                </Button>
                <Button
                  color="secondary"
                  disabled={approvalLoading}
                  onClick={() =>
                    loadingStateManager.startLoading({
                      waitOn: authService.deny_new_user(user),
                    })
                  }
                >
                  Deny
                </Button>
              </>
            </SuccessOutlet>
          </Grid>
        </Grid>
      </>
    );
  }
  return <Root className={className}>{body}</Root>;
}

export default observer(ReviewNewUser);
