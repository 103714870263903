// NPM packages
import React from 'react';
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ButtonProps,
} from '@mui/material';
import { LinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

// All other imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from './Link';

const Root = styled.div({
  // EXAMPLE -> display: 'flex'
});

export interface SplitButtonOption {
  key: string;
  label: React.ReactNode;
  /**
   * For accessibility reasons, you MUST only use this
   * prop if this option does NOT cause navigation.
   * Otherwise use the "to" prop. The styles won't
   * change, but the HTML will.
   */
  onClick?: () => void;
  /**
   * Same as the "to" prop on the Link component.
   * Use this if clicking on the option causes navigation.
   */
  to?: LinkProps['to'];
  isDefault?: boolean;
}

export interface SplitButtonProps {
  className?: string;
  defaultOption: SplitButtonOption;
  secondaryOptions: SplitButtonOption[];
  buttonProps?: ButtonProps;
}
function SplitButton(props: SplitButtonProps): React.ReactElement | null {
  const { className, secondaryOptions, defaultOption, buttonProps } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  return (
    <Root className={className}>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button {...buttonProps} onClick={defaultOption.onClick}>
          {defaultOption.label}
        </Button>
        <Button
          {...buttonProps}
          size="small"
          aria-controls={open ? 'split-button-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-label="select option"
          aria-haspopup="menu"
          onClick={getHandleToggle(setOpen)}
        >
          <FontAwesomeIcon icon={faCaretDown} />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={getHandleClose(setOpen, anchorRef)}
              >
                <MenuList id="split-button-menu" autoFocusItem>
                  {secondaryOptions.map((option, index) => (
                    <MenuItem
                      key={option.key}
                      component={Link}
                      to={option.to}
                      onClick={(event: React.MouseEvent<HTMLElement>) =>
                        getHandleMenuItemClick(setOpen, secondaryOptions)(
                          event,
                          index
                        )
                      }
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Root>
  );
}

export default SplitButton;

function getHandleMenuItemClick(
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  secondaryOptions: SplitButtonOption[]
) {
  return (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOpen(false);
    const option = secondaryOptions[index];
    if (option.onClick) {
      option.onClick();
    }
  };
}
function getHandleToggle(
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
) {
  return () => {
    setOpen((prevOpen) => !prevOpen);
  };
}
function getHandleClose(
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  anchorRef: React.RefObject<HTMLDivElement>
) {
  return (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
}
