// NPM packages
import React from 'react';
import {
  ButtonProps,
  CircularProgress as MuiCircularProgress,
  CircularProgressProps as MuiCircularProgressProps,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import styled from '@emotion/styled';

export interface CircularProgressProps extends MuiCircularProgressProps {
  text?: string;
  insideButton?: boolean;
  buttonSize?: ButtonProps['size'];
}

const LoadingWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '3rem',
});

const LoadingText = styled.div({
  marginLeft: '1rem',
});

function CircularProgress(
  props: CircularProgressProps
): React.ReactElement | null {
  const { text } = props;
  const style: React.CSSProperties = { ...props.style };
  let size: MuiCircularProgressProps['size'] = props.size;

  if (props.insideButton) {
    size = '1.5rem';
    style.marginRight = '1rem';
    style.color = 'inherit';
    if (props.buttonSize === 'small') {
      style.marginRight = '0.5rem';
      size = '1rem';
    }
  }

  const body = text ? (
    <LoadingWrapper>
      <CircularProgress size={'2rem'} />
      <LoadingText>{text}</LoadingText>
    </LoadingWrapper>
  ) : (
    <MuiCircularProgress
      aria-label="Loading..."
      {...props}
      style={style}
      size={size}
    />
  );

  return body;
}

export default observer(CircularProgress);
