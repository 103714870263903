// NPM packages
import React from 'react';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';

// All other imports
import ResourceInformation from '../ResourceInformation';
import { TextResourceStoryblok } from 'types/storyblokTypes';
import { storyblokEditable } from '@storyblok/react';
import { IMAGE_COLUMN_WIDTH_PX } from '../utils';
import { RESOURCE_COMPONENT_COLUMN_GAP as COLUMN_GAP } from '../utils';
import { Card } from '@mui/material';

// All other imports

const Root = styled.div(({ theme }) => {
  return {
    minWidth: theme.maxToolWidth,
    marginTop: '1.75rem',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: `${IMAGE_COLUMN_WIDTH_PX}px 45rem`,
    gap: `${COLUMN_GAP}rem`,
  };
});

const ImageContentCard = styled(Card)({
  height: `${IMAGE_COLUMN_WIDTH_PX * 0.5}px`,
  width: `${IMAGE_COLUMN_WIDTH_PX}px`,
});

const Image = styled.img({
  height: `${IMAGE_COLUMN_WIDTH_PX * 0.5}px`,
  width: `${IMAGE_COLUMN_WIDTH_PX}px`,
  objectFit: 'cover',
});

export interface TextResourceProps {
  blok: TextResourceStoryblok;
  className?: string;
}

function TextResource(props: TextResourceProps): React.ReactElement | null {
  const { blok, className } = props;

  const thumbnailExists = Boolean(blok.image?.filename);
  return (
    <div {...storyblokEditable(blok)}>
      <Root className={className}>
        <ImageContentCard variant="elevation" raised>
          {thumbnailExists ? <Image src={blok.image?.filename} /> : <div></div>}
        </ImageContentCard>
        <ResourceInformation blok={blok} />
      </Root>
    </div>
  );
}

export default observer(TextResource);
