import React from 'react';
import {
  render,
  RenderOptions,
  NODE_PARAGRAPH,
  MARK_LINK,
  NODE_HEADING,
  NODE_LI,
  NODE_CODEBLOCK,
  MARK_CODE,
} from 'storyblok-rich-text-react-renderer';
import merge from 'lodash/merge';
import { RichtextStoryblok } from 'types/storyblokTypes';
import { Typography } from '@mui/material';
import CopyCode from 'components/core/CopyCode';
import styled from '@emotion/styled';
import DynamicLink from './DynamicLink';

const Heading = styled(Typography)<{ callingComponent?: string }>(({
  variant,
  callingComponent,
}) => {
  const leftMargin =
    callingComponent !== 'alert' && variant
      ? ((parseInt(variant.substring(1)) - 1) * 0.25).toString() + 'rem'
      : '0rem';
  const topMargin = callingComponent === 'alert' ? '0rem' : '1rem';
  return {
    margin: `${topMargin} 0 1rem ${leftMargin}`,
  };
});

export default function renderRichText(
  document: RichtextStoryblok,
  options?: RenderOptions,
  continueOrderedList?: boolean,
  callingComponent?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): any {
  let liIndex = 1;
  const defaultOptions: RenderOptions = {
    markResolvers: {
      [MARK_LINK]: linkResolver,
      [MARK_CODE]: (children) => {
        return codeResolver(children, callingComponent);
      },
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, level) =>
        headingResolver(children, level, callingComponent),
      [NODE_CODEBLOCK]: codeblockResolver,
      [NODE_LI]: (children) => {
        const content = listItemResolver(
          children,
          continueOrderedList ? liIndex : undefined
        );
        liIndex += 1;
        return content;
      },
      [NODE_PARAGRAPH]: paragraphResolver,
    },
  };
  const mergedOptions = merge(defaultOptions, options);
  return render(document, mergedOptions);
}

function codeResolver(
  children: React.ReactNode,
  callingComponent?: string
): React.ReactElement | null {
  const code = children?.toString() || '';
  if (callingComponent === 'alert' || code.startsWith('--')) {
    return (
      <b>
        <code style={{ margin: '0 0.5rem' }}>{children}</code>
      </b>
    );
  } else if (callingComponent === 'richText') {
    return code ? (
      <CopyCode code={code} />
    ) : (
      <Typography>{children}</Typography>
    );
  }
  return <Typography>{children}</Typography>;
}

function paragraphResolver(children: React.ReactNode): React.ReactElement {
  return <Typography>{children}</Typography>;
}

function headingResolver(
  children: React.ReactNode,
  { level }: { level: 1 | 2 | 3 | 4 | 5 | 6 },
  callingComponent?: string
): React.ReactElement {
  const variant = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  return (
    <Heading variant={variant} callingComponent={callingComponent}>
      {children}
    </Heading>
  );
}

function linkResolver(
  children: React.ReactNode,
  props: {
    linktype?: string;
    href?: string;
    target?: string;
    anchor?: string;
    uuid?: string;
  }
): React.ReactElement {
  const muiProps = props
    ? {
        href: props.linktype === 'email' ? `mailto:${props.href}` : props.href,
        target: props.target,
      }
    : {};
  return <DynamicLink muiProps={muiProps} children={children} />;
}

function listItemResolver(
  children: React.ReactNode,
  liIndex?: number
): React.ReactElement {
  return <li value={liIndex}>{children}</li>;
}

const Pre = styled.pre({
  display: 'block',
  overflowX: 'auto',
  padding: '0.5rem',
  color: 'rgb(0,0,0)',
  background: 'rgb(248,248,255)',
});

const Code = styled.code({
  whiteSpace: 'pre',
});

function codeblockResolver(children: React.ReactNode): React.ReactElement {
  return (
    <Pre>
      <Code className="language-yaml">{children}</Code>
    </Pre>
  );
}
