// NPM packages
import React from 'react';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { StoryblokComponent, storyblokEditable } from '@storyblok/react';

// All other imports
import {
  GuideResourceStoryblok,
  ResourceCategoryStoryblok,
  TextResourceStoryblok,
  VideoResourceStoryblok,
} from 'types/storyblokTypes';
import GradientBanner from './GradientBanner';
import { displayBreadcrumbs, useBreadcrumbs } from './utils';

const Root = styled.div(({ theme }) => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '1.75rem',
  };
});

const CategoryDescription = styled.div(() => {
  return {
    marginTop: '39.375px',
  };
});

export interface ResourceCategoryProps {
  blok: ResourceCategoryStoryblok;
  className?: string;
}

function ResourceCategory(
  props: ResourceCategoryProps
): React.ReactElement | null {
  const { blok, className } = props;
  const resources = blok.resources;
  const useGradientBanner = blok.useGradientBanner;
  const breadcrumbs = useBreadcrumbs();
  return (
    <Root className={className} {...storyblokEditable(blok)}>
      {useGradientBanner ? (
        <GradientBanner
          header={blok.title}
          description={blok.description || ''}
          image={blok.svgIcon?.filename}
          imageAlt={blok.svgIconAlt}
          breadcrumbs={breadcrumbs}
        />
      ) : (
        <CategoryDescription>
          {breadcrumbs && displayBreadcrumbs(breadcrumbs, '#000000')}
          <Typography variant="h1">{blok.title}</Typography>
          <Typography>{blok.description}</Typography>
        </CategoryDescription>
      )}
      {resources?.map(
        (
          value:
            | TextResourceStoryblok
            | VideoResourceStoryblok
            | GuideResourceStoryblok,
          idx
        ) => {
          return <StoryblokComponent blok={value} key={`Resource-${idx}`} />;
        }
      )}
    </Root>
  );
}

export default observer(ResourceCategory);
