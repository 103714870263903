// NPM packages
import React from 'react';
import { Grid, Typography } from '@mui/material';
import styled from '@emotion/styled';
import ProjectResource, { ProjectResourceType } from 'types/ProjectResource';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWikipediaW,
  faSlack,
  faGithub,
} from '@fortawesome/free-brands-svg-icons';
import { faGlobe, faDatabase } from '@fortawesome/free-solid-svg-icons';
import jupyterLogo from 'resources/jupyter_logo.svg';

// All other imports
import Link from 'components/core/Link';
import { useServices } from 'services';
import Project from 'types/Project';
import { observer } from 'mobx-react-lite';

const Root = styled.div(({ theme: { maxTextWidth } }) => ({
  maxWidth: maxTextWidth,
}));
const ResourceLink = styled(Link)({
  display: 'inline-flex',
  alignItems: 'center',
});
const ResourceLabel = styled(Typography)(() => ({
  paddingLeft: '0.5rem',
  fontSize: '1.25rem',
  margin: 0,
}));
const JupyterLogoImg = styled.img({
  height: '2em',
});

export interface ProjectResourceGridProps {
  className?: string;
  resources: ProjectResource[];
}

function ProjectResourceGrid(
  props: ProjectResourceGridProps
): React.ReactElement | null {
  const { className, resources } = props;
  const { authService, projectService } = useServices();
  const renderMap: Record<
    ProjectResourceType,
    {
      logo: React.ReactNode;
      label: string;
    }
  > = {
    [ProjectResourceType.WEBSITE]: {
      logo: <FontAwesomeIcon icon={faGlobe} size="2x" />,
      label: 'Website',
    },
    [ProjectResourceType.SLACK]: {
      logo: <FontAwesomeIcon icon={faSlack} size="2x" />,
      label: 'Slack',
    },
    [ProjectResourceType.WIKI]: {
      logo: <FontAwesomeIcon icon={faWikipediaW} size="2x" />,
      label: 'Wiki',
    },
    [ProjectResourceType.JUPYTER]: {
      logo: <JupyterLogoImg src={jupyterLogo} alt="" />,
      label: 'Jupyter Notebooks',
    },
    [ProjectResourceType.DATASETS]: {
      logo: <FontAwesomeIcon icon={faDatabase} size="2x" />,
      label: 'Datasets',
    },
    [ProjectResourceType.GITHUB]: {
      logo: <FontAwesomeIcon icon={faGithub} size="2x" />,
      label: 'GitHub',
    },
  };

  const selectedProjectSlug = (projectService.selectedProject as Project).slug;
  const userIsMember = authService.user?.isMember(selectedProjectSlug);
  const userIsAdmin = authService.user?.isGlobalAdmin;

  const visibleResources = resources.filter((resource) => {
    const resourceIsPublic = !resource.private;
    return resourceIsPublic || userIsMember || userIsAdmin;
  });

  return (
    <Root className={className}>
      <Grid container spacing={2}>
        {visibleResources.map((resource) => {
          const { logo, label } = renderMap[resource.type];
          return (
            <Grid item xs={12} md={6} lg={4} key={resource.url}>
              <ResourceLink
                to={resource.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {logo} <ResourceLabel>{label}</ResourceLabel>
              </ResourceLink>
            </Grid>
          );
        })}
      </Grid>
    </Root>
  );
}
export default observer(ProjectResourceGrid);
