// NPM packages
import React from 'react';
import {
  Button,
  Card,
  CardContent,
  MenuItem,
  FormControlLabel,
  Radio,
  Typography,
  IconButton,
  Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import { observer } from 'mobx-react-lite';
import { useFieldArray } from 'react-hook-form';
import { FormState } from 'hooks/useForm';
import ProjectResource, { ProjectResourceType } from 'types/ProjectResource';
import ProjectFormData from 'types/ProjectFormData';
import TextField from 'components/core/TextField';
import RadioGroup from 'components/core/RadioGroup';
import { faTrash, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldSet from 'components/core/FieldSet';

// All other imports

const Root = styled(FieldSet)({
  marginBottom: '1rem',
});
const ResourceList = styled.ul({
  listStyle: 'none',
  padding: 0,
  margin: 0,
  marginBottom: '0.5rem',
});
const ResourceItem = styled(Card)({
  marginBottom: '0.25rem',
}) as typeof Card;
const ResourceItemFieldset = styled(CardContent)({
  border: 'none',
  padding: '1rem',
  paddingBottom: '1.5rem',
}) as typeof CardContent;
const ResourceItemLegend = styled.legend({
  paddingTop: '1rem',
});
const ResourceItemTitleBar = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

export interface ResourcesFieldProps {
  className?: string;
  formState: FormState<ProjectFormData>;
}

function ResourcesField(props: ResourcesFieldProps): React.ReactElement | null {
  const { className, formState } = props;
  const { append, remove, fields } = useFieldArray({
    control: formState.control,
    name: 'resources',
  });
  return (
    <Root className={className}>
      <Typography component="legend" variant="h3">
        Resources
      </Typography>

      <ResourceList>
        {fields.map((field, index) => (
          <ResourceItem key={field.id} component="li">
            <ResourceItemFieldset component="fieldset">
              <ResourceItemTitleBar>
                <ResourceItemLegend>Resource {index + 1}</ResourceItemLegend>
                <Tooltip title="Remove">
                  <IconButton
                    aria-label="remove resource"
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} size="sm" />
                  </IconButton>
                </Tooltip>
              </ResourceItemTitleBar>

              <TextField
                formState={formState}
                fieldName={`resources.${index}.type`}
                textFieldProps={{
                  label: 'Type',
                  select: true,
                }}
              >
                {Object.values(ProjectResourceType).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                formState={formState}
                fieldName={`resources.${index}.url`}
                textFieldProps={{
                  label: 'URL',
                }}
              />
              <RadioGroup
                label="Who Can See This Resource?"
                formState={formState}
                fieldName={`resources.${index}.private`}
                muiRadioGroupProps={{ row: true }}
              >
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label="Everyone"
                />
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label="Project Members"
                />
              </RadioGroup>
            </ResourceItemFieldset>
          </ResourceItem>
        ))}
      </ResourceList>
      <Button
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        variant="outlined"
        onClick={addNewResource(append)}
      >
        Add Resource
      </Button>
    </Root>
  );
}

export default observer(ResourcesField);

function addNewResource(append: (arg: ProjectResource) => void): () => void {
  return () => {
    append({
      type: ProjectResourceType.WEBSITE,
      url: '',
      private: true,
    });
  };
}
