// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

// All other imports
import TextField from 'components/core/TextField';
import Form from 'components/core/Form';
import ProjectResource, { ProjectResourceType } from 'types/ProjectResource';
import ProjectFormData from 'types/ProjectFormData';
import ResourcesField from 'components/project/ResourcesField';
import ContactField from 'components/project/ContactField';
import MarkdownField from 'components/markdown/MarkdownField';

export interface ProjectFormProps {
  className?: string;
  initialValues: ProjectFormData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: (data: ProjectFormData) => Promise<any>;
  onCancel: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getSuccessMessage: (resolvedVal: any) => React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getErrorMessage: (rejectedVal: any) => React.ReactNode;
  submitButtonText?: string;
}

const resourceSchema: yup.ObjectSchema<ProjectResource> = yup.object({
  type: yup
    .mixed<ProjectResourceType>()
    .oneOf(Object.values(ProjectResourceType))
    .required(),
  url: yup
    .string()
    .test('has-protocol', 'Must start with "https://" or "http://"', (value) =>
      /^https?:\/\/.+/.test(value || '')
    )
    .url()
    .required(),
  private: yup.boolean(),
});

const schema: yup.ObjectSchema<ProjectFormData> = yup.object({
  title: yup.string().required(),
  subtitle: yup.string().required(),
  description: yup.string().required(),
  longDescription: yup.string().required(),
  resources: yup.array().of(resourceSchema).required(),
  contact: yup
    .object({
      firstName: yup.string().required(),
      lastName: yup.string().required(),
      email: yup.string().required(),
      affiliation: yup.string().required(),
    })
    .required(),
});

function ProjectForm(props: ProjectFormProps): React.ReactElement | null {
  const {
    className,
    initialValues,
    onSubmit,
    getErrorMessage,
    getSuccessMessage,
    submitButtonText,
    onCancel,
  } = props;

  return (
    <Form<ProjectFormData>
      className={className}
      schema={schema}
      defaultValues={initialValues}
      onSubmit={onSubmit}
      getErrorMessage={getErrorMessage}
      getSuccessMessage={getSuccessMessage}
      submitText={submitButtonText || 'Submit'}
      onCancel={onCancel}
    >
      {(formState) => {
        return (
          <>
            <TextField formState={formState} fieldName="title" />
            <TextField formState={formState} fieldName="subtitle" />
            <MarkdownField
              fieldName="Full Description"
              value={formState.getValues().longDescription}
              onChange={(value) => formState.setValue('longDescription', value)}
              helperText={`Note: Full description is displayed on the project details page ("/projects/<project>/info")`}
              style={{ margin: '0.75rem 0' }}
              label="Full Description"
            />
            <TextField
              formState={formState}
              fieldName="description"
              textFieldProps={{
                style: { marginBottom: '1rem' },
                multiline: true,
                minRows: 3,
                label: 'Abbreviated Description',
                helperText: `Note: Abbreviated description is displayed on the project
                    listing page ("/projects")`,
              }}
            />
            <ResourcesField formState={formState} />
            <ContactField formState={formState} />
          </>
        );
      }}
    </Form>
  );
}

export default observer(ProjectForm);
