// NPM packages
import React from 'react';
import { observer } from 'mobx-react-lite';
import * as yup from 'yup';

// All other imports
import TextField from 'components/core/TextField';
import { useServices } from 'services';
import Form from 'components/core/Form';
import AffiliationAutocomplete from 'components/core/AffiliationAutocomplete';
import UpdateProfileData from 'types/UpdateProfileData';

export interface UpdateProfileProps {
  className?: string;
}

interface ErrorComponentProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rejectedVal: any;
}

const schema: yup.ObjectSchema<UpdateProfileData> = yup.object({
  email: yup.string().required().email().label('Email'),
  firstName: yup.string().required().label('First Name'),
  lastName: yup.string().required().label('Last Name'),
  affiliation: yup.string().required().label('Affiliation'),
});

function ErrorComponent(props: ErrorComponentProps): React.ReactElement {
  const { rejectedVal } = props;
  return (
    <div>
      <div>Profile failed to update: {rejectedVal.message}</div>
      <div style={{ marginTop: '8px' }}>{rejectedVal.stack}</div>
    </div>
  );
}

function UpdateProfile(props: UpdateProfileProps): React.ReactElement | null {
  const { className } = props;
  const { authService } = useServices();
  const user = authService.user;
  const defaultValues = {
    email: user?.email || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    affiliation: user?.affiliation || '',
  };

  return (
    <Form<UpdateProfileData>
      className={className}
      schema={schema}
      defaultValues={defaultValues}
      onSubmit={(data) => authService.updateProfile(data)}
      replaceFormWithSuccess={false}
      getSuccessMessage={() => <div>Profile successfully updated!</div>}
      getErrorMessage={(rejectedVal) => (
        <ErrorComponent rejectedVal={rejectedVal} />
      )}
      submitText="Update"
    >
      {(formState) => {
        return (
          <>
            <TextField
              formState={formState}
              fieldName="email"
              textFieldProps={{ type: 'email' }}
            />
            <TextField formState={formState} fieldName="firstName" />
            <TextField formState={formState} fieldName="lastName" />
            <AffiliationAutocomplete
              formState={formState}
              fieldName="affiliation"
            />
          </>
        );
      }}
    </Form>
  );
}

export default observer(UpdateProfile);
