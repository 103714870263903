import { action, makeObservable, observable } from 'mobx';

export interface GlobalError {
  message: React.ReactNode;
  closeable: boolean;
}

export default class GlobalErrorService {
  globalError: GlobalError | null = null;
  constructor() {
    makeObservable(this, {
      globalError: observable,
      showError: action,
      closeError: action,
    });
  }
  showError = ({
    message,
    closeable = true,
  }: {
    message: React.ReactNode;
    closeable?: boolean;
  }): void => {
    this.globalError = {
      message,
      closeable,
    };
  };
  closeError = (): void => {
    if (this.globalError?.closeable) {
      this.globalError = null;
    }
  };
}
