import { Auth } from 'aws-amplify';
import { AxiosError } from 'axios';
import { action, makeObservable, observable, runInAction } from 'mobx';
import { Location } from 'react-router-dom';
import throttle from 'lodash/throttle';

import SignUpData from 'types/SignUpData';
import CookieService from './CookieService';
import EnvService from './EnvService';
import RdmApiService from './RdmApiService';
import User from 'types/User';
import GlobalErrorService from './GlobalErrorService';
import ConfirmationService from './ConfirmationService';
import { getCurrentUrl, getErrorMessage } from 'utils';
import UserJson from 'types/UserJson';
import UpdateProfileData from 'types/UpdateProfileData';

interface SearchState {
  text: string | null;
  limit: number;
  paginationToken: string | null;
  results: User[] | undefined;
  currentRequestNumber: number;
}

export default class AuthService {
  envService: EnvService;
  rdmApiService: RdmApiService;
  globalErrorService: GlobalErrorService;
  confirmationService: ConfirmationService;
  cookieService: CookieService;
  user: User | null | undefined = undefined;
  // email => user
  users: Record<string, User> = {};
  initialized: boolean = false;
  searchState: SearchState = {
    text: null,
    limit: 10,
    paginationToken: null,
    results: undefined,
    currentRequestNumber: 0,
  };

  constructor({
    envService,
    cookieService,
    rdmApiService,
    globalErrorService,
    confirmationService,
  }: {
    envService: EnvService;
    cookieService: CookieService;
    rdmApiService: RdmApiService;
    globalErrorService: GlobalErrorService;
    confirmationService: ConfirmationService;
  }) {
    this.envService = envService;
    this.cookieService = cookieService;
    this.rdmApiService = rdmApiService;
    this.confirmationService = confirmationService;
    this.globalErrorService = globalErrorService;
    makeObservable(this, {
      user: observable,
      initialized: observable,
      users: observable,
      searchState: observable,
      cacheUserIfNeeded: action,
      fetchUser: action,
      _searchUsers: action,
    });
  }

  get logInUrl(): string {
    return `${this.envService.getValue('DATA_REPO_URL')}/oauth/login/cognito/`;
  }

  get csrfToken(): string | null {
    return this.cookieService.getCookie('csrftoken');
  }

  get isLoggedIn(): boolean {
    return this.user !== undefined && this.user !== null;
  }

  initialize = async (): Promise<void> => {
    try {
      await this.autoLogIn();
      runInAction(() => (this.initialized = true));
    } catch (error: unknown) {
      this.globalErrorService.showError({
        message: getErrorMessage({
          error,
          defaultMessage: 'Could not initialize application.',
        }),
      });
    }
  };

  signUp = async (data: SignUpData): Promise<void> => {
    await Auth.signUp({
      username: data.email,
      password: data.password,
      attributes: {
        email: data.email,
        family_name: data.lastName,
        given_name: data.firstName,
        'custom:affiliation': data.affiliation,
        'custom:justification': data.justification,
      },
    });
  };

  signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<void> => {
    await Auth.signIn({ username: email, password });
  };

  updateProfile = async (data: UpdateProfileData): Promise<void> => {
    await this.rdmApiService.axios.post('/current-msdlive-user', {
      email: data.email,
      family_name: data.lastName,
      given_name: data.firstName,
      'custom:affiliation': data.affiliation,
    });
  };

  resendVerificationEmail = async ({
    email,
  }: {
    email: string;
  }): Promise<void> => {
    await Auth.resendSignUp(email);
  };

  verifyEmail = async ({
    email,
    code,
  }: {
    email?: string | null;
    code?: string | null;
  }): Promise<void> => {
    if (!email || !code) {
      throw new Error('Can not verify email.');
    }

    try {
      await Auth.confirmSignUp(email, code);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (
        error.message !==
        'User cannot be confirmed. Current status is CONFIRMED'
      ) {
        throw error;
      }
    }
  };

  autoLogIn = async (): Promise<void> => {
    try {
      const userResp = await this.rdmApiService.axios.get(
        '/current-msdlive-user'
      );

      const user = this.cacheUserIfNeeded(userResp.data);
      runInAction(() => {
        this.user = user;
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.status === 401) {
        // the user is not logged in
        runInAction(() => {
          this.user = null;
        });
      } else {
        throw error;
      }
    }
  };

  cacheUserIfNeeded = (userData: UserJson): User => {
    let user = this.getUser(userData.email);
    if (!user) {
      user = new User({
        userData,
        envService: this.envService,
        rdmApiService: this.rdmApiService,
        globalErrorService: this.globalErrorService,
        confirmationService: this.confirmationService,
      });
      this.users[user.email] = user;
    }

    return user;
  };

  getUser = (email: string): User | null => {
    return this.users[email] || null;
  };

  forgotPassword = async ({ email }: { email: string }): Promise<void> => {
    try {
      await Auth.forgotPassword(email);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.code === 'NotAuthorizedException') {
        await this.rdmApiService.axios.get(`/resend-temp-password/${email}`);
      } else {
        throw error;
      }
    }
  };

  resetPassword = async ({
    email,
    code,
    newPassword,
  }: {
    email: string;
    code: string;
    newPassword: string;
  }): Promise<void> => {
    await Auth.forgotPasswordSubmit(email, code, newPassword);
  };

  getLogInUrl = ({
    nextUrl,
    location,
  }: {
    nextUrl?: string;
    location: Location;
  }): string => {
    const currentUrl = getCurrentUrl(location);
    if (!nextUrl) {
      nextUrl = currentUrl;
    }

    const loginUrl = `${this.logInUrl}?next=${encodeURIComponent(nextUrl)}`;

    return `/disclaimer?urlToContinue=${encodeURI(
      loginUrl
    )}&urlToGoBack=${encodeURI(currentUrl)}`;
  };

  getAwsCredentials = (): void => {
    this.rdmApiService.axios.get('/get-aws-credentials');
  };

  private search = async (): Promise<void> => {
    const { text, limit, paginationToken } = this.searchState;
    const currentRequestNumber = this.searchState.currentRequestNumber + 1;
    runInAction(() => {
      this.searchState.results = undefined;
      this.searchState.currentRequestNumber = currentRequestNumber;
    });
    const resp = await this.rdmApiService.axios.get('search-users', {
      params: {
        text,
        limit,
        pagination_token: paginationToken,
      },
    });
    runInAction(() => {
      // This is needed so that stale results don't overwrite the current results
      if (this.searchState.currentRequestNumber === currentRequestNumber) {
        this.searchState.paginationToken = resp.data.pagination_token;
        this.searchState.results = resp.data.map((userJson: UserJson) => {
          return this.cacheUserIfNeeded(userJson);
        });
      }
    });
  };

  nextSearchPage = async (): Promise<void> => {
    if (!this.searchState.paginationToken) {
      // no more pages
      console.error('No more pages to load');
      return;
    }
    this.search();
  };

  fetchUser = async (email: string): Promise<void> => {
    try {
      const resp = await this.rdmApiService.axios.get(`/get-user/${email}`);
      const user = resp.data;
      this.cacheUserIfNeeded(user);
    } catch (error: unknown) {
      this.globalErrorService.showError({
        message: getErrorMessage({
          error,
          defaultMessage: 'Could not fetch user',
        }),
      });
    }
  };

  _searchUsers = async (text?: string): Promise<void> => {
    this.searchState.text = text || null;
    this.searchState.paginationToken = null;
    this.search();
  };

  searchUsers = throttle(this._searchUsers, 700, {
    trailing: true,
  });

  approve_new_user = async (user: User): Promise<string> => {
    await this.rdmApiService.axios.post(`/approve-user/${user.email}`);
    user.changeApproval(true);
    return 'User has been approved. If this was a mistake, refresh the page and make the appropriate selection.';
  };

  deny_new_user = async (user: User): Promise<string> => {
    await this.rdmApiService.axios.post(`/deny-user/${user.email}`);
    user.changeApproval(false);
    return 'User has been denied. If this was a mistake, refresh the page and make the appropriate selection.';
  };
}
